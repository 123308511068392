const moment = require("moment-timezone");

export const getWeek = (start, end) => {
  var temp = [];
  var day = start;

  while (day <= end) {
    temp.push(day.toDate());
    day = day.clone().add(1, "d");
  }

  return [
    {
      startDate: moment(start).format("MM/DD/YYYY"),
      endDate: moment(end).format("MM/DD/YYYY"),
      days: temp.map((dayOfWeek) => moment(dayOfWeek).format("MM/DD/YYYY")),
    },
  ];
};

export const getWeeksInMonth = (start, end) => {
  let monthFirstWeekStart = moment(start).startOf("isoWeek");
  let monthFirstWeekEnd = moment(start).endOf("isoWeek");
  let monthEndWeekStart = moment(end).startOf("isoWeek");

  let weeks = getWeek(monthFirstWeekStart, monthFirstWeekEnd);

  for (
    var m = moment(monthFirstWeekEnd);
    m.isBefore(monthEndWeekStart);
    m.add(7, "days")
  ) {
    let startOfWeek = moment(m)
      .add(1, "days")
      .startOf("isoWeek");
    let endOfWeek = moment(startOfWeek).endOf("isoWeek");

    weeks = [...weeks, ...getWeek(startOfWeek, endOfWeek)];
  }
  return weeks;
};

export const getTwoDateRangeDays = (start, end) => {
  let monthWeeks = getWeeksInMonth(start, end);
  let weeks = [];

  monthWeeks &&
    monthWeeks.forEach((week) => {
      let days = [];
      week.days.forEach((day) => {
        if (
          start.format("MM/DD/YYYY") <=
            moment(day, "MM/DD/YYYY").format("MM/DD/YYYY") &&
          moment(day, "MM/DD/YYYY").format("MM/DD/YYYY") <=
            end.format("MM/DD/YYYY")
        ) {
          days = [...days, day];
        }
      });
      week.sDate = days[0];
      week.eDate = days[days.length - 1];
      week.days = days;
      weeks = [...weeks, week];
    });

  return weeks;
};

export const getData = (obj, key, field) => {
  if (obj && obj[key] && obj[key][field]) {
    return obj[key][field];
  } else {
    return "";
  }
};

export const getDays = (start, end) => {
  start = moment(start, "MM/DD/YYYY");
  end = moment(end, "MM/DD/YYYY");

  var temp = [];

  while (start <= end) {
    temp.push(start.toDate());
    start = start.clone().add(1, "d");
  }

  return temp.map((dayOfWeek) => moment(dayOfWeek).format("MM/DD/YYYY"));
};

export const isInputDisabled = (onBD, pSD, cd, status, data) => {
  let allowToEdit = true;

  const onBoardingDate = moment(onBD).format("MM/DD/YYYY");
  const projectStartDate = moment(pSD).format("MM/DD/YYYY");
  const currentDate = moment().format("MM/DD/YYYY");
  const inputDate = moment(cd).format("MM/DD/YYYY");

  // Check status
  if (["Awaiting For Review", "Approved"].includes(status)) allowToEdit = false;
  // Check is Future Date
  if (new Date(inputDate) > new Date(currentDate)) allowToEdit = false;
  // If Proj Start Date is Less than Onboarding Date, Enter time only from Onboarding Date
  if (new Date(projectStartDate) < new Date(onBoardingDate)) {
    if (new Date(inputDate) < new Date(onBoardingDate)) allowToEdit = false;
  }
  // If Proj Start Date is Greater than Onboarding Date, Enter time only from Proj Start Date
  if (new Date(projectStartDate) > new Date(onBoardingDate)) {
    if (new Date(inputDate) < new Date(projectStartDate)) allowToEdit = false;
  }
  // check status is partially approved and input date have value value

  if (status === "Partially Approve") {
    if (data[inputDate] === undefined) {
      allowToEdit = true;
    } else {
      allowToEdit = false;
    }
  }
  return !allowToEdit;
};
