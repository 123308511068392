import React from "react";
import FormGroup from "./utils/FormGroup";

const Password = (props) => {
  return (
    <FormGroup element={props.element} module={props.module}>
      {props.module.permissions.update ? (
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fa fa-key"></i>
            </span>
          </div>
          <input
            placeholder={props.element.placeholder}
            type="password"
            id={props.element.id}
            className={"form-control " + props.element.class}
            onChange={props.events.change}
            disabled={props.element.readonly ? true : false}
            value={props.element.value}
            autoComplete="current-password"
          />
        </div>
      ) : (
        <h6 className="text-primary">{props.element.value}</h6>
      )}
    </FormGroup>
  );
};

export default Password;
