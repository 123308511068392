import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";

const Loader = () => {
  const [load, setLoad] = useState(true);
  const [msg, setMsg] = useState(0);

  useEffect(() => {
    if (load) {
      setTimeout(() => {
        setMsg(1);
        setTimeout(() => {
          setMsg(2);
          setTimeout(() => {
            setMsg(3);
          }, 1000);
        }, 2000);
      }, 2000);
      setLoad(false);
    }
  }, [load]);

  return (
    <div style={{ textAlign: "center" }}>
      <Spinner animation="border" variant="primary" />
      <br />
      {msg === 0 && (
        <p>
          <span className="text-info">
            <i className="fas fa-arrow-right mr-1" />
            Loading your Permission setting...
          </span>
          <br />
          Loading your petition info...
          <br />
          Running calculations...
          <br />
          Finalizing the info...
        </p>
      )}
      {msg === 1 && (
        <p>
          <span className="text-success">
            <i className="fa fa-check mr-1" />
            Loading your Permission setting...
          </span>
          <br />
          <span className="text-info">
            <i className="fas fa-arrow-right mr-1" />
            Loading your petition info...
          </span>
          <br />
          Running calculations...
          <br />
          Finalizing the info...
        </p>
      )}
      {msg === 2 && (
        <p>
          <span className="text-success">
            <i className="fa fa-check mr-1" />
            Loading your Permission setting...
          </span>
          <br />
          <span className="text-success">
            <i className="fa fa-check mr-1" />
            Loading your petition info...
          </span>
          <br />
          <span className="text-info">
            <i className="fas fa-arrow-right mr-1" />
            Running calculations...
          </span>
          <br />
          Finalizing the info...
        </p>
      )}
      {msg === 3 && (
        <p>
          <span className="text-success">
            <i className="fa fa-check mr-1" />
            Loading your Permission setting...
          </span>
          <br />
          <span className="text-success">
            <i className="fa fa-check mr-1" />
            Loading your petition info...
          </span>
          <br />
          <span className="text-success">
            <i className="fa fa-check mr-1" />
            Running calculations...
          </span>
          <br />
          <span className="text-info">
            <i className="fas fa-arrow-right mr-1" />
            Finalizing the info...
          </span>
        </p>
      )}
    </div>
  );
};

export default Loader;
