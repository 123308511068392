import React, { Fragment } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";

import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import Menu from "./Menu";

const LanguageSelector = () => {
  const { menuConfig } = useSelector(
    ({ builder: { menuConfig } }) => ({
      menuConfig,
    }),
    shallowEqual
  );
  //Campaigns
  let menus = [
    /*{
      url: "integrations",
      title: "Integrations",
      icon: "fas fa-file-import fa-lg",
    },*/
    { url: "imports", title: "Data Imports", icon: "fa fa-link fa-lg" },
  ];

  let headers = [
    ...menuConfig.header.items,
    ...(menuConfig.static ? menuConfig.static : []),
    /*{
      title: "Marketing Emails",
      icon: "fas fa-mail-bulk	fa-lg",
      root: true,
      alignment: "left",
      submenu: [
        {
          title: "Campaigns",
          page: "campaigns",
        },
        {
          _id: "email_template_builder",
          title: "Email Templates",
          page: "builders/email_templates",
        },
        {
          _id: "email_triggers",
          title: "Email Triggers",
          page: "builders/email_triggers",
        },
        
      ],
    },*/
  ];

  let icons = {
    builders: "fa fa-building",
    products_programs: "fab fa-product-hunt",
    security: "fa fa-lock",
    reports: "fa fa-file",
    workforces: "fa fa-user",
    contacts: "fa fa-user",
    groups: "fa fa-users",
  };
  
  return menuConfig.static && menuConfig.static.length > 0 && menuConfig.loggedInUserRole !=="Timesheet Approver" ? (
    <Dropdown
      className="kt-header__topbar-item kt-header__topbar-item--langs"
      drop="down"
      alignRight
    >
      <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-my-cart">
        <span
          className={clsx("kt-header__topbar-icon", {
            "kt-header__topbar-icon--brand": true,
          })}
        >
          <i className="fa fa-tools fa-2x" />
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
        {headers.map(
          (d, i) =>
            (d.show_menu || d.show_menu === undefined) &&
            ![
              "dashboard",
              "leads",
              "prospects",
              "timesheets",
              "petitions",
            ].includes(d.page) && (
              <Fragment key={i}>
                {d.submenu && d.submenu.length > 0 ? (
                  <Menu data={d} />
                ) : (
                  <div
                    key={i}
                    style={{
                      padding: "16px",
                      minHeight: "48px",
                    }}
                  >
                    {
                      d.page === "contacts" || d.page === "workforces" || d.page === "groups" ? (
                        <i className={icons[d.page] + " fa-lg mr-2 text-primary"} />
                      ) : (
                        <i className={icons[d._id] + " fa-lg mr-2 text-primary"} />
                      )
                    }

                    <Link to={"/" + d.page}>
                      <span
                        style={{
                          fontSize: "1rem",
                          fontFamily: "Poppins",
                          fontWeight: "400",
                          lineHeight: "1.5",
                          color: "#000",
                        }}
                      >
                        {d.title}
                      </span>
                    </Link>
                  </div>
                )}
              </Fragment>
            )
        )}

        {menus.map((m, k) => (
          <div
            key={k}
            style={{
              padding: "16px",
              minHeight: "48px",
              borderTop: "1px solid rgba(0, 0, 0, 0.15)",
            }}
          >
            <i className={m.icon + " fa-lg mr-2 text-primary"} />
            <Link to={"/" + m.url}>
              <span
                style={{
                  fontSize: "1rem",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "1.5",
                  color: "#000",
                }}
              >
                {m.title}
              </span>
            </Link>
          </div>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  ) : (
    ""
  );
};

export default LanguageSelector;
