import React, { useState, useEffect } from "react";
import axios from "axios";
import { backendApiUrl } from "../../utils";
import Templates from "./templates";
import Requests from "./requests";

const Contracts = (props) => {
  const [state, setState] = useState({ load: true });

  useEffect(() => {
    if (state.load) {
      axios
        .post(
          backendApiUrl("contracts"),
          { form: props.MI.form._id, owner: props.MI.MDID },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let st = { ...state, ...res.data };
          st.load = false;
          setState(st);
        })
        .catch((err) => {
          let st = { ...state };
          st.load = false;
          setState(st);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.load]);

  return (
    <>
      {props.templates && props.templates.length > 0 && (
        <Templates templates={props.templates} MI={props.MI} />
      )}
      {state.contracts && state.contracts.length > 0 && (
        <Requests data={state.contracts} />
      )}
    </>
  );
};

export default Contracts;
