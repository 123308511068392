import React, { useState, useEffect } from "react";
import axios from "axios";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import DialogBox from "./DialogBox";

export const Activate = (props) => {
  const [openConfirmBox, setOpenConfirmBox] = useState(false);
  const [approved, setApproved] = useState(false);

  useEffect(() => {
    let data = {};
    if (props.data) {
      data = props.data;
    } else {
      data = { id: props.id };
    }

    if (approved) {
      axios
        .post(props.url, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          props.actions.msg({
            status: 200,
            msg:
              (!!props.title ? props.title : "") + " activated successfully.",
            show: true,
          });
          props.actions.load(true);
          setApproved(false);
          setOpenConfirmBox(false);
        })
        .catch((err) => {
          props.actions.msg({
            status: 500,
            msg:
              "Problem while activating the" +
              (!!props.title ? props.title : "") +
              ", please try again.",
            show: true,
          });
          setApproved(false);
          setOpenConfirmBox(false);
        });
    }
  }, [approved, props.actions, props.data, props.id, props.title, props.url]);

  const statusClickHandler = (status) => {
    setApproved(status);
    setOpenConfirmBox(false);
  };

  return (
    <span>
      {openConfirmBox && (
        <DialogBox
          msg={
            "Are you sure want to activate " +
            (!!props.title ? props.title : "") +
            "?"
          }
          setStatus={statusClickHandler}
        />
      )}
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>Click here to activate.</Tooltip>}
      >
        <i
          className="fa fa-undo fa-lg text-success"
          onClick={() => setOpenConfirmBox(true)}
        />
      </OverlayTrigger>
    </span>
  );
};

export const Deactivate = (props) => {
  const [openConfirmBox, setOpenConfirmBox] = useState(false);
  const [approved, setApproved] = useState(false);

  useEffect(() => {
    if (approved) {
      axios
        .delete(props.url)
        .then((res) => {
          props.actions.msg({
            status: 200,
            msg:
              (!!props.title ? props.title : "") + " deactivated successfully.",
            show: true,
            _id: props.id,
          });
          if (props.actions.load) props.actions.load(true);
          setApproved(false);
          setOpenConfirmBox(false);
        })
        .catch((err) => {
          props.actions.msg({
            status: 500,
            msg:
              "Problem while deactivating the " +
              (!!props.title ? props.title : "") +
              ", please try again.",
            show: true,
          });
          setApproved(false);
          setOpenConfirmBox(false);
        });
    }
  }, [approved, props.actions, props.id, props.title, props.url]);

  const statusClickHandler = (status) => {
    setApproved(status);
    setOpenConfirmBox(false);
  };

  return (
    <span>
      {openConfirmBox && (
        <DialogBox
          msg={
            "Are you sure want to deactivate " +
            (!!props.title ? props.title : "") +
            "?"
          }
          setStatus={statusClickHandler}
        />
      )}
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>Click here to deactivate.</Tooltip>}
      >
        <i
          className="fa fa-trash fa-lg text-danger"
          onClick={() => setOpenConfirmBox(true)}
        />
      </OverlayTrigger>
    </span>
  );
};
