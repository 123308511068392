import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import Select from "react-select";
import { connect } from "react-redux";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../partials/content/Portlet";

const Auth = (props) => {
  let MI = { ...props.module };
  const [allowToUpdatePassword, setallowToUpdatePassword] = useState(
    MI.MDID === "" || MI.MDID === undefined ? true : false
  );

  let auth = { ...MI.data.auth };
  const [selRoles, setSelRoles] = useState(auth.selRoles ? auth.selRoles : []);

  let roles = MI.roles.filter((role) => {
    role.label = role.title;
    role.value = role._id;
    return (
      (MI.form.title === "Workforces" && role.type === "workforce") ||
      (MI.form.title === "Contacts" && role.type === "contact") ||
      ["Prospects", "Employees"].includes(MI.form.title)
    );
  });

  const rolesSelectHandler = (sroles) => {
    if (sroles === null) sroles = [];
    let data = { ...MI.data };
    let tr = [];
    sroles.forEach((role) => {
      tr = [...tr, role._id];
    });

    if (data.auth) {
      data.auth.roles = tr;
    } else {
      data.auth = {};
      data.auth.roles = tr;
    }
    props.events.setData(data);
    setSelRoles(sroles);
  };

  const onChangeHandler = (event) => {
    let data = { ...MI.data };

    if (data.auth) {
      data.auth[event.target.id] = event.target.value;
    } else {
      data.auth = {};
      data.auth[event.target.id] = event.target.value;
    }

    props.events.setData(data);
  };

  const radioBtnClickHandler = (e) => {
    let data = { ...MI.data };
    data[e.target.id] = e.target.checked ? true : false;
    props.events.setData(data);
  };

  let allowToEdit = false;
  let permissions = {};
  if (props.user.role.admin || MI.MDID === "") {
    permissions = { add: true, update: true, delete: true };
  } else if (MI.permissions && MI.permissions.auth) {
    permissions = MI.permissions.auth;
  }
  if (permissions.add || permissions.update) allowToEdit = true;

  let allowToEditAdminInfo = true;
  if (
    !(props.user.role && props.user.role.admin) &&
    MI.MDID === props.user._id
  ) {
    allowToEditAdminInfo = false;
  }

  return (
    <div className="container">
      <Portlet>
        <PortletHeader
          title={
            <div>
              <h5>
                Auth Information{" "}
                <span className="text-danger">
                  (Update this page only if you want to change username &
                  Password)
                </span>
              </h5>
            </div>
          }
        />
        <PortletBody>
          <Grid container spacing={2} className="mb-3">
            {MI.form.title !== "Employees" && (
              <Grid item xs={6}>
                <label className="form-label" htmlFor="role">
                  Roles <span className="text-danger">*</span>
                </label>
                {allowToEditAdminInfo && allowToEdit ? (
                  <Select
                    className="kt-width-full"
                    name="roles"
                    options={roles}
                    onChange={rolesSelectHandler}
                    value={selRoles}
                    isMulti
                    placeholder="Please select roles"
                  />
                ) : (
                  <h6 className="text-primary">
                    {selRoles.map((v, i) =>
                      i === 0 ? v.label : ", " + v.label
                    )}
                  </h6>
                )}
                <div className="invalid-feedback">
                  Please provide a valid roles.
                </div>
              </Grid>
            )}

            <Grid item xs={12} className="bg-light">
              <div className="row">
                <div className="col-md-6">
                  <label className="form-label mr-2" htmlFor="allow_to_login">
                    Allow to login ?
                  </label>
                  {allowToEditAdminInfo && allowToEdit ? (
                    <input
                      type="checkbox"
                      id="allow_to_login"
                      checked={MI.data.allow_to_login ? true : false}
                      onChange={radioBtnClickHandler}
                    />
                  ) : (
                    <h6 className="text-primary">
                      {MI.data.allow_to_login ? "Yes" : "No"}
                    </h6>
                  )}
                </div>
                {MI.data.allow_to_login && !!MI.MDID && (
                  <div className="col-md-6" style={{ textAlign: "right" }}>
                    {allowToEdit && (
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() =>
                          setallowToUpdatePassword(
                            allowToUpdatePassword === true ? false : true
                          )
                        }
                      >
                        Show/Hide Password Fields
                      </button>
                    )}
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
          {MI.data.allow_to_login && (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <div className="form-group">
                  <label className="form-label" htmlFor="username">
                    User Name <span className="text-danger">*</span>
                  </label>
                  {allowToEdit ? (
                    <input
                      type="text"
                      id="username"
                      value={!!auth.username ? auth.username : ""}
                      onChange={onChangeHandler}
                      className={
                        MI.requiredFields.includes("username")
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                    />
                  ) : (
                    <h6 className="text-primary">
                      {!!auth.username ? auth.username : ""}
                    </h6>
                  )}
                  <div className="invalid-feedback">
                    Please provide a valid username
                  </div>
                </div>
              </Grid>
              {allowToUpdatePassword && (
                <React.Fragment>
                  <Grid item xs={4}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="password">
                        Password <span className="text-danger">*</span>
                      </label>
                      <input
                        type="password"
                        id="password"
                        value={!!auth.password ? auth.password : ""}
                        onChange={onChangeHandler}
                        className={
                          MI.requiredFields.includes("password")
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                      />
                      <div className="invalid-feedback">
                        Please provide a valid password.
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="confirm_password">
                        Confirm Password <span className="text-danger">*</span>
                      </label>
                      <input
                        type="password"
                        id="confirm_password"
                        value={
                          !!auth.confirm_password ? auth.confirm_password : ""
                        }
                        onChange={onChangeHandler}
                        className={
                          MI.requiredFields.includes("confirm_password")
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                      />
                      <div className="invalid-feedback">
                        Password and Confirm Password didn't match.
                      </div>
                    </div>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          )}
        </PortletBody>
      </Portlet>
    </div>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(Auth);
