import React, { useState } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";

import { backendApiUrl } from "../../utils";

const DialogBox = (props) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const [reason, setReason] = useState("");

  const onAgreeClickHandler = () => {
    if (props.info.status === "Resubmit" && reason === "") {
      props.events.setMsg({
        status: 500,
        msg: "Please Enter a Reason.",
        show: true,
      });
      return true;
    }
    setSubmitting(true);
    if (props.info && props.info.status === "Delete") {
      axios
        .post(
          backendApiUrl("timesheet/delete"),
          {
            _id: props.info._id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          props.events.onClose({});
          props.events.setMsg({
            status: 200,
            msg: "Timesheet Deleted Successfully!",
            show: true,
          });
          setTimeout(() => {
            props.events.setLoad(true);
          }, 2000);
        })
        .catch((err) => {
          setSubmitting(false);
          props.events.setMsg({
            status: 500,
            msg: "Problem While " + props.info.status + " Timesheet.",
            show: true,
          });
        });
    } else {
      axios
        .post(
          backendApiUrl("timesheet/status"),
          {
            _id: props.info._id,
            status: props.info.status,
            reason,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          props.events.onClose({});
          props.events.setMsg({
            status: 200,
            msg:
              props.info.status === "Approve"
                ? "Timesheet Approved Successfully!"
                : props.info.status === "Partially Approve"
                ? "Timesheet Partially Approved Successfully!"
                : "Successfully Requested to Resubmit.",
            show: true,
          });

          setTimeout(() => {
            props.events.setLoad(true);
          }, 2000);
        })
        .catch((err) => {
          setSubmitting(false);
          props.events.setMsg({
            status: 500,
            msg: "Problem While " + props.info.status + " Timesheet.",
            show: true,
          });
        });
    }
  };

  return (
    <Modal show={true} size="md" backdrop="static" keyboard={false}>
      <Modal.Body>
        <div align="center">
          {props.info.status === "Resubmit"
            ? "Please Enter the Reason for Re-Submit Request :"
            : "Are you sure want to " + props.info.status + " the Timesheet ?"}
          {props.info.status === "Resubmit" && (
            <textarea
              className="form-control"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          )}
          <br />
          <br />
          <span className="mt-2">
            {isSubmitting ? (
              <button className="btn card-blue-green">Processing..</button>
            ) : (
              <button
                className="btn card-blue-green"
                onClick={onAgreeClickHandler}
              >
                Yes
              </button>
            )}
            <button
              className="btn card-salmon-pink ml-2"
              onClick={() => props.events.onClose({})}
            >
              No
            </button>
          </span>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DialogBox;
