import React from "react";
import { Badge } from "react-bootstrap";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../partials/content/Portlet";

const Requests = (props) => {
  return (
    <>
      {props.data && props.data.length > 0 && (
        <div className="mt-3">
          <Portlet>
            <PortletHeader
              title="Contracts Sign Requests"
              toolbar={<PortletHeaderToolbar>{""}</PortletHeaderToolbar>}
            />
            <PortletBody>
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th nowrap="true">Title</th>
                      <th nowrap="true">Signature Requests</th>
                      <th nowrap="true">Status</th>
                      <th nowrap="true">Created Date</th>
                      <th nowrap="true">Updated Date</th>
                      <th nowrap="true">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.data.map((t, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{t.template.title}</td>
                        <td>
                          {t.actions &&
                            t.actions.map(
                              (a, j) =>
                                ["sign_request", "signature"].includes(
                                  a.type
                                ) && (
                                  <div key={j} className="mb-2">
                                    <span className="mr-2">
                                      {!!a.sign || !!a.image ? (
                                        <Badge variant="success">Signed</Badge>
                                      ) : (
                                        <Badge variant="danger">
                                          Not Signed
                                        </Badge>
                                      )}
                                    </span>
                                    <span>
                                      {a.user
                                        ? a.user.split(" | ")[3] +
                                          " | " +
                                          a.user.split(" | ")[1] +
                                          " | " +
                                          a.user.split(" | ")[2]
                                        : ""}
                                    </span>
                                  </div>
                                )
                            )}
                        </td>
                        <td>
                          {t.signed ? (
                            <Badge variant="success">Completed</Badge>
                          ) : (
                            <Badge variant="danger">Pending</Badge>
                          )}
                        </td>
                        <td>{t.createdAt}</td>
                        <td>{t.updatedAt}</td>
                        <td>
                          {t.signed && (
                            <a
                              href={
                                process.env.REACT_APP_DOC_URL +
                                "contracts/view/" +
                                t._id +
                                "?d=immicrm"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Badge variant="info">View Document</Badge>
                            </a>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </PortletBody>
          </Portlet>
        </div>
      )}
    </>
  );
};

export default Requests;
