import React from "react";
import { Grid } from "@material-ui/core";
import { MdMarkEmailUnread, MdMarkEmailRead } from "react-icons/md";
import { IoIosMailOpen } from "react-icons/io";
import { GiClick } from "react-icons/gi";
import { RiMailCloseFill, RiMailForbidFill } from "react-icons/ri";

import { Portlet, PortletBody } from "../partials/content/Portlet";
import "./mailbox.css";

const Counter = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={2} align="center">
        <Portlet className="card card-purple-pink">
          <PortletBody className="p-3">
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <MdMarkEmailUnread size={40} className="mt-3" />
              </Grid>
              <Grid item xs={10}>
                Sent
                <h3
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => props.onClickHandler("send")}
                >
                  {props.state.mailCnts ? props.state.mailCnts.send : ""}
                </h3>
                {props.state.count} Emails
              </Grid>
            </Grid>
          </PortletBody>
        </Portlet>
      </Grid>
      <Grid item xs={2} align="center">
        <Portlet className="card card-blue-green">
          <PortletBody className="p-3">
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <MdMarkEmailRead size={40} className="mt-3" />
              </Grid>
              <Grid item xs={10}>
                Delivered
                <h3
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => props.onClickHandler("delivery")}
                >
                  {props.state.mailCnts ? props.state.mailCnts.delivery : ""}
                </h3>
                {props.state.count} Emails
              </Grid>
            </Grid>
          </PortletBody>
        </Portlet>
      </Grid>
      <Grid item xs={2} align="center">
        <Portlet className="card card-purple-blue">
          <PortletBody className="p-3">
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <IoIosMailOpen size={40} className="mt-3" />
              </Grid>
              <Grid item xs={10}>
                Opened
                <h3
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => props.onClickHandler("open")}
                >
                  {props.state.mailCnts ? props.state.mailCnts.open : ""}
                </h3>
                {props.state.count} Emails
              </Grid>
            </Grid>
          </PortletBody>
        </Portlet>
      </Grid>
      <Grid item xs={2} align="center">
        <Portlet className="card card-sweet-morning">
          <PortletBody className="p-3">
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <GiClick size={40} className="mt-3" />
              </Grid>
              <Grid item xs={10}>
                Clicked
                <h3
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => props.onClickHandler("click")}
                >
                  {props.state.mailCnts ? props.state.mailCnts.click : ""}
                </h3>
                {props.state.count} Emails
              </Grid>
            </Grid>
          </PortletBody>
        </Portlet>
      </Grid>
      <Grid item xs={2} align="center">
        <Portlet className="card card-salmon-pink">
          <PortletBody className="p-3">
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <RiMailForbidFill size={40} className="mt-3" />
              </Grid>
              <Grid item xs={10}>
                Bounced
                <h3
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => props.onClickHandler("bounce")}
                >
                  {props.state.mailCnts ? props.state.mailCnts.bounce : ""}
                </h3>
                {props.state.count} Emails
              </Grid>
            </Grid>
          </PortletBody>
        </Portlet>
      </Grid>
      <Grid item xs={2} align="center">
        <Portlet className="card card-salmon-pink">
          <PortletBody className="p-3">
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <RiMailCloseFill size={40} className="mt-3" />
              </Grid>
              <Grid item xs={10}>
                Unsubscribed
                <h3
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => props.onClickHandler("unsubscribe")}
                >
                  {props.state.mailCnts
                    ? props.state.mailCnts.unsubscribed
                    : ""}
                </h3>
                {props.state.count} Emails
              </Grid>
            </Grid>
          </PortletBody>
        </Portlet>
      </Grid>
    </Grid>
  );
};

export default Counter;
