import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import { Portlet, PortletBody } from "../partials/content/Portlet";
import { backendApiUrl } from "../utils";

const Dashboard = (props) => {
  const [state, setState] = useState({
    load: true,
    employees: {},
    timesheets: {},
    employeeCount: {},
    visaStatusCounts: {},
    statusCounts: {},
    cvs: [],
    employeeTypeInfo: [],
  });

  const pFloat = (value) => {
    return parseFloat(value) > 0 ? parseFloat(value) : 0;
  };

  useEffect(() => {
    if (state.load) {
      axios
        .post(
          backendApiUrl("timesheet/sheets"),
          { page: "dashboard" },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let show_columns = [];
          let form_fields = [];
          let fieldsIds = [];
          let currentVisaStatusInfo = [];
          let cvsCount = {};
          let { form, visaStatusCounts, employeeTypeCount } = res.data;
          let etCount = {};
          let employeeTypeInfo = [];
          const getModuleFields = (configs) => {
            configs.forEach((config) => {
              // Section hide based on roles
              let show = true;
              if (config.hide) {
                if (
                  config.hided_roles &&
                  config.hided_roles.includes(props.user.role._id)
                ) {
                  show = false;
                }
              }
              if (show) {
                if (
                  config.type !== "container" &&
                  config.type !== "grid" &&
                  config.type !== "item" &&
                  config.type !== "tab" &&
                  config.type !== "section" &&
                  config.type !== "file"
                ) {
                  if (
                    config.id === "current_visa_status" &&
                    config.type === "select"
                  ) {
                    config.options.forEach((s) => {
                      currentVisaStatusInfo = [...currentVisaStatusInfo, s];
                    });
                  }
                  if (
                    config.id === "employee_type" &&
                    config.type === "select"
                  ) {
                    config.options.forEach((et) => {
                      employeeTypeInfo = [...employeeTypeInfo, et];
                    });
                  }

                  // Avoid list duplicate
                  if (!fieldsIds.includes(config.id)) {
                    form_fields = [...form_fields, config];
                    fieldsIds = [...fieldsIds, config.id];

                    if (config.listShowField) {
                      show_columns = [...show_columns, config];
                    }
                  }
                } else {
                  if (Array.isArray(config.childs) && !config.cloneable)
                    getModuleFields(config.childs);
                }
              }
            });
          };
          if (form) {
            getModuleFields(form.content);
          }
          currentVisaStatusInfo &&
            currentVisaStatusInfo.forEach((cvsinfo) => {
              if (!!visaStatusCounts[cvsinfo.value]) {
                cvsCount[cvsinfo.label] = visaStatusCounts[cvsinfo.value];
              } else {
                cvsCount[cvsinfo.label] = 0;
              }
            });
          let st = { ...state, ...res.data, load: false };
          st.cvs = currentVisaStatusInfo;
          st.visaStatusCounts = cvsCount;
          employeeTypeInfo &&
            employeeTypeInfo.forEach((etcinfo) => {
              if (!!employeeTypeCount[etcinfo.value]) {
                etCount[etcinfo.label] = employeeTypeCount[etcinfo.value];
              } else {
                etCount[etcinfo.label] = 0;
              }
            });
          st.employeeTypeInfo = employeeTypeInfo;
          st.employeeTypeCount = etCount;
          setState(st);
        });
    }
  }, [state, props.user.role._id]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} align="left">
        <h4>Employee Summary</h4>
      </Grid>
      {[
        "Total Employees",
        "On-Bench",
        "Active Clients",
        "Based on Vendors",
      ].map((d, i) => (
        <Grid item xs={2} align="center" key={i}>
          <Portlet className="card card-blue-green">
            <PortletBody className="p-3">
              <a
                href={process.env.PUBLIC_URL + "prospects"}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Grid container spacing={2} style={{ height: "90px" }}>
                  <Grid item xs={8} style={{ color: "white" }}>
                    {d}
                    <h3>
                      {d === "Total Employees" &&
                      state.employeeCount[d] &&
                      state.employeeTypeCount["Offshore"]
                        ? state.employeeCount[d] -
                          state.employeeTypeCount["Offshore"]
                        : state.employeeCount[d]
                        ? state.employeeCount[d]
                        : 0}
                    </h3>
                  </Grid>
                </Grid>
              </a>
            </PortletBody>
          </Portlet>
        </Grid>
      ))}

      {state.cvs.map((d, i) => (
        <>
          <Grid item xs={12} align="left">
            <h4>Employee Based on Visa Status</h4>
          </Grid>
          <Grid item xs={2} align="center" key={i}>
            <Portlet className="card card-purple-blue">
              <PortletBody className="p-3">
                <a
                  href={process.env.PUBLIC_URL + "prospects?cvs=" + d.value}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Grid container spacing={2} style={{ height: "90px" }}>
                    <Grid item xs={12} style={{ color: "white" }}>
                      {d.label}
                      <h3>
                        {state.visaStatusCounts[d.label]
                          ? state.visaStatusCounts[d.label]
                          : 0}
                      </h3>
                    </Grid>
                  </Grid>
                </a>
              </PortletBody>
            </Portlet>
          </Grid>
        </>
      ))}
      <Grid item xs={12} align="left">
        <h4>TimeSheet Summary</h4>
      </Grid>
      {[
        "All Status",
        "Awaiting For Review",
        "Approved",
        "Resubmit",
        "Partially Approve",
      ].map((s, d) => (
        <Grid item xs={2} align="center" key={d}>
          <Portlet
            className={
              "card " +
              (s === "Awaiting For Review"
                ? "card-purple-pink"
                : s === "Approved"
                ? "card-blue-green"
                : s === "Resubmit"
                ? "card-salmon-pink"
                : s === "Partially Approve"
                ? "card-purple-love"
                : s === "All Status"
                ? "card-purple-blue"
                : "card-sweet-morning")
            }
          >
            <PortletBody className="p-3">
              <a
                href={process.env.PUBLIC_URL + "timesheets?sts=" + s}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Grid container spacing={2} style={{ height: "100px" }}>
                  <Grid item xs={4} style={{ color: "white" }}>
                    <CircularProgressbar
                      value={
                        state.statusCounts[s]
                          ? Math.round(
                              pFloat(
                                pFloat(state.statusCounts[s]) /
                                  pFloat(state.statusCounts["All Status"])
                              ) * 100
                            )
                          : 0
                      }
                      text={`${Math.round(
                        pFloat(
                          pFloat(state.statusCounts[s]) /
                            pFloat(state.statusCounts["All Status"])
                        ) * 100
                      )}%`}
                      styles={buildStyles({
                        textColor: "#fff",
                        pathColor: "#fff",
                      })}
                    />
                  </Grid>

                  <Grid item xs={8} style={{ color: "white" }}>
                    {s}
                    <h3>{state.statusCounts[s] ? state.statusCounts[s] : 0}</h3>
                    {state.statusCounts[s]
                      ? state.statusCounts[s] + " Hours"
                      : s === "All Status"
                      ? ""
                      : 0 + " Hours"}
                  </Grid>
                </Grid>
              </a>
            </PortletBody>
          </Portlet>
        </Grid>
      ))}
    </Grid>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(Dashboard);
