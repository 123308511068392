import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { Grid, Switch } from "@material-ui/core";
import { MdOutlineNoteAlt } from "react-icons/md";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { IoDocumentAttach } from "react-icons/io5";
import {
  Badge,
  OverlayTrigger,
  Tooltip,
  Popover,
  Modal,
  Button,
} from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import StatusUpdate from "./StatusUpdate";
import { FaRegEye } from "react-icons/fa";

import {
  Portlet,
  PortletHeader,
  PortletHeaderToolbar,
  PortletBody,
} from "../partials/content/Portlet";
import Attachment from "./utils/Attachment";

import { getWeeksInMonth, getData } from "./utils/DateFun";

const moment = require("moment-timezone");

const Monthly = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [attachment, setAttachment] = useState([]);
  let totalHours = 0;
  let status = "";
  let nonApprovedCnt = 0;
  let tWeeks = [];

  props.state.weeks &&
    props.state.weeks.forEach((we) => {
      let sts = !!getData(
        props.state.data,
        moment(we.startDate).format("MM/DD/YYYY") +
          "to" +
          moment(we.endDate).format("MM/DD/YYYY"),
        "status"
      )
        ? getData(
            props.state.data,
            moment(we.startDate).format("MM/DD/YYYY") +
              "to" +
              moment(we.endDate).format("MM/DD/YYYY"),
            "status"
          )
        : "Not Submitted";
      tWeeks = [
        ...tWeeks,
        {
          startDate: new Date(moment(we.startDate).toString()),
          endDate: new Date(moment(we.endDate).toString()),
          key: "isoWeek",
          color:
            sts === "Awaiting For Review"
              ? "rgb(254, 209, 76)"
              : sts === "Approved"
              ? "#0abb87"
              : sts === "Resubmit"
              ? "#fd397a"
              : "rgb(61, 145, 255)",
        },
      ];
    });

  const onNextAndPreviousWeekHandler = (action) => {
    let st = { ...props.state };

    if (action === "Previous") {
      st.startDate = moment(st.startDate)
        .subtract(1, "months")
        .startOf("month");
      st.endDate = moment(st.startDate).endOf("month");
    } else {
      st.endDate = moment(st.endDate)
        .add(1, "months")
        .endOf("month");
      st.startDate = moment(st.endDate).startOf("month");
    }
    st.weeks = getWeeksInMonth(st.startDate, st.endDate);
    st.load = true;
    props.events.setState(st);
  };

  const onCalendarChangeHandler = (e) => {
    console.log(e);
  };
  const showAttachment = (files, week) => {
    setOpenModal(true);
    setAttachment(files);
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12} align="center">
        <Portlet>
          <PortletHeader
            title="Monthly Timesheet"
            toolbar={
              <PortletHeaderToolbar>
                <button
                  className="btn btn-sm btn-default"
                  onClick={() => props.events.onClose({})}
                >
                  {"<< Back"}
                </button>
                &nbsp;
                <button
                  className={
                    props.state.type === "Weekly"
                      ? "btn btn-sm btn-primary"
                      : "btn btn-sm btn-default"
                  }
                  onClick={() => props.events.setType("Weekly")}
                >
                  Weekly
                </button>
                &nbsp;
                <button
                  className={
                    props.state.type === "Monthly"
                      ? "btn btn-sm btn-primary"
                      : "btn btn-sm btn-default"
                  }
                  onClick={() => props.events.setType("Monthly")}
                >
                  Monthly
                </button>
              </PortletHeaderToolbar>
            }
          />
          <PortletBody>
            <table className="table table-dark">
              <thead>
                <tr className="table-active">
                  <th colSpan={11} style={{ textAlign: "center" }}>
                    <div className="date-input">
                      <h5>
                        {new Date(
                          moment(props.state.client.project_start_date)
                        ) <
                          new Date(
                            moment(props.state.startDate, "MM/DD/YYYY")
                          ) && (
                          <FiChevronLeft
                            className="left-right-margin npBtn"
                            size={25}
                            onClick={() =>
                              onNextAndPreviousWeekHandler("Previous")
                            }
                          />
                        )}
                        <span>
                          {moment(props.state.startDate).format("MMMM YYYY")}
                        </span>
                        {moment(props.state.endDate)
                          .format("MM/DD/YYYY")
                          .toString() !==
                          moment()
                            .endOf("month")
                            .format("MM/DD/YYYY")
                            .toString() && (
                          <FiChevronRight
                            className="left-right-margin npBtn"
                            size={25}
                            onClick={() => onNextAndPreviousWeekHandler("Next")}
                          />
                        )}
                      </h5>
                    </div>
                  </th>
                </tr>
                {props.state.weeks &&
                  props.state.weeks.map((we, j) => (
                    <Fragment key={j}>
                      <tr className="table-active">
                        <th colSpan={11} style={{ textAlign: "center" }}>
                          <span style={{ display: "none" }}>
                            {
                              (status = !!getData(
                                props.state.data,
                                we.startDate + "to" + we.endDate,
                                "status"
                              )
                                ? getData(
                                    props.state.data,
                                    we.startDate + "to" + we.endDate,
                                    "status"
                                  )
                                : "Not Submitted")
                            }
                            {status !== "Approved" ? (nonApprovedCnt += 1) : ""}
                          </span>
                          <div className="date-input">
                            <h5>
                              {we.startDate}
                              <span className="left-right-margin">to</span>
                              {we.endDate}
                            </h5>
                          </div>
                          <div style={{ float: "right" }}>
                            Have you worked weekends?
                            <Switch
                              name="allowWeekend"
                              checked={
                                getData(
                                  props.state.data,
                                  we.startDate + "to" + we.endDate,
                                  "allowWeekend"
                                )
                                  ? true
                                  : false
                              }
                              onChange={(e) =>
                                props.events.onHourChange(
                                  e,
                                  we.startDate + "to" + we.endDate
                                )
                              }
                            />
                          </div>
                        </th>
                      </tr>
                      <tr>
                        {we.days.map((w, i) =>
                          (["Saturday", "Sunday"].includes(
                            moment(w).format("dddd")
                          ) &&
                            getData(
                              props.state.data,
                              we.startDate + "to" + we.endDate,
                              "allowWeekend"
                            )) ||
                          !["Saturday", "Sunday"].includes(
                            moment(w).format("dddd")
                          ) ? (
                            <th className="alignCenter" key={i}>
                              {moment(w).format("MM/DD")}
                              <br />
                              {moment(w).format("dddd")}
                            </th>
                          ) : (
                            <th key={i}></th>
                          )
                        )}
                        <th className="table-active alignCenter">Attachment</th>
                        <th className="table-active alignCenter">Notes</th>
                        <th className="table-active alignCenter">Status</th>
                      </tr>
                      <tr>
                        {we.days.map((w, i) =>
                          (["Saturday", "Sunday"].includes(
                            moment(w).format("dddd")
                          ) &&
                            getData(
                              props.state.data,
                              we.startDate + "to" + we.endDate,
                              "allowWeekend"
                            )) ||
                          !["Saturday", "Sunday"].includes(
                            moment(w).format("dddd")
                          ) ? (
                            <th className="alignCenter" key={i}>
                              <span style={{ display: "none" }}>
                                {parseFloat(
                                  getData(
                                    props.state.data[
                                      we.startDate + "to" + we.endDate
                                    ],
                                    "dailyHours",
                                    moment(w)
                                      .format("MM/DD/YYYY")
                                      .toString()
                                  )
                                ) > 0
                                  ? (totalHours += parseFloat(
                                      getData(
                                        props.state.data[
                                          we.startDate + "to" + we.endDate
                                        ],
                                        "dailyHours",
                                        moment(w)
                                          .format("MM/DD/YYYY")
                                          .toString()
                                      )
                                    ))
                                  : ""}
                              </span>
                              <input
                                type="number"
                                className="form-control"
                                value={getData(
                                  props.state.data[
                                    we.startDate + "to" + we.endDate
                                  ],
                                  "dailyHours",
                                  moment(w)
                                    .format("MM/DD/YYYY")
                                    .toString()
                                )}
                                name={moment(w)
                                  .format("MM/DD/YYYY")
                                  .toString()}
                                onChange={(e) =>
                                  e.target.value === "" || e.target.value <= 24
                                    ? props.events.onHourChange(
                                        e,
                                        we.startDate + "to" + we.endDate
                                      )
                                    : true
                                }
                                disabled={props.events.inputDisabled(
                                  w,
                                  status,
                                  props.state.data[
                                    we.startDate + "to" + we.endDate
                                  ]
                                    ? props.state.data[
                                        we.startDate + "to" + we.endDate
                                      ].dailyHours
                                    : []
                                )}
                              />
                            </th>
                          ) : (
                            <th key={i}></th>
                          )
                        )}
                        <th className="table-active alignCenter">
                          <OverlayTrigger
                            placement="top"
                            trigger="click"
                            overlay={
                              <Popover
                                style={{ width: "300px", maxWidth: "none" }}
                              >
                                <Attachment
                                  onAttachmentHandler={
                                    props.events.onAttachmentHandler
                                  }
                                  onAttachmentDeleteHandler={
                                    props.events.onAttachmentDeleteHandler
                                  }
                                  week={we.startDate + "to" + we.endDate}
                                  file={
                                    props.state.data[
                                      we.startDate + "to" + we.endDate
                                    ]
                                      ? props.state.data[
                                          we.startDate + "to" + we.endDate
                                        ].file
                                      : null
                                  }
                                />
                              </Popover>
                            }
                            rootClose
                          >
                            <IoDocumentAttach
                              size="25"
                              color={
                                props.state.data[
                                  we.startDate + "to" + we.endDate
                                ] &&
                                props.state.data[
                                  we.startDate + "to" + we.endDate
                                ].file &&
                                props.state.data[
                                  we.startDate + "to" + we.endDate
                                ].file.length > 0
                                  ? "#e83e8c"
                                  : "#fff"
                              }
                            />
                          </OverlayTrigger>
                          <br />

                          {/*props.state.data[we.startDate + "to" + we.endDate] &&
                            !!props.state.data[we.startDate + "to" + we.endDate]
                              .file &&
                            !props.state.data[we.startDate + "to" + we.endDate]
                              .file.path && (
                              <a
                                href={
                                  process.env.REACT_APP_BACKEND_API_URL +
                                  "auth/timesheet/doc/" +
                                  props.state.companyId +
                                  "/" +
                                  props.state.data[
                                    we.startDate + "to" + we.endDate
                                  ].file
                                }
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                view
                              </a>
                              )*/}
                          {props.state.data[we.startDate + "to" + we.endDate] &&
                            props.state.data[we.startDate + "to" + we.endDate]
                              .files &&
                            props.state.data[we.startDate + "to" + we.endDate]
                              .files.length > 0 && (
                              <span style={{ margin: "5px" }}>
                                <OverlayTrigger
                                  placement="top"
                                  trigger="click"
                                  overlay={
                                    <Tooltip>Show the Attachment</Tooltip>
                                  }
                                  rootClose
                                >
                                  <FaRegEye
                                    size="25"
                                    color={
                                      props.state.data[
                                        we.startDate + "to" + we.endDate
                                      ] &&
                                      props.state.data[
                                        we.startDate + "to" + we.endDate
                                      ].files &&
                                      props.state.data[
                                        we.startDate + "to" + we.endDate
                                      ].files.length > 0
                                        ? "#e83e8c"
                                        : "#fff"
                                    }
                                    onClick={() =>
                                      showAttachment(
                                        props.state.data[
                                          we.startDate + "to" + we.endDate
                                        ].files,
                                        we.startDate + "to" + we.endDate
                                      )
                                    }
                                  />
                                </OverlayTrigger>
                              </span>
                            )}
                        </th>
                        <th className="alignCenter table-active">
                          {(props.state.data[
                            we.startDate + "to" + we.endDate
                          ] &&
                            props.state.data[we.startDate + "to" + we.endDate]
                              .notes) ||
                          (props.state.notes &&
                            props.state.notes[
                              we.startDate + "to" + we.endDate
                            ]) ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>
                                  <table className="table table-hover">
                                    <thead>
                                      <tr>
                                        <th>Notes</th>
                                      </tr>
                                      {props.state.data[
                                        we.startDate + "to" + we.endDate
                                      ] &&
                                        props.state.data[
                                          we.startDate + "to" + we.endDate
                                        ].notes &&
                                        props.state.data[
                                          we.startDate + "to" + we.endDate
                                        ].notes.map((n, g) => (
                                          <tr key={g}>
                                            <th>
                                              {n}
                                              <br />
                                              <span>
                                                <b>By: </b>
                                                {props.user.fullName +
                                                  "(" +
                                                  props.user.type +
                                                  ")"}
                                              </span>
                                            </th>
                                          </tr>
                                        ))}
                                      {props.state.notes &&
                                        props.state.notes[
                                          we.startDate + "to" + we.endDate
                                        ] &&
                                        props.state.notes[
                                          we.startDate + "to" + we.endDate
                                        ].map((n, g) => (
                                          <tr key={g}>
                                            <th>
                                              <span
                                                dangerouslySetInnerHTML={{
                                                  __html: n,
                                                }}
                                              />
                                            </th>
                                          </tr>
                                        ))}
                                    </thead>
                                  </table>
                                </Tooltip>
                              }
                            >
                              <MdOutlineNoteAlt
                                size="25"
                                color="#e83e8c"
                                onClick={() =>
                                  props.events.showNotes(
                                    we.startDate + "to" + we.endDate
                                  )
                                }
                              />
                            </OverlayTrigger>
                          ) : (
                            <MdOutlineNoteAlt
                              size="25"
                              color="#fff"
                              onClick={() =>
                                props.events.showNotes(
                                  we.startDate + "to" + we.endDate
                                )
                              }
                            />
                          )}
                        </th>
                        <th className="table-active alignCenter">
                          <OverlayTrigger
                            placement="top"
                            trigger="click"
                            overlay={
                              <Tooltip>
                                {!!status &&
                                status === "Approved" &&
                                props.user.role.isTimesheetApprover ? (
                                  <StatusUpdate
                                    id={
                                      props.state.data[
                                        we.startDate + "to" + we.endDate
                                      ]._id
                                    }
                                    status={
                                      props.state.data[
                                        we.startDate + "to" + we.endDate
                                      ].status
                                    }
                                    setMsg={props.events.setMsg}
                                    setLoad={props.events.setLoad}
                                  />
                                ) : (
                                  "Time Sheet Status"
                                )}
                              </Tooltip>
                            }
                            rootClose
                          >
                            <Badge
                              variant={
                                status === "Awaiting For Review"
                                  ? "warning"
                                  : status === "Approved"
                                  ? "success"
                                  : status === "Partially Approve"
                                  ? "success"
                                  : status === "Resubmit"
                                  ? "danger"
                                  : "info"
                              }
                              className="rounded-corners"
                            >
                              {status}
                            </Badge>
                          </OverlayTrigger>
                        </th>
                      </tr>
                    </Fragment>
                  ))}
              </thead>
            </table>
            <div className="col-md-12" align="center">
              <h4>
                Total Hours:{" "}
                <span className="text-success">{totalHours}.00 hours</span>
              </h4>
              <br />
              {nonApprovedCnt === 0 ? (
                <Badge variant="success">{props.state.status}</Badge>
              ) : (
                <>
                  <button
                    className="btn btn-sm btn-success mr-2"
                    onClick={() => props.events.onSubmit("Saved")}
                  >
                    <h5>SAVE</h5>
                  </button>
                  {new Date(
                    moment(props.state.startDate)
                      .endOf("month")
                      .format("MM/DD/YYYY")
                  ) <= new Date(moment().format("MM/DD/YYYY")) && (
                    <button
                      className="btn btn-sm btn-success"
                      onClick={() => props.events.onSubmit("Submitted")}
                    >
                      <h5>SUBMIT</h5>
                    </button>
                  )}
                </>
              )}
            </div>

            {openModal && (
              <Modal
                show={true}
                size="md"
                onHide={() => setOpenModal(false)}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title> Attachments</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Grid container spacing={2}></Grid>
                </Modal.Body>
                {attachment &&
                  attachment.map((a, i) => (
                    <span style={{ margin: "15px" }} key={i}>
                      <a
                        href={
                          process.env.REACT_APP_BACKEND_API_URL +
                          "auth/timesheet/doc/" +
                          props.state.companyId +
                          "/" +
                          a
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Attached File {i + 1}
                      </a>
                    </span>
                  ))}
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setOpenModal(false)}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
          </PortletBody>
        </Portlet>
      </Grid>
      <Grid item md={4} align="center"></Grid>
      <Grid item md={4} align="center">
        <table className="table table-dark">
          <thead>
            <tr className="table-active">
              <th style={{ textAlign: "center" }}>
                <h4>Calendar View</h4>
                <div>
                  <Badge variant="info" className="rounded-corners mr-2">
                    Not Submitted
                  </Badge>
                  <Badge variant="warning" className="rounded-corners mr-2">
                    Awaiting For Review
                  </Badge>
                  <Badge variant="success" className="rounded-corners mr-2">
                    Approved
                  </Badge>
                  <Badge variant="danger" className="rounded-corners mr-2">
                    Resubmit
                  </Badge>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <DateRange
                  editableDateInputs={true}
                  onChange={(e) => onCalendarChangeHandler(e)}
                  moveRangeOnFirstSelection={false}
                  ranges={tWeeks}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Grid>
      <Grid item md={4} align="center"></Grid>
    </Grid>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(Monthly);
