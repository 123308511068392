import React from "react";
import {
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";

const Sort = (props) => {
  const onClickHandler = (data) => {
    props.actions.sort(data);
    props.actions.load(true);
  };

  return (
    <span style={{ textAlign: "right" }} className="ml-3">
      {props.data.order === 1 ? (
        <AiOutlineSortAscending
          onClick={() => onClickHandler({ field: props.field, order: -1 })}
        />
      ) : (
        <AiOutlineSortDescending
          onClick={() => onClickHandler({ field: props.field, order: 1 })}
        />
      )}
    </span>
  );
};

export default Sort;
