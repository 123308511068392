import React from "react";
import NumberFormat from "react-number-format";
import FormGroup from "./utils/FormGroup";

const Amount = (props) => {
  return (
    <FormGroup element={props.element} module={props.module}>
      {props.module.permissions.update ? (
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fas fa-dollar-sign"></i>
            </span>
          </div>
          <NumberFormat
            thousandSeparator={true}
            placeholder={props.element.placeholder}
            type="text"
            id={props.element.id}
            onChange={props.events.change}
            value={props.element.value}
            disabled={props.element.readonly ? true : false}
            className={"form-control " + props.element.class}
          />
        </div>
      ) : (
        <h6 className="text-primary">{props.element.value}</h6>
      )}
    </FormGroup>
  );
};

export default Amount;
