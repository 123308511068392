import React, { useState, useEffect } from "react";
import axios from "axios";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { backendApiUrl, formatBytes } from "../../utils";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../partials/content/Portlet";

const StandardDocs = (props) => {
  const [state, setState] = useState({ load: true });
  let MI = { ...props.MI };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      let d = {
        docType: "standard_doc_version",
        files: acceptedFiles,
        module: "opportunities",
        owner: props.MI.MDID,
      };

      if (MI.files) {
        if (MI.files["standard_doc_version"]) {
          MI.files["standard_doc_version"].files = [
            ...MI.files["standard_doc_version"].files,
            ...acceptedFiles,
          ];
        } else {
          MI.files["standard_doc_version"] = d;
        }
      } else {
        MI.files = {
          standard_doc_version: d,
        };
      }
      props.events.setModuleInfo(MI);
    },
  });


  const deleteNewFile = (elm, file) => {
    let files = [];
    MI.files[elm].files.forEach((f) => {
      if (f.name !== file.name) {
        files = [...files, f];
      }
    });
    MI.files[elm].files = files;
    props.events.setModuleInfo(MI);
  };

  let new_files = "";
  if (
    MI.files &&
    MI.files["standard_doc_version"] &&
    MI.files["standard_doc_version"].files
  ) {
    new_files = MI.files["standard_doc_version"].files.map((file) => (
      <li key={file.path}>
        <span className="mr-2">
          {file.path} - {formatBytes(file.size, 2)}&nbsp;
          <i
            className="fa fa-trash fa-lg text-danger"
            title="Deactivate"
            onClick={() => deleteNewFile("standard_doc_version", file)}
          />
        </span>
      </li>
    ));
  }

  useEffect(() => {
    if (state.load) {
      axios
        .post(
          backendApiUrl("standard_docs/module"),
          { form: props.MI.form._id },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let st = { ...state, ...res.data, load: false };
          setState(st);
        })
        .catch((err) => {
          let st = { ...state };
          st.load = false;
          setState(st);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.load]);

  return (
    <div className="mt-3">
      <Portlet>
        <PortletHeader title="Standard Docs" />
        <PortletBody>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{ paddingLeft: "100px", paddingRight: "100px" }}
            >
              <div className="form-group">
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />{" "}
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
              </div>
              {!!new_files && (
                <aside className="mt-2" name={"standard_doc_version"}>
                  <h6>New Files</h6>
                  <ul>{new_files}</ul>
                </aside>
              )}
            </Grid>
          </Grid>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th nowrap="true">Title</th>
                  <th nowrap="true">Description</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {state.load ? (
                  <tr>
                    <td colSpan="4">
                      <div style={{ textAlign: "center" }}>
                        <Spinner animation="border" variant="primary" />
                      </div>
                    </td>
                  </tr>
                ) : (
                  state.docs.map((f, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{f.doc.title}</td>
                      <td>{f.doc.description}</td>
                      <td className="fa">
                        <Link
                          to={
                            "/standard_docs/view/" + f._id + "/" + props.MI.MDID
                          }
                          className="btn btn-sm btn-primary"
                        >
                          View Document
                        </Link>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </PortletBody>
      </Portlet>
    </div>
  );
};

export default StandardDocs;
