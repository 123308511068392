import React, { useState } from "react";
import axios from "axios";
import { Grid } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import copy from "copy-to-clipboard";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import MIME_TYPE from "../../config/MIME_TYPE";
import { backendApiUrl, formatBytes } from "../../utils";
import { Deactivate } from "../../utils/ActivateAndDeactivate";

import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../partials/content/Portlet";

const PublicFiles = (props) => {
  let MI = { ...props.module };
  const [doc, setDoc] = useState(
    MI.uploadedDocs && MI.uploadedDocs["public_posting"]
      ? MI.uploadedDocs["public_posting"]
      : {}
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      let d = {
        docType: "public_posting",
        files: acceptedFiles,
        status: "Awaiting Review",
        module: "opportunities",
        owner: props.module.MDID,
      };

      if (MI.files) {
        if (MI.files["public_posting"]) {
          MI.files["public_posting"].files = [
            ...MI.files["public_posting"].files,
            ...acceptedFiles,
          ];
        } else {
          MI.files["public_posting"] = d;
        }
      } else {
        MI.files = {
          public_posting: d,
        };
      }

      props.events.setModuleInfo(MI);
    },
  });

  const deleteNewFile = (elm, file) => {
    let files = [];
    MI.files[elm].files.forEach((f) => {
      if (f.name !== file.name) {
        files = [...files, f];
      }
    });
    MI.files[elm].files = files;
    props.events.setModuleInfo(MI);
  };

  let new_files = "";
  if (
    MI.files &&
    MI.files["public_posting"] &&
    MI.files["public_posting"].files
  ) {
    new_files = MI.files["public_posting"].files.map((file) => (
      <li key={file.path}>
        <span className="mr-2">
          {file.path} - {formatBytes(file.size, 2)}&nbsp;
          <i
            className="fa fa-trash fa-lg text-danger"
            title="Deactivate"
            onClick={() => deleteNewFile("public_posting", file)}
          />
        </span>
      </li>
    ));
  }

  const onDeleteHandler = (msg) => {
    if (!!msg._id) {
      let files = [];
      doc.files.forEach((f) => {
        if (msg._id !== f._id) files = [...files, f];
      });
      let d = { ...doc };
      d.files = files;
      setDoc(d);
      props.events.setMsg(msg);
    }
  };

  const previewOnClickHandler = (id) => {
    axios
      .get(backendApiUrl("auth/file/view/" + id), {
        responseType: "blob",
      })
      .then((res) => {
        const file = new Blob([res.data], {
          type: res.headers["content-type"],
        });

        let extension =
          res.headers["content-type"] === "text/csv; charset=UTF-8"
            ? "csv"
            : "";
        MIME_TYPE.forEach((m) => {
          if (res.headers["content-type"] === m.value) extension = m.label;
        });
        //Build a URL from the file
        const url = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        if (["docx", "doc", "csv"].includes(extension))
          link.setAttribute("download", "file." + extension); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let sno = 1;

  const getFileName = (name) => {
    let obj = name.split("-");
    return obj[1];
  };

  return (
    <Portlet>
      <PortletHeader title="LCA Public Posting  & Job Posting" />
      <PortletBody>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{ paddingLeft: "100px", paddingRight: "100px" }}
          >
            <div className="form-group">
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />{" "}
                <p>Drag 'n' drop some files here, or click to select files</p>
              </div>
            </div>
            {!!new_files && (
              <aside className="mt-2" name={"public_posting"}>
                <h6>New Files</h6>
                <ul>{new_files}</ul>
              </aside>
            )}
          </Grid>
        </Grid>
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>title</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {doc.files &&
                doc.files.map(
                  (f, z) =>
                    (f.active || f.active === undefined) && (
                      <tr key={f._id}>
                        <td>{sno++}</td>
                        <td>
                          {getFileName(f.name) + "-" + formatBytes(f.size, 2)}
                        </td>
                        <td>
                          <i
                            className="fa fa-eye fa-lg mr-2"
                            style={{ color: "#5867dd" }}
                            onClick={() => previewOnClickHandler(f._id)}
                            aria-hidden="true"
                          />
                          <OverlayTrigger
                            trigger="click"
                            rootClose
                            placement="top"
                            overlay={<Tooltip>Link Copyed..</Tooltip>}
                          >
                            <i
                              className="fa fa-clipboard fa-lg mr-2 text-success"
                              onClick={() =>
                                copy(backendApiUrl("auth/file/view/" + f._id))
                              }
                              aria-hidden="true"
                            />
                          </OverlayTrigger>
                          <Deactivate
                            url={backendApiUrl("docs/file/" + f._id)}
                            id={f._id}
                            actions={{
                              msg: onDeleteHandler,
                            }}
                            title={f.name}
                          />
                        </td>
                      </tr>
                    )
                )}
            </tbody>
          </table>
        </div>
      </PortletBody>
    </Portlet>
  );
};

export default PublicFiles;
