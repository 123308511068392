import React from "react";
import { connect } from "react-redux";

const ActionButtons = (props) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const tab = params.get("tab");

  let st = { ...props.state };
  let tabs = [];
  const getTabs = (config) => {
    Array.isArray(config) &&
      config.forEach((c) => {
        if (c.type === "item") {
          let allow = true;
          if (c.hided_roles && props.user) {
            c.hided_roles.forEach((r) => {
              if (r._id === props.user.role._id) {
                allow = false;
              }
            });
          }
          if (allow) tabs = [...tabs, c.id];
        }
        getTabs(c.childs);
      });
  };
  getTabs(st.form.content);
  tabs = [...tabs, ...st.allowed_tabs];

  let activeTab = !!st.form.activeTab ? st.form.activeTab : tabs[0];
  let position = tabs.indexOf(activeTab);
  let next = "";
  let previous = "";
  if (!!tabs[position + 1]) next = tabs[position + 1];
  if (!!tabs[position - 1]) previous = tabs[position - 1];

  const onButtonClickHandler = (tab, from) => {
    var myDiv = document.getElementById("kt_content");
    myDiv.scrollTop = 0;
    return props.events.tabChange(tab, from);
  };

  const convertClickHandler = () => {
    let st = { ...props.state };
    st.convertLead = st.savedData;
    props.events.setState(st);
  };

  return !["timesheets", "opportunities"].includes(tab) ? (
    <div
      align="right"
      id="action_buttons"
      style={
        props.user && props.user.type === "Super"
          ? { display: "block" }
          : { display: "none" }
      }
    >
      {tabs.length > 1 && (
        <>
          <button
            className="btn btn-primary mr-2"
            id="back"
            disabled={!!previous ? false : true}
            onClick={() => onButtonClickHandler(previous, "back")}
          >
            <i className="fa fa-angle-double-left mr-2" />
            Back
          </button>
          <button
            className="btn btn-primary mr-2"
            id="next"
            disabled={!!next ? false : true}
            onClick={() => onButtonClickHandler(next)}
          >
            Next
            <i className="fa fa-angle-double-right ml-2" />
          </button>
        </>
      )}
      {!!st.MDID ? (
        <>
          {props.user &&
          props.user.type === "Prospects" &&
          props.state.form &&
          props.state.form.title === "Opportunities" ? (
            <>
              <button
                className="btn btn-primary mr-2"
                id="force_update"
                disabled={st.permissions.update ? false : true}
                onClick={() => props.events.onSubmit("Force Update")}
              >
                Save
              </button>
              <button
                className="btn btn-primary mr-2"
                id="update"
                disabled={st.permissions.update ? false : true}
                onClick={() => props.events.onSubmit("Final Submit")}
              >
                Submit
              </button>
            </>
          ) : props.user &&
            props.user.type === "Prospects" &&
            props.state.form &&
            props.state.form.title === "Prospects" &&
            props.user &&
            props.user.role &&
            props.user.role.company === "6296265fb480ef015ac96aad" ? (
            <button
              className="btn btn-primary mr-2"
              id="force_update"
              disabled={st.permissions.update ? false : true}
              onClick={() => props.events.onSubmit("Force Update")}
            >
              Save Profile
            </button>
          ) : props.user &&
            props.user.type === "Prospects" &&
            props.state.form &&
            props.state.form.title === "Prospects" ? (
              
            <button
              className="btn btn-primary mr-2"
              id="update"
              disabled={st.permissions.update ? false : true}
              onClick={props.events.onSubmit}
            >
              Save Profile
            </button>
          ) : (
            <button
              className="btn btn-primary mr-2"
              id="update"
              disabled={st.permissions.update ? false : true}
              onClick={props.events.onSubmit}
            >
              Save
            </button>
          )}
          {props.user && props.user.type === "Workforces" && (
            <button
              className="btn btn-primary mr-2"
              id="force_update"
              disabled={st.permissions.update ? false : true}
              onClick={() => props.events.onSubmit("Force Update")}
            >
              Skip Mandatory & Save
            </button>
          )}
        </>
      ) : props.user ? (
        <>
          <button
            className="btn btn-primary mr-2"
            id="update"
            onClick={props.events.onSubmit}
          >
            Create
          </button>
          <button
            className="btn btn-primary mr-2"
            id="force_update"
            disabled={st.permissions.update ? false : true}
            onClick={() => props.events.onSubmit("Force Update")}
          >
            Skip Mandatory & Save
          </button>
        </>
      ) : (
        <button
          className="btn btn-primary mr-2"
          id="create"
          onClick={props.events.onSubmit}
        >
          Submit
        </button>
      )}

      {st.savedData && st.savedData.converted ? (
        <button className="btn btn-success mr-2" id="converted">
          Converted
        </button>
      ) : (
        st.permissions.convert && (
          <button
            className="btn btn-primary mr-2"
            id="convert"
            color="primary"
            onClick={() => convertClickHandler()}
          >
            Convert
          </button>
        )
      )}
    </div>
  ) : (
    ""
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(ActionButtons);