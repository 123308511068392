import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { connect } from "react-redux";
import objectPath from "object-path";
import Header from "./header/Header";
import SubHeader from "./sub-header/SubHeader";
import HeaderMobile from "./header/HeaderMobile";
import ScrollTop from "../../app/partials/layout/ScrollTop";
import HTMLClassService from "./HTMLClassService";
import LayoutConfig from "./LayoutConfig";
import MenuConfig from "./MenuConfig";
import LayoutInitializer from "./LayoutInitializer";
import QuickPanel from "../../app/partials/layout/QuickPanel";
import KtContent from "./KtContent";
import PublicContent from "./PublicContent";
import "./assets/Base.scss";

import BuilderTools from "../builders/tools/BuilderTools";
import Footer from "../builders/Footer";
import ModuleFooter from "./ModuleFooter";
import Message from "../utils/Message";
import { metronic } from "../../_metronic";
import { useDispatch } from "react-redux";

const htmlClassService = new HTMLClassService();

function Layout({ children, selfLayout, layoutConfig }) {
  const dispatch = useDispatch();
  const [msg, setMsg] = useState({});

  const { alertMsg } = useSelector(
    ({ builder }) => ({
      alertMsg: builder.alertMsg,
    }),
    shallowEqual
  );

  const setAlertMsg = (msg) => {
    dispatch(metronic.builder.actions.setAlertMsg(msg));
  };

  htmlClassService.setConfig(layoutConfig);

  useEffect(() => {
    setMsg(alertMsg);
  }, [alertMsg]);

  return selfLayout !== "blank" &&
    !window.location.pathname.includes("/forms/") &&
    !window.location.pathname.includes("/public/") &&
    !window.location.pathname.includes("/doc/view") &&
    !window.location.pathname.includes("/email/unsubscribe") ? (
    <LayoutInitializer
      menuConfig={MenuConfig}
      layoutConfig={LayoutConfig}
      htmlClassService={htmlClassService}
    >
      <HeaderMobile />
      <div
        className="kt-grid kt-grid--hor kt-grid--root"
        style={{ marginTop: "50px" }}
      >
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
          {(window.location.pathname === "/builders/modules/form" ||
            window.location.pathname === "/builders/opportunity/form" ||
            window.location.pathname === "/builders/webforms/form" ||
            window.location.pathname === "/builders/libraries/form") && (
            <BuilderTools />
          )}
          <div
            className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
            id="kt_wrapper"
          >
            <Header />
            <SubHeader />
            {msg && msg.show && (
              <Message status={msg.status} setMsg={setAlertMsg} msg={msg.msg} />
            )}
            <div
              id="kt_content"
              className={`kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor`}
              style={{
                height: window.innerHeight - 201 + "px",
                overflowY: "scroll",
              }}
            >
              <KtContent>{children}</KtContent>
            </div>
            {window.location.pathname.includes("/form") && <Footer />}
            {window.location.pathname.includes("/edit") ||
            window.location.pathname.includes("/create") ||
            window.location.pathname.includes("/leads/") ? (
              <ModuleFooter />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <QuickPanel />
      <ScrollTop />
    </LayoutInitializer>
  ) : (
    // BLANK LAYOUT
    <div className="kt-grid kt-grid--ver kt-grid--root">
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div
          id="kt_content"
          className={`kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor`}
          style={{
            height: window.innerHeight - 141 + "px",
            overflowY: "scroll",
          }}
        >
          <PublicContent>{children}</PublicContent>
        </div>
        <ModuleFooter />
      </div>
    </div>
  );
}

const mapStateToProps = ({ builder: { layoutConfig } }) => ({
  layoutConfig,
  selfLayout: objectPath.get(layoutConfig, "self.layout"),
  subheaderDisplay: objectPath.get(layoutConfig, "subheader.display"),
  desktopHeaderDisplay: objectPath.get(
    layoutConfig,
    "header.self.fixed.desktop"
  ),
  contentContainerClasses: "",
});

export default connect(mapStateToProps)(Layout);
