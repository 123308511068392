import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Select from "react-select";
import axios from "axios";
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";

import "../../_metronic/_assets/sass/app_tab.scss";
import { backendApiUrl } from "../utils";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../partials/content/Portlet";

const Admin = (props) => {
  let MI = { ...props.module };
  let info = MI.admin ? MI.admin : {};

  const [state, setState] = useState({
    load: true,
    products: [],
    programs: [],
    allPrograms: [],
    workforces: [],
    contacts: [],
    prospects: [],
    productWithPrograms: [],
  });

  const onSelectHandler = (values, field) => {
    if (values === null) values = [];
    MI.admin[field] = values;

    let st = { ...state };
    if (field === "product") {
      st.programs = [];
      state.allPrograms.forEach((p) => {
        if (values._id === p.product) {
          p.value = p._id;
          p.label = p.title;
          st.programs = [...st.programs, p];
        }
      });
    }
    setState(st);
    props.actions.setModuleInfo(MI);
  };

  useEffect(() => {
    if (state.load) {
      axios
        .get(backendApiUrl("opportunities/admin"))
        .then((res) => {
          let rs = { ...res.data };

          let st = { ...state };
          let opp = MI.opportunity ? MI.opportunity : {};
          MI.admin = {};
          st.products = rs.products;
          st.allPrograms = rs.programs;
          st.workforces = rs.workforces;
          st.contacts = rs.contacts;
          st.status = rs.status;
          st.source = rs.source;
          st.prospects = rs.prospects;
          st.groups = rs.groups;
          st.productWithPrograms = rs.productWithPrograms;

          if (!!opp.product) {
            rs.products.forEach((p) => {
              if (opp.product === p._id) MI.admin.product = p;
            });
            rs.programs.forEach((p) => {
              if (opp.product === p.product) st.programs = [...st.programs, p];
              if (!!opp.program && opp.program._id === p._id) {
                MI.admin.program = {
                  label: p.title,
                  value: p.product + "---" + p._id,
                };
              }
            });
          }

          if (opp.workforces) {
            rs.workforces.forEach((w) => {
              opp.workforces &&
                opp.workforces.forEach((ow) => {
                  if (w.value === ow.user + "---" + ow.role) {
                    if (MI.admin.workforces) {
                      MI.admin.workforces = [...MI.admin.workforces, w];
                    } else {
                      MI.admin.workforces = [w];
                    }
                  }
                });
            });
          }

          if (opp.contacts) {
            rs.contacts.forEach((w) => {
              opp.contacts &&
                opp.contacts.forEach((ow) => {
                  if (w.value === ow.user + "---" + ow.role) {
                    if (MI.admin.contacts) {
                      MI.admin.contacts = [...MI.admin.contacts, w];
                    } else {
                      MI.admin.contacts = [w];
                    }
                  }
                });
            });
          }

          if (opp.status) {
            rs.status.forEach((w) => {
              if (opp.status.includes(w._id)) {
                if (MI.admin.status) {
                  MI.admin.status = [...MI.admin.status, w];
                } else {
                  MI.admin.status = [w];
                }
              }
            });
          }

          if (opp.source) {
            rs.source.forEach((w) => {
              if (opp.source.includes(w._id)) {
                if (MI.admin.source) {
                  MI.admin.source = [...MI.admin.source, w];
                } else {
                  MI.admin.source = [w];
                }
              }
            });
          }

          if (opp.groups) MI.admin.groups = opp.groups;

          st.load = false;
          setState(st);
          props.actions.setModuleInfo(MI);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [state, MI, props.actions]);

  let allowToEdit = false;
  let permissions = {};
  if (props.user.role.admin || MI.MDID === "") {
    permissions = { add: true, update: true, delete: true };
  } else if (MI.permissions && MI.permissions.auth) {
    permissions = MI.permissions.auth;
  }
  if (permissions.add || permissions.update) allowToEdit = true;

  const prospectOnChangeHandler = (e) => {
    MI.prospect = e.value;
    props.actions.setModuleInfo(MI);
  };

  let selProspect = {};
  state.prospects.forEach((p) => {
    if (p.value === MI.prospect) selProspect = p;
  });

  return state.load ? (
    <div style={{ textAlign: "center" }}>
      <Spinner animation="border" variant="primary" />
    </div>
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Portlet>
          <PortletHeader title="Admin Information" />
          <PortletBody>
            <Grid container spacing={2}>
              {MI.from === "opportunities" && (
                <>
                  {window.location.pathname === "/petitions" && (
                    <Grid item xs={12}>
                      <label className="form-label" htmlFor="Employee">
                        Employee <span className="text-danger">*</span>
                      </label>
                      <Select
                        className="kt-width-full"
                        name="prospect"
                        options={state.prospects}
                        onChange={(v) => prospectOnChangeHandler(v)}
                        value={selProspect}
                        placeholder="Please select employee"
                      />
                    </Grid>
                  )}
                  {/* <Grid item xs={12}>
                    <label className="form-label" htmlFor="product">
                      Product <span className="text-danger">*</span>
                    </label>
                    {allowToEdit ? (
                      <Select
                        className="kt-width-full"
                        name="product"
                        options={state.products}
                        onChange={(v) => onSelectHandler(v, "product")}
                        value={info.product ? info.product : {}}
                        placeholder="Please select product"
                      />
                    ) : (
                      <h6 className="text-primary">
                        {info.product && info.product.title}
                      </h6>
                    )}
                  </Grid> */}

                  <Grid item xs={12}>
                    <label className="form-label" htmlFor="program">
                      Program <span className="text-danger">*</span>
                    </label>
                    {allowToEdit ? (
                      <Select
                        className="kt-width-full"
                        name="program"
                        options={state.productWithPrograms}
                        onChange={(v) => onSelectHandler(v, "program")}
                        value={info.program}
                        placeholder="Please select program"
                      />
                    ) : (
                      <h6 className="text-primary">
                        {info.program && info.program.label}
                      </h6>
                    )}
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <label className="form-label" htmlFor="workforces">
                  Workforces <span className="text-danger">*</span>
                </label>
                {allowToEdit ? (
                  <Select
                    className="kt-width-full"
                    name="workforces"
                    options={state.workforces}
                    onChange={(v) => onSelectHandler(v, "workforces")}
                    value={info.workforces}
                    isMulti
                    placeholder="Please select Workforces"
                  />
                ) : (
                  <h6 className="text-primary">
                    {info.workforces &&
                      info.workforces.map((v, i) =>
                        i === 0 ? v.label : ", " + v.label
                      )}
                  </h6>
                )}
              </Grid>

              <Grid item xs={12}>
                <label className="form-label" htmlFor="contacts">
                  Contacts
                </label>
                {allowToEdit ? (
                  <Select
                    className="kt-width-full"
                    name="contacts"
                    options={state.contacts}
                    onChange={(v) => onSelectHandler(v, "contacts")}
                    value={info.contacts}
                    isMulti
                    placeholder="Please select Contacts"
                  />
                ) : (
                  <h6 className="text-primary">
                    {info.contacts &&
                      info.contacts.map((v, i) =>
                        i === 0 ? v.label : ", " + v.label
                      )}
                  </h6>
                )}
              </Grid>

              {MI.from === "opportunities" && (
                <Grid item xs={12}>
                  <label className="form-label" htmlFor="groups">
                    Groups
                  </label>
                  {allowToEdit ? (
                    <Select
                      className="kt-width-full"
                      name="groups"
                      options={state.groups}
                      onChange={(v) => onSelectHandler(v, "groups")}
                      value={info.groups}
                      isMulti
                      placeholder="Please select groups"
                    />
                  ) : (
                    <h6 className="text-primary">
                      {info.groups &&
                        info.groups.map((v, i) =>
                          i === 0 ? v.label : ", " + v.label
                        )}
                    </h6>
                  )}
                </Grid>
              )}

              {MI.from === "opportunities" && (
                <Grid item xs={12}>
                  <label className="form-label" htmlFor="status">
                    Status
                  </label>
                  {allowToEdit ? (
                    <Select
                      className="kt-width-full"
                      name="status"
                      options={state.status}
                      onChange={(v) => onSelectHandler(v, "status")}
                      value={info.status}
                      isMulti
                      placeholder="Please select Status"
                    />
                  ) : (
                    <h6 className="text-primary">
                      {info.status &&
                        info.status.map((v, i) =>
                          i === 0 ? v.label : ", " + v.label
                        )}
                    </h6>
                  )}
                </Grid>
              )}

              <Grid item xs={12}>
                <label className="form-label" htmlFor="status">
                  Source
                </label>
                {allowToEdit ? (
                  <Select
                    className="kt-width-full"
                    name="source"
                    options={state.source}
                    onChange={(v) => onSelectHandler(v, "source")}
                    value={info.source}
                    isMulti
                    placeholder="Please select Source"
                  />
                ) : (
                  <h6 className="text-primary">
                    {info.source &&
                      info.source.map((v, i) =>
                        i === 0 ? v.label : ", " + v.label
                      )}
                  </h6>
                )}
              </Grid>
            </Grid>
          </PortletBody>
        </Portlet>
      </Grid>
      {Array.isArray(info.workforces) && (
        <Grid item xs={6}>
          <Portlet>
            <PortletHeader title="Assigned Workforces" />
            <PortletBody>
              <Grid container spacing={2}>
                {info.workforces.map((w, i) => (
                  <Grid item xs={6} key={i}>
                    <h5 className="text-primary">{w.role}</h5>
                    <p>First Name: {w.info.first_name}</p>
                    <p>Last Name: {w.info.last_name}</p>
                    <p>Email: {w.info.email}</p>
                    <p>Phone: {w.info.phone}</p>
                  </Grid>
                ))}
              </Grid>
            </PortletBody>
          </Portlet>
        </Grid>
      )}
      {Array.isArray(info.contacts) && (
        <Grid item xs={6}>
          <Portlet>
            <PortletHeader title="Assigned Contacts" />
            <PortletBody>
              <Grid container spacing={2}>
                {info.contacts.map((w, i) => (
                  <Grid item xs={6} key={i}>
                    <h5 className="text-primary">{w.role}</h5>
                    <p>First Name: {w.info.first_name}</p>
                    <p>Last Name: {w.info.last_name}</p>
                    <p>Email: {w.info.email}</p>
                    <p>Phone: {w.info.phone}</p>
                  </Grid>
                ))}
              </Grid>
            </PortletBody>
          </Portlet>
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(Admin);
