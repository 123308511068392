import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { BsPersonFillAdd } from "react-icons/bs";
import { FaUserEdit } from "react-icons/fa";
import { FcInfo } from "react-icons/fc";
import { TablePagination } from "@material-ui/core";
import { Spinner } from "react-bootstrap";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { backendApiUrl } from "../../utils";
import Message from "../../utils/Message";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../partials/content/Portlet";
import Clients from "./Clients";
import TablePaginationActions from "../../shared/TablePaginationActions";
import Sort from "../../utils/Sort";
import Sheets from "../Sheets";

const moment = require("moment-timezone");

const Employment = (props) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const clid = params.get("cl");

  const [load, setLoad] = useState(true);

  const [records, setRecords] = useState([]);

  const [client, setClient] = useState({});

  const [users, setUsers] = useState([]);

  const [totalDocumnetCount, setTotalDocumnetCount] = useState(0);

  const [currentRowStart, setCurrentRowStart] = useState(1);

  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sort, setSort] = useState({});

  const [sheet, setSheet] = useState({});

  const [msg, setMsg] = useState({});

  const prospect = useParams().MDID;

  const handleChangePage = (event, newPage) => {
    if (
      Math.max(0, Math.ceil(totalDocumnetCount / rowsPerPage) - 1) === newPage
    ) {
      setCurrentRowStart(totalDocumnetCount - rowsPerPage + 1);
    } else if (page < newPage) {
      setCurrentRowStart(currentRowStart + rowsPerPage);
    } else if (newPage === 0) {
      setCurrentRowStart(1);
    } else {
      setCurrentRowStart(currentRowStart - rowsPerPage);
    }
    setPage(newPage);
    setLoad(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setLoad(true);
  };

  useEffect(() => {
    if (load) {
      axios
        .post(
          backendApiUrl("timesheet"),
          { prospect },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setRecords(res.data.clients);
          setUsers(res.data.users);
          setTotalDocumnetCount(res.data.count);
          if (!!clid) {
            res.data.clients.forEach((cl) => {
              if (cl._id === clid) setSheet(cl);
            });
          }
          setLoad(false);
        })
        .catch((err) => {
          setLoad(false);
        });
    }
  }, [load, prospect, clid]);

  if (load) {
    return (
      <div align="center">
        <Spinner animation="border" variant="info" />
      </div>
    );
  } else {
    if (client.show || !!client._id) {
      return (
        <div className="container">
          <Clients
            client={client}
            setLoad={setLoad}
            onClose={setClient}
            setMsg={setMsg}
            companyName={props.user.company}
          />
        </div>
      );
    } else if (!!sheet._id) {
      return (
        <Sheets
          client={sheet}
          users={users}
          onBoardingDate={props.module.onBoardingDate}
          onClose={setSheet}
          setLoad={setLoad}
          setMsg={setMsg}
        />
      );
    } else {
      return (
        <Portlet>
          <PortletHeader
            title="Project Details"
            toolbar={
              <PortletHeaderToolbar>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => setClient({ show: true })}
                >
                  <BsPersonFillAdd /> Create Project
                </button>
              </PortletHeaderToolbar>
            }
          />
          <PortletBody>
            {msg.show && (
              <Message status={msg.status} setMsg={setMsg} msg={msg.msg} />
            )}
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th nowrap="true">
                      Client
                      <Sort
                        data={sort}
                        actions={{ sort: setSort, load: setLoad }}
                        field="title"
                      />
                    </th>
                    <th nowrap="true">Vendor's</th>
                    <th nowrap="true">Work Location</th>
                    {/*<th nowrap="true">Team Name</th>*/}
                    <th nowrap="true">Reporting Manager</th>
                    <th nowrap="true">Project Start Date</th>
                    <th nowrap="true">Active Client</th>
                    <th nowrap="true">H1B Filed</th>
                    <th nowrap="true">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {records ? (
                    records.map((cl, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{cl.name}</td>
                        <td>
                          {cl.vendors &&
                            cl.vendors.map((ve, j) => (
                              <span key={j}>
                                {j > 0 && ", "}
                                {ve.name}
                              </span>
                            ))}
                        </td>
                        <td>{cl.location}</td>
                        {/*<td>{cl.teamName}</td>*/}
                        <td>
                          {cl.manager}
                          {!!cl.email && (
                            <span>
                              <br />
                              Email: {cl.email}
                            </span>
                          )}
                          {!!cl.phone && (
                            <span>
                              <br />
                              Contact: {cl.phone}
                            </span>
                          )}
                        </td>
                        <td>
                          {!!cl.project_start_date
                            ? moment(cl.project_start_date).format("MM/DD/YYYY")
                            : ""}
                        </td>
                        <td>{cl.isActiveClient ? "Yes" : "No"}</td>
                        <td>{cl.h1bPetitionFiled ? "Yes" : "No"}</td>
                        <td>
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Click here to edit.</Tooltip>}
                          >
                            <FaUserEdit
                              className="mr-2"
                              color="blue"
                              onClick={() => setClient(cl)}
                              size="25"
                            />
                          </OverlayTrigger>
                          {cl.isActiveClient && (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>Click here to edit timesheet.</Tooltip>
                              }
                            >
                              <Badge
                                className="card card-purple-blue mr-2"
                                onClick={() => setSheet(cl)}
                                size="25"
                              >
                                Submit Timesheet
                              </Badge>
                            </OverlayTrigger>
                          )}

                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                Created by:{" "}
                                <strong>{cl.created_by.user}</strong>
                                <br />
                                Created Date & Time:{" "}
                                <strong>{cl.createdAt}</strong>
                                {!!cl.updated_by.user && (
                                  <>
                                    <br />
                                    Last Updated by:{" "}
                                    <strong>{cl.updated_by.user}</strong>
                                    <br />
                                    Last Updated Date & Time:{" "}
                                    <strong>
                                      {!!cl.updated_by.user ? cl.updatedAt : ""}
                                    </strong>
                                  </>
                                )}
                              </Tooltip>
                            }
                          >
                            <FcInfo size="25" />
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <h5 className="text-primary text-center">
                          No client available, Create one!
                        </h5>
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50]}
                      colSpan={12}
                      count={totalDocumnetCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "Rows per page" },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </tr>
                </tfoot>
              </table>
            </div>
          </PortletBody>
        </Portlet>
      );
    }
  }
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(Employment);
