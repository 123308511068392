/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";

class UserProfile extends React.Component {
  render() {
    const { user, showHi, showAvatar, showBadge } = this.props;
    let moduleName = user.role.title === "Prospect" ? "Employee" : user.role.title;
    return (
      <Dropdown
        className="kt-header__topbar-item kt-header__topbar-item--user"
        drop="down"
        alignRight
      >
        <Dropdown.Toggle
          as={HeaderDropdownToggle}
          id="dropdown-toggle-user-profile"
        >
          <div className="kt-header__topbar-user">
            {showHi && (
              <span className="kt-header__topbar-welcome kt-hidden-mobile">
                Hi,
              </span>
            )}

            {showHi && (
              <span className="kt-header__topbar-username kt-hidden-mobile">
                {user.fullName
                  ? user.fullName + " (" + moduleName + ")"
                  : ""}
              </span>
            )}

            {showAvatar && (
              <img
                alt="Pic"
                src={toAbsoluteUrl("/media/logos/user-icon.png")}
              />
            )}

            {showBadge && (
              <span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">
                {/* TODO: Should get from currentUser */}
                {user.fullName}
              </span>
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          <div
            className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/misc/bg-1.jpg")})`,
            }}
          >
            <div className="kt-user-card__avatar">
              <img
                alt="Pic"
                className="kt-hidden"
                src={toAbsoluteUrl("/media/logos/user-icon.png")}
              />
              <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold text-light">
                <i className="fa fa-user fa-2x" />
              </span>
            </div>
            <div className="kt-user-card__name">{user.fullName}</div>
            <div className="kt-user-card__badge text-light font-weight-bold">
              Name: {user.fullName}
              <br />
              Role: {user.role.title === "Prospect" ? "Employee" : user.role.title}
              <br />
              Type: {user.type === "Prospects" ? "Employees" : user.type}
              <br />
              Company: {user.company}
            </div>
          </div>
          <div className="kt-notification">
            {user.profile && (
              <span className="kt-notification__item">
                <div className="kt-notification__item-icon">
                  <i className="flaticon2-calendar-3 kt-font-success" />
                </div>
                <div className="kt-notification__item-details">
                  <div className="kt-notification__item-title kt-font-bold">
                    <Link to={user.profile} style={{ cursor: "pointer" }}>
                      My Profile
                    </Link>
                  </div>
                </div>
              </span>
            )}
            {user.cprofile && (
              <span className="kt-notification__item">
                <div className="kt-notification__item-icon">
                  <i className="flaticon2-calendar-3 kt-font-success" />
                </div>
                <div className="kt-notification__item-details">
                  <div className="kt-notification__item-title kt-font-bold">
                    <Link to={user.cprofile} style={{ cursor: "pointer" }}>
                      Company Profile
                      <br />
                    </Link>
                  </div>
                  <div className="kt-notification__item-time">
                    Account settings and more
                  </div>
                </div>
              </span>
            )}
            {/* <span className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-mail kt-font-warning" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  My Messages
                </div>
                <div className="kt-notification__item-time">
                  Inbox and tasks
                </div>
              </div>
            </span>
            <span className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-rocket-1 kt-font-danger" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  My Activities
                </div>
                <div className="kt-notification__item-time">
                  Logs and notifications
                </div>
              </div>
            </span>
            <span className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-hourglass kt-font-brand" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  My Tasks
                </div>
                <div className="kt-notification__item-time">
                  latest tasks and projects
                </div>
              </div>
            </span> */}
            <div className="kt-notification__custom">
              <Link
                to="/logout"
                className="btn btn-label-brand btn-sm btn-bold"
              >
                Sign Out
              </Link>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(UserProfile);
