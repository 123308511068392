import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { useImmerReducer } from "use-immer";
import { v4 as uuidv4 } from "uuid";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import DatePicker from "react-datepicker";
import DialogBox from "../utils/DialogBox";
import JumbLink from "../utils/JumbLink";

import { Spinner, Modal, Button, Tabs, Tab } from "react-bootstrap";

import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../partials/content/Portlet";

import { backendApiUrl, getDate, getYearMonth } from "../utils";
import Message from "../utils/Message";
import { checkValidation } from "../utils/FormHandling";

import { initialData } from "./utils/constants";
import { initReducer } from "./utils/reducers";
import Card from "./utils/card";
import Ach from "./utils/ach";
import Filter from "../utils/Filter";

const Payments = (props) => {
  // console.log(props);
  const [paymentsData, setPaymentsData] = useImmerReducer(
    initReducer,
    initialData(props)
  );

  const [filters, setFilters] = useState([]);

  const stripePromise = loadStripe(
    "pk_test_51JeLxzJZZEB4Pj4qT9ysFz493oKUWxDu83bf12lcqfKucevbMetVtyq6ZPMLyZKRVACp3iUePhL9LwSxmEsxghMw00ZigURbUq",
    {
      stripeAccount: paymentsData.formData.stripeConnectedAcc,
    }
  );
  const [reqFields, setReqFields] = useState([]);
  // console.log(reqFields);

  const updateMultipleFormData = (obj) => {
    setPaymentsData({ type: "updateMultipleFormData", payload: obj });
  };

  const deleteNestedForm = (obj) => {
    setPaymentsData({ type: "deleteNestedForm", payload: obj });
  };

  const updateNestedLoopFormData = (obj) => {
    setPaymentsData({ type: "updateNestedLoopFormData", payload: obj });
  };
  console.log(paymentsData.formData);
  const addNewRow = () => {
    console.log(...paymentsData.formData.payments);
    let f = [
      ...paymentsData.formData.payments,
      {
        _id: uuidv4(),
        item: "",
        amount: "",
      },
    ];
    updateMultipleFormData([{ key: "payments", value: f }]);
  };

  const makePayment = (n) => {
    const stripeCusId = n.prospect?.stripe_info?.customer?.id
      ? n.prospect?.stripe_info?.customer?.id
      : "";
    updateMultipleFormData([
      { key: "client_secret", value: n?.paymentIntent?.client_secret },
      { key: "workforce", value: n.Workforce },
      {
        key: "selectedPayments",
        value: n,
      },
      { key: "showPaymentMethod", value: true },
      { key: "data", subKey: "stripeCusId", value: stripeCusId },
    ]);
  };

  const paymentsInput = ({ k, sK, val, i }) => {
    updateNestedLoopFormData({
      k,
      sK,
      val,
      i,
    });
  };

  const deletePayment = (confirm, _id) => {
    console.log(_id);
    console.log(confirm);
    if (paymentsData.formData.deletePaymentId && confirm) {
      updateMultipleFormData([{ key: "loader", value: true }]);
      axios
        .delete(
          backendApiUrl("payments/") + paymentsData.formData.deletePaymentId
        )
        .then((res) => {
          // console.log(res);
          updateMultipleFormData([
            { key: "deletePaymentId", value: "" },
            { key: "msg", subKey: "show", value: true },
            { key: "msg", subKey: "msg", value: res.data.message },
            { key: "msg", subKey: "status", value: 200 },
          ]);
          loadPayments();
        })
        .catch((err) => {
          updateMultipleFormData([
            { key: "deletePaymentId", value: "" },
            { key: "msg", subKey: "show", value: true },
            { key: "loader", value: false },
            {
              key: "msg",
              subKey: "msg",
              value: err.response.data.message,
            },
            { key: "msg", subKey: "status", value: 500 },
          ]);
        });
    } else {
      updateMultipleFormData([{ key: "deletePaymentId", value: _id }]);
    }
  };

  const editPaymentRequest = (n) => {
    console.log(new Date(n.subscriptionStartMonth));
    updateMultipleFormData([
      { key: "paymentId", value: n._id },
      { key: "paymentAmount", value: n.paymentAmount },
      { key: "paymentFrequency", value: n.paymentFrequency },
      { key: "recurringDate", value: n.recurringDate },
      {
        key: "subscriptionStartMonth",
        value: new Date(n.subscriptionStartMonth),
      },
      { key: "subscriptionEndMonth", value: new Date(n.subscriptionEndMonth) },
      { key: "reminderBefore", value: n.reminderBefore },
      { key: "lateFeePercentage", value: n.lateFeePercentage },
      { key: "cappedAt", value: n.cappedAt },
      // { key: "paymentMethod", value: n.paymentMethod },
      {
        key: "includeExcludeProcessingFee",
        value: n.convenienceFee === "Included" ? true : false,
      },
      { key: "showPayments", value: true },
    ]);
  };

  const updatePayment = (e) => {
    updateMultipleFormData([{ key: "loader", value: true }]);

    const data = {
      _id: paymentsData.formData.paymentId,
      payments: paymentsData.formData.payments,
      paymentAmount: paymentsData.formData.paymentAmount,
      lateFeePercentage: paymentsData.formData.lateFeePercentage,
      cappedAt: paymentsData.formData.cappedAt,
      paymentMethod:
        paymentsData.formData.paymentFrequency === "Recurring"
          ? { ach: true, card: false }
          : paymentsData.formData.paymentMethod,
      billingInterval: paymentsData.formData.billingInterval,
      paymentFrequency: paymentsData.formData.paymentFrequency,
      module: props.module,
      owner: props.owner,
      userId: props.user._id,
      subscriptionStartMonth: getYearMonth(
        paymentsData.formData.subscriptionStartMonth
      ),
      subscriptionEndMonth: getYearMonth(
        paymentsData.formData.subscriptionEndMonth
      ),
      recurringDate: `${paymentsData.formData.recurringDate}`,
      reminderBefore: `${paymentsData.formData.reminderBefore}`,
      convenienceFee: paymentsData.formData.includeExcludeProcessingFee
        ? "Included"
        : "Excluded",
      prospectId: props.opportunity.prospect._id,
      recipients: [
        {
          email: props.opportunity.prospect.info.email,
          type: "Payments",
        },
      ],
    };
    axios
      .patch(
        backendApiUrl("payments/" + paymentsData.formData.paymentId),
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log(res);
        updateMultipleFormData([
          { key: "showPayments", value: false },
          { key: "msg", subKey: "show", value: true },
          { key: "msg", subKey: "msg", value: res.data.message },
          { key: "msg", subKey: "status", value: 200 },
        ]);
        loadPayments();
      })
      .catch((err) => {
        updateMultipleFormData([
          { key: "msg", subKey: "show", value: true },
          { key: "loader", value: false },
          {
            key: "msg",
            subKey: "msg",
            value: err.response.data.message,
          },
          { key: "msg", subKey: "status", value: 500 },
        ]);
      })
      .finally(() => {
        updateMultipleFormData([
          { key: "paymentMethod", value: { ach: false, card: false } },
        ]);
      });
  };

  const createPayment = (e) => {
    let check = checkValidation("PS_mandatory");
    // console.log(check);
    if (check.length > 0) {
      setReqFields(check);
      return true;
    }

    if (parseFloat(sumPayments(paymentsData.formData.payments)) <= 25) {
      updateMultipleFormData([
        { key: "msg", subKey: "show", value: true },
        {
          key: "msg",
          subKey: "msg",
          value: "Minimum payment is $25.00",
        },
        { key: "msg", subKey: "status", value: 500 },
      ]);
      return true;
    }

    updateMultipleFormData([{ key: "loader", value: true }]);

    const data = {
      payments: paymentsData.formData.payments,
      paymentAmount: paymentsData.formData.paymentAmount,
      lateFeePercentage: paymentsData.formData.lateFeePercentage,
      cappedAt: paymentsData.formData.cappedAt,
      paymentMethod:
        paymentsData.formData.paymentFrequency === "Recurring"
          ? { ach: true, card: false }
          : paymentsData.formData.paymentMethod,
      billingInterval: paymentsData.formData.billingInterval,
      paymentFrequency: paymentsData.formData.paymentFrequency,
      module: props.module,
      owner: props.owner,
      userId: props.user._id,
      subscriptionStartMonth: getYearMonth(
        paymentsData.formData.subscriptionStartMonth
      ),
      subscriptionEndMonth: getYearMonth(
        paymentsData.formData.subscriptionEndMonth
      ),
      recurringDate: `${paymentsData.formData.recurringDate}`,
      reminderBefore: `${paymentsData.formData.reminderBefore}`,
      convenienceFee: paymentsData.formData.includeExcludeProcessingFee
        ? "Included"
        : "Excluded",
      prospectId: props.opportunity.prospect._id,
      recipients: [
        {
          email: props.opportunity.prospect.info.email,
          type: "Payments",
        },
      ],
    };
    axios
      .post(backendApiUrl("payments/create_one_time_payment"), data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // console.log(res);
        updateMultipleFormData([
          { key: "showPayments", value: false },
          { key: "msg", subKey: "show", value: true },
          { key: "msg", subKey: "msg", value: res.data.message },
          { key: "msg", subKey: "status", value: 200 },
        ]);
        loadPayments();
      })
      .catch((err) => {
        updateMultipleFormData([
          { key: "msg", subKey: "show", value: true },
          { key: "loader", value: false },
          {
            key: "msg",
            subKey: "msg",
            value: err.response.data.message,
          },
          { key: "msg", subKey: "status", value: 500 },
        ]);
      })
      .finally(() => {
        updateMultipleFormData([
          { key: "paymentMethod", value: { ach: false, card: false } },
        ]);
      });
  };
  const oppId = props?.owner;
  // console.log(oppId);
  const isAdmin = props.user.role.admin;
  const openFilter = paymentsData.formData.openFilter;

  const loadPayments = useCallback(() => {
    setPaymentsData({
      type: "updateMultipleFormData",
      payload: [{ key: "loader", value: true }],
    });

    let data = {};
    if (oppId !== undefined) {
      data.oppId = oppId;
    } else if (isAdmin) {
      setPaymentsData({
        type: "updateMultipleFormData",
        payload: [{ key: "openFilter", value: true }],
      });
    }

    const url = backendApiUrl(`payments/get_payments`);

    if (filters.length > 0 && openFilter) data.filters = filters;
    axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.payments.length) {
          setPaymentsData({
            type: "updateMultipleFormData",
            payload: [
              {
                key: "stripeConnectedAcc",
                value: res.data.payments[0].company.stripe.id,
              },
              { key: "loader", value: false },
              { key: "data", subKey: "payments", value: res.data.payments },
            ],
          });
        } else {
          setPaymentsData({
            type: "updateMultipleFormData",
            payload: [
              { key: "loader", value: false },
              { key: "data", subKey: "payments", value: [] },
            ],
          });
        }
      })
      .catch((err) => {
        setPaymentsData({
          type: "updateMultipleFormData",
          payload: [
            { key: "msg", subKey: "show", value: true },
            { key: "loader", value: false },
            { key: "data", subKey: "payments", value: [] },
            {
              key: "msg",
              subKey: "msg",
              value: "Unable to load payment. please refresh page again!",
            },
            { key: "msg", subKey: "status", value: 500 },
          ],
        });
      });
  }, [setPaymentsData, oppId, openFilter, isAdmin, filters]);

  useEffect(() => {
    loadPayments();
  }, [loadPayments]);

  const searchClickHandler = (data) => {
    setFilters(data);
  };

  const calculateProcessingFee = (payments) => {
    // console.log("calculateProcessingFee" + payments);
    const percent = 2.9;
    return (percent * payments) / 100;
  };

  const achcalculateProcessingFee = (payments) => {
    if (payments > 625) {
      return 5;
    } else {
      const percent = 0.8;
      return (percent * payments) / 100;
    }
  };

  const achcalculateLateFee = (payment) => {
    const payments = parseFloat(payment);
    const lateFeePercentage = parseFloat(
      paymentsData.formData.lateFeePercentage
    );
    let total = (lateFeePercentage * payments) / 100;
    console.log("total - ", total);
    if (total >= paymentsData.formData.cappedAt) {
      total = paymentsData.formData.cappedAt;
    }

    return (payments + total).toFixed(2);
  };

  const calculateNetFee = (payments) => {
    // console.log("calculateNetFee" + payments);
    const percent = 2.9;
    return (
      payments -
      ((percent * payments) / 100 +
        0.3 +
        paymentsData.formData.applicationFeeAmt)
    );
  };

  const achcalculateNetFee = (payment, type = "") => {
    const payments = parseFloat(payment);
    console.log("calculateNetFee" + payments);
    const percent = 0.8;

    if (type === "add") {
      if (payments > 625) {
        return payments + (5 + paymentsData.formData.applicationFeeAmt);
      } else {
        return (
          payments +
          ((percent * payments) / 100 + paymentsData.formData.applicationFeeAmt)
        );
      }
    } else {
      if (payments > 625) {
        return payments - (5 + paymentsData.formData.applicationFeeAmt);
      } else {
        return (
          payments -
          ((percent * payments) / 100 + paymentsData.formData.applicationFeeAmt)
        );
      }
    }
  };

  const sumPayments = (payments) => {
    console.log("payments", payments);
    let sum = 0;
    for (let payment of payments) {
      let { amount } = payment;
      sum += parseFloat(amount);
    }
    // const sum = payments.reduce(
    //   (n, { amount }) =>
    //     n + parseFloat(amount.replaceAll(",", "")),
    //   0
    // )
    return sum;
  };

  const formatDollar = (amt) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "USD",
    }).format(amt);
  };

  const getStatus = (n) => {
    const type = n.status ? n.status : "";
    switch (type) {
      case "requires_payment_method":
        return "requires payment method";
      case "succeeded":
        return "succeeded";
      default:
        return capitalize(type);
    }
  };

  const capitalize = (s) => {
    return s[0].toUpperCase() + s.slice(1);
  };

  const getButtonStatus = (n) => {
    // console.log(n);
    const type = n.status ? n.status : "";
    switch (type) {
      case "Incomplete":
        return (
          <span
            className="badge badge-warning"
            style={{ cursor: "pointer" }}
            onClick={(e) => makePayment(n)}
          >
            Make Payment
          </span>
        );
      case "failed":
        return (
          <span
            className="badge badge-warning"
            style={{ cursor: "pointer" }}
            onClick={(e) => makePayment(n)}
          >
            Make Payment
          </span>
        );
      case "succeeded":
        return (
          <span className="badge badge-success">{`Payment ${capitalize(
            type
          )}`}</span>
        );
      default:
        return (
          <span className="badge badge-danger">{`Payment ${capitalize(
            type
          )}`}</span>
        );
    }
  };

  const getPaymentsOneTimeRecurring = (payments) => {
    let Recurring = [];
    let Onetime = [];

    for (let payment of payments) {
      if (payment.paymentFrequency === "Recurring") {
        Recurring.push(payment);
      } else {
        Onetime.push(payment);
      }
    }
    // console.log(Recurring);
    return { Onetime, Recurring };
  };

  const viewSubscriptions = (n) => {
    updateMultipleFormData([
      { key: "subscriptionsLoader", value: true },
      { key: "showSubscriptions", value: true },
    ]);
    axios
      .get(backendApiUrl(`payments/get_subscriptions/${n._id}`))
      .then((res) => {
        console.log(res);
        if (res.data.subscriptions.length) {
          setPaymentsData({
            type: "updateMultipleFormData",
            payload: [
              { key: "subscriptionsLoader", value: false },
              { key: "subscriptions", value: res.data.subscriptions },
            ],
          });
        }
      })
      .catch((err) => {
        setPaymentsData({
          type: "updateMultipleFormData",
          payload: [
            { key: "msg", subKey: "show", value: true },
            { key: "loader", value: false },
            {
              key: "msg",
              subKey: "msg",
              value: "Unable to load subscriptions. please refresh page again!",
            },
            { key: "msg", subKey: "status", value: 500 },
          ],
        });
      });
  };

  let minMonth = new Date();
  minMonth.setDate("01");

  const search_fields = [
    {
      id: "paymentFrequency",
      title: "Payment Frequency",
      type: "select",
      options: [
        { label: "Payment Requests", value: "Onetime" },
        { label: "Recurring", value: "Recurring" },
      ],
      show_default: true,
      outside_field: true,
    },
    {
      id: "convenienceFee",
      title: "Convenience Fee",
      type: "select",
      options: [
        { label: "Included", value: "Included" },
        { label: "Excluded", value: "Excluded" },
      ],
      outside_field: true,
    },
    {
      id: "lateFee",
      title: "Late Fee",
      type: "select",
      options: [
        { label: "Included", value: "Included" },
        { label: "Excluded", value: "Excluded" },
      ],
      outside_field: true,
    },
    {
      id: "paymentAmount",
      title: "Payment Amount",
      type: "number",
      outside_field: true,
    },
    {
      id: "dueDate",
      title: "Start Month",
      type: "month",
      outside_field: true,
    },
    {
      id: "dueDate",
      title: "End Month",
      type: "month",
      outside_field: true,
    },
    {
      id: "reminderDate",
      title: "Reminder Date",
      type: "date",
      outside_field: true,
    },
    {
      id: "dueDate",
      title: "Due Date",
      type: "date",
      outside_field: true,
    },
  ];

  return props.user.type === "Workforces" ? (
    paymentsData.formData.loader ? (
      <Grid container spacing={2}>
        <Grid item md={12}>
          <div style={{ textAlign: "center" }}>
            <Spinner animation="border" variant="primary" />
          </div>
        </Grid>
      </Grid>
    ) : (
      <Portlet>
        <PortletHeader
          title="Payments"
          toolbar={
            <PortletHeaderToolbar>
              {/* {permissions.add && ( */}
              <button
                className="btn btn-primary btn-sm"
                onClick={(e) =>
                  updateMultipleFormData([
                    {
                      key: "showPayments",
                      value: !paymentsData.formData.showPayments,
                    },
                  ])
                }
              >
                Create Payments
              </button>
              {/* )} */}
            </PortletHeaderToolbar>
          }
        />
        <PortletBody>
          {paymentsData.formData.openFilter && (
            <Filter
              fields={search_fields}
              submit={searchClickHandler}
              filters={filters}
            />
          )}

          {paymentsData.formData.msg.show && (
            <Message
              status={paymentsData.formData.msg.status}
              setMsg={(e) =>
                updateMultipleFormData([
                  { key: "msg", subKey: "show", value: false },
                  { key: "msg", subKey: "msg", value: "" },
                  { key: "msg", subKey: "status", value: 200 },
                ])
              }
              msg={paymentsData.formData.msg.msg}
            />
          )}

          {paymentsData.formData.showSubscriptions && (
            <Modal
              show={true}
              size="xl"
              onHide={() =>
                updateMultipleFormData([
                  { key: "showSubscriptions", value: false },
                ])
              }
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Payment Schedule</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {paymentsData.formData.subscriptionsLoader ? (
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <div style={{ textAlign: "center" }}>
                        <Spinner animation="border" variant="primary" />
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th nowrap="true">Reminder Date</th>
                        <th nowrap="true">Due Date</th>
                        <th nowrap="true">Amount</th>
                        <th nowrap="true">Status</th>
                        <th nowrap="true">Created By</th>
                        <th nowrap="true">Updated By</th>
                        {/* <th nowrap="true">Actions</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {paymentsData.formData.subscriptions.length ? (
                        paymentsData.formData.subscriptions.map((n, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{getDate(n.reminderDate)}</td>
                            <td>{getDate(n.dueDate)}</td>
                            <td>{formatDollar(n.paymentAmount)}</td>
                            <td>{n.status}</td>
                            <td>
                              <Link
                                to={n.created_by.url}
                                style={{ cursor: "pointer" }}
                              >
                                {n.created_by.user}
                              </Link>
                              <br />
                              <span style={{ textAlign: "right" }}>
                                At: {n.createdAt}
                              </span>
                            </td>
                            <td>
                              <Link
                                to={n.updated_by.url}
                                style={{ cursor: "pointer" }}
                              >
                                {n.updated_by.user}
                              </Link>
                              <br />
                              <span style={{ textAlign: "right" }}>
                                At: {n.updatedAt}
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={10}>
                            <h5 className="text-primary text-center">
                              No payments available, create one!
                            </h5>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() =>
                    updateMultipleFormData([
                      { key: "showSubscriptions", value: false },
                    ])
                  }
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}

          {paymentsData.formData.deletePaymentId && (
            <DialogBox
              msg={"Are you sure want to delete this recurring payment"}
              setStatus={deletePayment}
            />
          )}

          <Grid container spacing={4}>
            <Grid item md={12}>
              {paymentsData.formData.showPayments && (
                <Grid container spacing={2}>
                  <Grid item md={8}>
                    <Grid container spacing={1}>
                      <Grid item md={12}>
                        <Grid container spacing={1}>
                          <Grid item md={8}>
                            <h6 style={{ marginTop: "10px" }}>
                              Payment Frequency
                            </h6>
                            <div className="form-inline">
                              <div className="form-check form-check-inline">
                                <input
                                  type="radio"
                                  id="Onetime"
                                  name="paymentFrequency"
                                  value={
                                    paymentsData.formData.paymentFrequency ===
                                    "Onetime"
                                  }
                                  defaultChecked={
                                    paymentsData.formData.paymentFrequency ===
                                    "Onetime"
                                  }
                                  onChange={(e) =>
                                    updateMultipleFormData([
                                      {
                                        key: "paymentFrequency",
                                        value: e.target.id,
                                      },
                                    ])
                                  }
                                  className={
                                    "sel_all PS_mandatory" +
                                    (reqFields.includes("Onetime")
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Onetime"
                                >
                                  {" "}
                                  One time Payment
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  type="radio"
                                  name="paymentFrequency"
                                  id="Recurring"
                                  value={
                                    paymentsData.formData.paymentFrequency ===
                                    "Recurring"
                                  }
                                  defaultChecked={
                                    paymentsData.formData.paymentFrequency ===
                                    "Recurring"
                                  }
                                  onChange={(e) =>
                                    updateMultipleFormData([
                                      {
                                        key: "paymentFrequency",
                                        value: e.target.id,
                                      },
                                    ])
                                  }
                                  className={
                                    "sel_all PS_mandatory" +
                                    (reqFields.includes("Recurring")
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Recurring"
                                >
                                  {" "}
                                  Recurring Payment
                                </label>
                              </div>
                            </div>
                          </Grid>
                        </Grid>

                        {paymentsData.formData.paymentFrequency ===
                          "Recurring" && (
                          <Grid container spacing={1}>
                            <Grid item md={12}>
                              <Grid container spacing={1}>
                                <Grid item md={4}>
                                  <div className="form-group">
                                    <h6 style={{ marginTop: "10px" }}>
                                      Monthly Payment
                                    </h6>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          <i className="fas fa-dollar-sign"></i>
                                        </span>
                                      </div>
                                      <NumberFormat
                                        thousandSeparator={true}
                                        placeholder={"Amount"}
                                        type="text"
                                        id={"paymentAmount"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        value={
                                          paymentsData.formData.paymentAmount
                                        }
                                        onChange={(e) =>
                                          updateMultipleFormData([
                                            {
                                              key: "paymentAmount",
                                              value: parseFloat(
                                                e.target.value.replace(/,/g, "")
                                              ),
                                            },
                                          ])
                                        }
                                        className={
                                          "form-control PS_mandatory" +
                                          (reqFields.includes("paymentAmount")
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>

                              <Grid container spacing={1}>
                                <Grid item md={4}>
                                  <div className="form-group">
                                    <h6 style={{ marginTop: "10px" }}>
                                      Recurring Date Of Every Month
                                    </h6>
                                    <div className="input-group">
                                      <FormControl variant="outlined" fullWidth>
                                        <Select
                                          labelId="demo-simple-select-outlined-label"
                                          id="demo-simple-select-outlined"
                                          value={
                                            paymentsData.formData.recurringDate
                                          }
                                          onChange={(e) =>
                                            updateMultipleFormData([
                                              {
                                                key: "recurringDate",
                                                value: e.target.value,
                                              },
                                            ])
                                          }
                                          variant="outlined"
                                          className={
                                            "form-control PS_mandatory"
                                          }
                                        >
                                          <MenuItem value={"01"}>01</MenuItem>
                                          <MenuItem value={"02"}>02</MenuItem>
                                          <MenuItem value={"03"}>03</MenuItem>
                                          <MenuItem value={"04"}>04</MenuItem>
                                          <MenuItem value={"05"}>05</MenuItem>
                                          <MenuItem value={"06"}>06</MenuItem>
                                          <MenuItem value={"07"}>07</MenuItem>
                                          <MenuItem value={"08"}>08</MenuItem>
                                          <MenuItem value={"09"}>09</MenuItem>
                                          <MenuItem value={"10"}>10</MenuItem>
                                          <MenuItem value={"11"}>11</MenuItem>
                                          <MenuItem value={"12"}>12</MenuItem>
                                          <MenuItem value={"13"}>13</MenuItem>
                                          <MenuItem value={"14"}>14</MenuItem>
                                          <MenuItem value={"15"}>15</MenuItem>
                                          <MenuItem value={"16"}>16</MenuItem>
                                          <MenuItem value={"17"}>17</MenuItem>
                                          <MenuItem value={"18"}>18</MenuItem>
                                          <MenuItem value={"19"}>19</MenuItem>
                                          <MenuItem value={"20"}>20</MenuItem>
                                          <MenuItem value={"21"}>21</MenuItem>
                                          <MenuItem value={"22"}>22</MenuItem>
                                          <MenuItem value={"23"}>23</MenuItem>
                                          <MenuItem value={"24"}>24</MenuItem>
                                          <MenuItem value={"25"}>25</MenuItem>
                                          <MenuItem value={"26"}>26</MenuItem>
                                          <MenuItem value={"27"}>27</MenuItem>
                                          <MenuItem value={"28"}>28</MenuItem>
                                          <MenuItem value={"29"}>29</MenuItem>
                                          <MenuItem value={"30"}>30</MenuItem>
                                        </Select>
                                      </FormControl>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item md={4}>
                                  <div className="form-group">
                                    <h6 style={{ marginTop: "10px" }}>
                                      Start Month
                                    </h6>
                                    <div className="input-group">
                                      <DatePicker
                                        dateFormat="MM-yyyy"
                                        showMonthYearPicker
                                        selected={
                                          paymentsData.formData
                                            .subscriptionStartMonth
                                        }
                                        placeholderText="MM-YYYY"
                                        // minDate={new Date("2022/03/01")}
                                        minDate={minMonth}
                                        // customInput={<InputMask mask="99-99-9999" type="text" />}
                                        id="subscriptionStartMonth"
                                        name="subscriptionStartMonth"
                                        // value={paymentsData.formData.subscriptionStartMonth}
                                        onChange={(date) =>
                                          updateMultipleFormData([
                                            {
                                              key: "subscriptionStartMonth",
                                              value: new Date(date),
                                            },
                                            {
                                              key: "subscriptionEndMonth",
                                              value: new Date(date),
                                            },
                                          ])
                                        }
                                        className={
                                          "form-control PS_mandatory" +
                                          (reqFields.includes(
                                            "subscriptionStartMonth"
                                          )
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item md={4}>
                                  <div className="form-group">
                                    <h6 style={{ marginTop: "10px" }}>
                                      End Month
                                    </h6>
                                    <div className="input-group">
                                      <DatePicker
                                        dateFormat="MM-yyyy"
                                        showMonthYearPicker
                                        selected={
                                          paymentsData.formData
                                            .subscriptionEndMonth
                                        }
                                        placeholderText="MM-YYYY"
                                        minDate={
                                          paymentsData.formData
                                            .subscriptionStartMonth
                                        }
                                        // customInput={<InputMask mask="99-99-9999" type="text" />}
                                        id="subscriptionEndMonth"
                                        name="subscriptionEndMonth"
                                        // value={paymentsData.formData.subscriptionEndMonth}
                                        onChange={(date) =>
                                          updateMultipleFormData([
                                            {
                                              key: "subscriptionEndMonth",
                                              value: date,
                                            },
                                          ])
                                        }
                                        className={
                                          "form-control PS_mandatory" +
                                          (reqFields.includes(
                                            "subscriptionEndMonth"
                                          )
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>

                              <Grid container spacing={1}>
                                <Grid item md={4}>
                                  <div className="form-group">
                                    <h6 style={{ marginTop: "10px" }}>
                                      Remind Before How Many Days
                                    </h6>
                                    <div className="input-group">
                                      <FormControl variant="outlined" fullWidth>
                                        <Select
                                          labelId="reminderBefore"
                                          id="reminderBefore"
                                          value={
                                            paymentsData.formData.reminderBefore
                                          }
                                          onChange={(e) =>
                                            updateMultipleFormData([
                                              {
                                                key: "reminderBefore",
                                                value: e.target.value,
                                              },
                                            ])
                                          }
                                          variant="outlined"
                                          className={
                                            "form-control PS_mandatory"
                                          }
                                        >
                                          <MenuItem value={"1"}>01</MenuItem>
                                          <MenuItem value={"2"}>02</MenuItem>
                                          <MenuItem value={"3"}>03</MenuItem>
                                          <MenuItem value={"4"}>04</MenuItem>
                                          <MenuItem value={"5"}>05</MenuItem>
                                          <MenuItem value={"6"}>06</MenuItem>
                                          <MenuItem value={"7"}>07</MenuItem>
                                          <MenuItem value={"8"}>08</MenuItem>
                                          <MenuItem value={"9"}>09</MenuItem>
                                          <MenuItem value={"10"}>10</MenuItem>
                                          <MenuItem value={"11"}>11</MenuItem>
                                          <MenuItem value={"12"}>12</MenuItem>
                                          <MenuItem value={"13"}>13</MenuItem>
                                          <MenuItem value={"14"}>14</MenuItem>
                                          <MenuItem value={"15"}>15</MenuItem>
                                          <MenuItem value={"16"}>16</MenuItem>
                                          <MenuItem value={"17"}>17</MenuItem>
                                          <MenuItem value={"18"}>18</MenuItem>
                                          <MenuItem value={"19"}>19</MenuItem>
                                          <MenuItem value={"20"}>20</MenuItem>
                                          <MenuItem value={"21"}>21</MenuItem>
                                          <MenuItem value={"22"}>22</MenuItem>
                                          <MenuItem value={"23"}>23</MenuItem>
                                          <MenuItem value={"24"}>24</MenuItem>
                                          <MenuItem value={"25"}>25</MenuItem>
                                          <MenuItem value={"26"}>26</MenuItem>
                                          <MenuItem value={"27"}>27</MenuItem>
                                          <MenuItem value={"28"}>28</MenuItem>
                                          <MenuItem value={"29"}>29</MenuItem>
                                          <MenuItem value={"30"}>30</MenuItem>
                                        </Select>
                                      </FormControl>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>

                              <Grid container spacing={1}>
                                <Grid item md={4}>
                                  <div className="form-group">
                                    <h6 style={{ marginTop: "10px" }}>
                                      Late Fee Percentage
                                    </h6>
                                    <div className="input-group mb-3">
                                      <NumberFormat
                                        thousandSeparator={true}
                                        type="text"
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        id={"lateFeePercentage"}
                                        value={
                                          paymentsData.formData
                                            .lateFeePercentage
                                        }
                                        onChange={(e) =>
                                          updateMultipleFormData([
                                            {
                                              key: "lateFeePercentage",
                                              value: e.target.value,
                                            },
                                          ])
                                        }
                                        isAllowed={({ floatValue }) =>
                                          floatValue <= 100
                                        }
                                        className={
                                          "form-control PS_mandatory" +
                                          (reqFields.includes(
                                            "lateFeePercentage"
                                          )
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder={"Late Fee Percentage"}
                                      />
                                      <div className="input-group-append">
                                        <span
                                          className="input-group-text"
                                          id="basic-addon2"
                                        >
                                          %
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item md={4}>
                                  <div className="form-group">
                                    <h6 style={{ marginTop: "10px" }}>
                                      Capped At
                                    </h6>
                                    <div className="input-group mb-3">
                                      <NumberFormat
                                        thousandSeparator={true}
                                        type="text"
                                        id={"cappedAt"}
                                        value={paymentsData.formData.cappedAt}
                                        onChange={(e) =>
                                          updateMultipleFormData([
                                            {
                                              key: "cappedAt",
                                              value: parseFloat(
                                                e.target.value.replace(/,/g, "")
                                              ),
                                            },
                                          ])
                                        }
                                        className={
                                          "form-control PS_mandatory" +
                                          (reqFields.includes("cappedAt")
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder={"capped at"}
                                      />
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>

                              <Grid container spacing={1}>
                                <Grid item md={12}>
                                  <div className="form-inline">
                                    <div className="form-check form-check-inline">
                                      <input
                                        type="checkbox"
                                        id="includeExcludeProcessingFee"
                                        name="includeExcludeProcessingFee"
                                        value={
                                          paymentsData.formData
                                            .includeExcludeProcessingFee
                                        }
                                        defaultChecked={
                                          paymentsData.formData
                                            .includeExcludeProcessingFee
                                        }
                                        onChange={(e) =>
                                          updateMultipleFormData([
                                            {
                                              key: "includeExcludeProcessingFee",
                                              value:
                                                !paymentsData.formData
                                                  .includeExcludeProcessingFee,
                                            },
                                          ])
                                        }
                                        className={
                                          "sel_all PS_mandatory" +
                                          (reqFields.includes(
                                            "includeExcludeProcessingFee"
                                          )
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="includeExcludeProcessingFee"
                                      >
                                        {" "}
                                        Add processing and application fee to
                                        monthly payment
                                      </label>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}

                        {paymentsData.formData.paymentFrequency ===
                          "Onetime" && (
                          <Grid container spacing={1}>
                            <Grid item md={12}>
                              <Grid container spacing={1}>
                                <Grid item md={12}>
                                  <h6 style={{ marginTop: "10px" }}>
                                    Payment Method
                                  </h6>
                                  <div className="form-inline">
                                    <div className="form-check form-check-inline">
                                      <input
                                        type="checkbox"
                                        id="achcheck"
                                        name="ach"
                                        value={
                                          paymentsData.formData.paymentMethod
                                            .ach
                                        }
                                        defaultChecked={
                                          paymentsData.formData.paymentMethod
                                            .ach
                                        }
                                        onChange={(e) =>
                                          updateMultipleFormData([
                                            {
                                              key: "paymentMethod",
                                              subKey: e.target.name,
                                              value:
                                                !paymentsData.formData
                                                  .paymentMethod.ach,
                                            },
                                          ])
                                        }
                                        className={
                                          "sel_all PS_mandatory" +
                                          (reqFields.includes("achcheck")
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="achcheck"
                                      >
                                        {" "}
                                        ACH
                                      </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input
                                        type="checkbox"
                                        name="card"
                                        id="cardcheck"
                                        value={
                                          paymentsData.formData.paymentMethod
                                            .card
                                        }
                                        defaultChecked={
                                          paymentsData.formData.paymentMethod
                                            .card
                                        }
                                        onChange={(e) =>
                                          updateMultipleFormData([
                                            {
                                              key: "paymentMethod",
                                              subKey: e.target.name,
                                              value:
                                                !paymentsData.formData
                                                  .paymentMethod.card,
                                            },
                                          ])
                                        }
                                        className={
                                          "sel_all PS_mandatory" +
                                          (reqFields.includes("cardcheck")
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="cardcheck"
                                      >
                                        {" "}
                                        CARD
                                      </label>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid container spacing={1}>
                                <Grid item md={12}>
                                  <h6 style={{ marginTop: "10px" }}>
                                    Payment Products
                                  </h6>
                                  {paymentsData.formData.payments.map(
                                    (d, i) => (
                                      <Grid container spacing={1} key={i}>
                                        <Grid item md={5}>
                                          <div className="form-group">
                                            <div className="input-group">
                                              <input
                                                placeholder={"Item"}
                                                type="text"
                                                value={d.item}
                                                id={d._id}
                                                onChange={(e) =>
                                                  paymentsInput({
                                                    k: "payments",
                                                    sK: "item",
                                                    val: e.target.value,
                                                    i,
                                                  })
                                                }
                                                className={
                                                  "form-control PS_mandatory" +
                                                  (reqFields.includes(d._id)
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              />
                                            </div>
                                          </div>
                                        </Grid>
                                        <Grid item md={5}>
                                          <div className="form-group">
                                            <div className="input-group">
                                              <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                  <i className="fas fa-dollar-sign"></i>
                                                </span>
                                              </div>
                                              <NumberFormat
                                                thousandSeparator={true}
                                                placeholder={"Amount"}
                                                type="text"
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                id={d._id}
                                                value={d.amount}
                                                onChange={(e) =>
                                                  paymentsInput({
                                                    k: "payments",
                                                    sK: "amount",
                                                    val: e.target.value.replace(
                                                      /,/g,
                                                      ""
                                                    ),
                                                    i,
                                                  })
                                                }
                                                className={
                                                  "form-control PS_mandatory" +
                                                  (reqFields.includes(d._id)
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              />
                                            </div>
                                          </div>
                                        </Grid>
                                        <Grid item md={2}>
                                          <div className="form-group mt-2">
                                            {paymentsData.formData.payments
                                              .length -
                                              1 ===
                                              i && (
                                              <i
                                                className="fa fa-plus fa-lg text-success mr-4"
                                                onClick={addNewRow}
                                              />
                                            )}

                                            {paymentsData.formData.payments
                                              .length !== 1 && (
                                              <i
                                                className="fa fa-times fa-lg text-danger mr-4"
                                                onClick={() =>
                                                  deleteNestedForm({
                                                    d: "payments",
                                                    _id: d._id,
                                                  })
                                                }
                                              />
                                            )}
                                          </div>
                                        </Grid>
                                      </Grid>
                                    )
                                  )}

                                  <Grid container spacing={1}>
                                    <Grid item md={5}>
                                      Total
                                    </Grid>
                                    <Grid item md={5}>
                                      <div className="form-group">
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">
                                              <i className="fas fa-dollar-sign"></i>
                                            </span>
                                          </div>
                                          <NumberFormat
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            type="text"
                                            value={sumPayments(
                                              paymentsData.formData.payments
                                            )}
                                            className={"form-control"}
                                          />
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}

                        {(paymentsData.formData.paymentFrequency ===
                          "Recurring" ||
                          paymentsData.formData.paymentFrequency ===
                            "Onetime") && (
                          <Grid container spacing={1}>
                            <Grid item md={12}>
                              {paymentsData.formData.paymentId !== "" ? (
                                <button
                                  className="btn btn-success btn-sm"
                                  style={{ marginTop: "25px" }}
                                  onClick={(e) => updatePayment()}
                                >
                                  Update Payment
                                </button>
                              ) : (
                                <button
                                  className="btn btn-success btn-sm"
                                  style={{ marginTop: "25px" }}
                                  onClick={(e) => createPayment()}
                                >
                                  Create Payment
                                </button>
                              )}
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item md={4}>
                    {paymentsData.formData.paymentMethod.ach &&
                      paymentsData.formData.paymentFrequency === "Onetime" && (
                        <Grid container spacing={1}>
                          <Grid container spacing={1}>
                            <h6 style={{ marginTop: "10px", color: "#74777b" }}>
                              ACH Summary
                            </h6>
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item md={6}>
                              <Typography>
                                <i
                                  className={
                                    "fa fa-calculator fa-lg mr-2 text-primary"
                                  }
                                />
                                {"Total"}
                              </Typography>
                              <Typography>
                                <i
                                  className={
                                    "fa fa-calculator fa-lg mr-2 text-primary"
                                  }
                                />
                                {"Proceesing Fee"}
                              </Typography>
                              <Typography>
                                <i
                                  className={
                                    "fa fa-calculator fa-lg mr-2 text-primary"
                                  }
                                />
                                {"Application Fee"}
                              </Typography>
                              <Typography>
                                <i
                                  className={
                                    "fa fa-calculator fa-lg mr-2 text-primary"
                                  }
                                />
                                {"Net Payable"}
                              </Typography>
                            </Grid>
                            <Grid item md={6}>
                              <Typography>
                                {isNaN(
                                  sumPayments(paymentsData.formData.payments)
                                )
                                  ? formatDollar("0")
                                  : formatDollar(
                                      sumPayments(
                                        paymentsData.formData.payments
                                      )
                                    )}
                              </Typography>
                              <Typography>
                                {isNaN(
                                  achcalculateProcessingFee(
                                    sumPayments(paymentsData.formData.payments)
                                  )
                                )
                                  ? formatDollar("0")
                                  : formatDollar(
                                      achcalculateProcessingFee(
                                        sumPayments(
                                          paymentsData.formData.payments
                                        )
                                      )
                                    )}
                              </Typography>
                              <Typography>
                                {formatDollar(
                                  paymentsData.formData.applicationFeeAmt
                                )}
                              </Typography>
                              <Typography>
                                {isNaN(
                                  achcalculateNetFee(
                                    sumPayments(paymentsData.formData.payments)
                                  )
                                )
                                  ? formatDollar("0")
                                  : formatDollar(
                                      achcalculateNetFee(
                                        sumPayments(
                                          paymentsData.formData.payments
                                        )
                                      )
                                    )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}

                    {paymentsData.formData.paymentMethod.card &&
                      paymentsData.formData.paymentFrequency === "Onetime" && (
                        <Grid container spacing={1}>
                          <Grid container spacing={1}>
                            <h6 style={{ marginTop: "10px", color: "#74777b" }}>
                              Card Summary
                            </h6>
                          </Grid>
                          <Grid item md={6}>
                            <Typography>
                              <i
                                className={
                                  "fa fa-calculator fa-lg mr-2 text-primary"
                                }
                              />
                              {"Total"}
                            </Typography>
                            <Typography>
                              <i
                                className={
                                  "fa fa-calculator fa-lg mr-2 text-primary"
                                }
                              />
                              {"Proceesing Fee"}
                            </Typography>
                            <Typography>
                              <i
                                className={
                                  "fa fa-calculator fa-lg mr-2 text-primary"
                                }
                              />
                              {"Application Fee"}
                            </Typography>
                            <Typography>
                              <i
                                className={
                                  "fa fa-calculator fa-lg mr-2 text-primary"
                                }
                              />
                              {"Net Payable"}
                            </Typography>
                          </Grid>
                          <Grid item md={6}>
                            <Typography>
                              {isNaN(
                                sumPayments(paymentsData.formData.payments)
                              )
                                ? formatDollar("0")
                                : formatDollar(
                                    sumPayments(paymentsData.formData.payments)
                                  )}
                            </Typography>
                            <Typography>
                              {isNaN(
                                calculateProcessingFee(
                                  sumPayments(paymentsData.formData.payments)
                                )
                              )
                                ? formatDollar("0")
                                : formatDollar(
                                    calculateProcessingFee(
                                      sumPayments(
                                        paymentsData.formData.payments
                                      )
                                    )
                                  )}
                            </Typography>
                            <Typography>
                              {formatDollar(
                                paymentsData.formData.applicationFeeAmt
                              )}
                            </Typography>
                            <Typography>
                              {isNaN(
                                calculateNetFee(
                                  sumPayments(paymentsData.formData.payments)
                                )
                              )
                                ? formatDollar("0")
                                : formatDollar(
                                    calculateNetFee(
                                      sumPayments(
                                        paymentsData.formData.payments
                                      )
                                    )
                                  )}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}

                    {paymentsData.formData.includeExcludeProcessingFee &&
                      paymentsData.formData.paymentFrequency ===
                        "Recurring" && (
                        <Grid container spacing={1}>
                          <Grid container spacing={1}>
                            <h6 style={{ marginTop: "10px", color: "#74777b" }}>
                              ACH Summary
                            </h6>
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item md={6}>
                              <Typography>
                                <i
                                  className={
                                    "fa fa-calculator fa-lg mr-2 text-primary"
                                  }
                                />
                                {"Total"}
                              </Typography>
                              <Typography>
                                <i
                                  className={
                                    "fa fa-calculator fa-lg mr-2 text-primary"
                                  }
                                />
                                {"Proceesing Fee"}
                              </Typography>
                              <Typography>
                                <i
                                  className={
                                    "fa fa-calculator fa-lg mr-2 text-primary"
                                  }
                                />
                                {"Application Fee"}
                              </Typography>
                              <Typography>
                                <i
                                  className={
                                    "fa fa-calculator fa-lg mr-2 text-primary"
                                  }
                                />
                                {"Net Payable"}
                              </Typography>
                            </Grid>
                            <Grid item md={6}>
                              <Typography>
                                {isNaN(paymentsData.formData.paymentAmount)
                                  ? formatDollar("0")
                                  : formatDollar(
                                      paymentsData.formData.paymentAmount
                                    )}
                              </Typography>
                              <Typography>
                                {isNaN(
                                  achcalculateProcessingFee(
                                    paymentsData.formData.paymentAmount
                                  )
                                )
                                  ? formatDollar("0")
                                  : formatDollar(
                                      achcalculateProcessingFee(
                                        paymentsData.formData.paymentAmount
                                      )
                                    )}
                              </Typography>
                              <Typography>
                                {formatDollar(
                                  paymentsData.formData.applicationFeeAmt
                                )}
                              </Typography>
                              <Typography>
                                {isNaN(
                                  achcalculateNetFee(
                                    paymentsData.formData.paymentAmount,
                                    "add"
                                  )
                                )
                                  ? formatDollar("0")
                                  : formatDollar(
                                      achcalculateNetFee(
                                        paymentsData.formData.paymentAmount,
                                        "add"
                                      )
                                    )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    {paymentsData.formData.lateFeePercentage > 0 &&
                      paymentsData.formData.paymentFrequency ===
                        "Recurring" && (
                        <Grid container spacing={1}>
                          <Grid container spacing={1}>
                            <h6 style={{ marginTop: "10px", color: "#74777b" }}>
                              Late Fee Summary
                            </h6>
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item md={6}>
                              <Typography>
                                <i
                                  className={
                                    "fa fa-calculator fa-lg mr-2 text-primary"
                                  }
                                />
                                {"Total"}
                              </Typography>
                              <Typography>
                                <i
                                  className={
                                    "fa fa-calculator fa-lg mr-2 text-primary"
                                  }
                                />
                                {"Late Fee Percentage"}
                              </Typography>
                              <Typography>
                                <i
                                  className={
                                    "fa fa-calculator fa-lg mr-2 text-primary"
                                  }
                                />
                                {"Late Fee Amount"}
                              </Typography>
                            </Grid>
                            <Grid item md={6}>
                              <Typography>
                                {isNaN(paymentsData.formData.paymentAmount)
                                  ? formatDollar("0")
                                  : formatDollar(
                                      paymentsData.formData.paymentAmount
                                    )}
                              </Typography>
                              <Typography>
                                {isNaN(paymentsData.formData.lateFeePercentage)
                                  ? "0%"
                                  : `${paymentsData.formData.lateFeePercentage}%`}
                              </Typography>
                              <Typography>
                                {isNaN(
                                  achcalculateLateFee(
                                    paymentsData.formData.paymentAmount
                                  )
                                )
                                  ? formatDollar("0")
                                  : achcalculateLateFee(
                                      paymentsData.formData.paymentAmount
                                    )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                  </Grid>
                </Grid>
              )}

              <Grid container spacing={2}>
                <Grid item md={12}>
                  {
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Payment Requests</Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ overflowX: "scroll" }}>
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th nowrap="true">Loan Number</th>
                              <th nowrap="true">Employee</th>
                              <th nowrap="true">Amount</th>
                              <th nowrap="true">Payment Requested</th>
                              <th nowrap="true">Payment Processed</th>
                              <th nowrap="true">Status</th>
                              <th nowrap="true">Processing Fee</th>
                              <th nowrap="true">Application Fee</th>
                              <th nowrap="true">Net Paid</th>
                              <th nowrap="true">Created By</th>
                              <th nowrap="true">Updated By</th>
                              {/* <th nowrap="true">Actions</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {getPaymentsOneTimeRecurring(
                              paymentsData.formData.data.payments
                            ).Onetime.length ? (
                              getPaymentsOneTimeRecurring(
                                paymentsData.formData.data.payments
                              ).Onetime.map((n, i) => (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>
                                    <JumbLink
                                      position={0}
                                      url={`/opportunities/edit/${
                                        n.owner._id && n.owner._id
                                      }?tab=payments`}
                                    >
                                      {n.owner.info.auto_increment
                                        ? n.owner.info.auto_increment
                                        : ""}
                                    </JumbLink>
                                  </td>
                                  <td>
                                    {n.prospect &&
                                      n.prospect.info &&
                                      (!!n.prospect.info.first_name
                                        ? n.prospect.info.first_name
                                        : "") +
                                        " " +
                                        (!!n.prospect.info.last_name
                                          ? n.prospect.info.last_name
                                          : "")}
                                  </td>
                                  <td>
                                    {formatDollar(sumPayments(n.payments))}
                                  </td>
                                  <td>{`${n?.paymentMethod?.ach ? "ACH" : ""} ${
                                    n?.paymentMethod?.ach &&
                                    n?.paymentMethod?.card
                                      ? ","
                                      : ""
                                  } ${
                                    n?.paymentMethod?.card ? "CARD" : ""
                                  }`}</td>
                                  <td>
                                    {n?.paymentMethod?.processed
                                      ? n?.paymentMethod?.processed
                                      : ""}
                                  </td>
                                  <td>{getStatus(n)}</td>
                                  <td>
                                    {n?.paymentIntent?.status === "succeeded"
                                      ? formatDollar(
                                          calculateProcessingFee(
                                            sumPayments(n.payments)
                                          )
                                        )
                                      : n?.charge?.status === "succeeded" &&
                                        formatDollar(
                                          achcalculateProcessingFee(
                                            sumPayments(n.payments)
                                          )
                                        )}
                                  </td>
                                  <td>
                                    {n?.paymentIntent?.status === "succeeded"
                                      ? formatDollar(
                                          paymentsData.formData
                                            .applicationFeeAmt
                                        )
                                      : n?.charge?.status === "succeeded" &&
                                        formatDollar(
                                          paymentsData.formData
                                            .applicationFeeAmt
                                        )}
                                  </td>
                                  <td>
                                    {n?.paymentIntent?.status === "succeeded"
                                      ? formatDollar(
                                          calculateNetFee(
                                            sumPayments(n.payments)
                                          )
                                        )
                                      : n?.charge?.status === "succeeded" &&
                                        formatDollar(
                                          achcalculateNetFee(
                                            sumPayments(n.payments)
                                          )
                                        )}
                                  </td>
                                  <td>
                                    <Link
                                      to={n.created_by.url}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {n.created_by.user}
                                    </Link>
                                    <br />
                                    <span style={{ textAlign: "right" }}>
                                      At: {n.createdAt}
                                    </span>
                                  </td>
                                  <td>
                                    <Link
                                      to={n.updated_by.url}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {n.updated_by.user}
                                    </Link>
                                    <br />
                                    <span style={{ textAlign: "right" }}>
                                      At: {n.updatedAt}
                                    </span>
                                  </td>
                                  {/* <td>
                              <i
                                className="fa fa-edit fa-lg text-primary mr-2"
                                title="Edit the payment."
                                onClick={(e) => editPaymentRequest(n)}
                              />
                            </td> */}
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={10}>
                                  <h5 className="text-primary text-center">
                                    No payments available, create one!
                                  </h5>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </AccordionDetails>
                    </Accordion>
                  }
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item md={12}>
                  {
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Recurring Payments</Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ overflowX: "scroll" }}>
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th nowrap="true">Loan Number</th>
                              <th nowrap="true">Employee</th>
                              <th nowrap="true">Amount</th>
                              <th nowrap="true">Status</th>
                              <th nowrap="true">Start Month</th>
                              <th nowrap="true">End Month</th>
                              <th nowrap="true">Recurring Date</th>
                              <th nowrap="true">Convenience Fee</th>
                              <th nowrap="true">Created By</th>
                              <th nowrap="true">Updated By</th>
                              <th nowrap="true">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {getPaymentsOneTimeRecurring(
                              paymentsData.formData.data.payments
                            ).Recurring.length ? (
                              getPaymentsOneTimeRecurring(
                                paymentsData.formData.data.payments
                              ).Recurring.map((n, i) => (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>
                                    <JumbLink
                                      position={0}
                                      url={`/opportunities/edit/${
                                        n.owner._id && n.owner._id
                                      }?tab=payments`}
                                    >
                                      {n.owner.info.auto_increment
                                        ? n.owner.info.auto_increment
                                        : ""}
                                    </JumbLink>
                                  </td>
                                  <td>
                                    {n.prospect &&
                                      n.prospect.info &&
                                      (!!n.prospect.info.first_name
                                        ? n.prospect.info.first_name
                                        : "") +
                                        " " +
                                        (!!n.prospect.info.last_name
                                          ? n.prospect.info.last_name
                                          : "")}
                                  </td>
                                  <td>{formatDollar(n?.paymentAmount)}</td>
                                  <td>{getStatus(n)}</td>
                                  <td>{n.subscriptionStartMonth}</td>
                                  <td>{n.subscriptionEndMonth}</td>
                                  <td>{n.recurringDate}</td>
                                  <td>{n.convenienceFee}</td>
                                  <td>
                                    <Link
                                      to={n.created_by.url}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {n.created_by.user}
                                    </Link>
                                    <br />
                                    <span style={{ textAlign: "right" }}>
                                      At: {n.createdAt}
                                    </span>
                                  </td>
                                  <td>
                                    <Link
                                      to={n.updated_by.url}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {n.updated_by.user}
                                    </Link>
                                    <br />
                                    <span style={{ textAlign: "right" }}>
                                      At: {n.updatedAt}
                                    </span>
                                  </td>
                                  <td>
                                    <i
                                      className="fa fa-edit fa-lg text-primary mr-2"
                                      title="Edit the payment."
                                      onClick={(e) => editPaymentRequest(n)}
                                    />
                                    <i
                                      className="fa fa-trash fa-lg text-primary mr-2"
                                      title="Delete the payment."
                                      onClick={(e) =>
                                        deletePayment(false, n._id)
                                      }
                                    />
                                    <span
                                      className="fa fa-eye fa-sm text-primary mr-2"
                                      onClick={(e) => viewSubscriptions(n)}
                                    >
                                      View Schedule
                                    </span>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={10}>
                                  <h5 className="text-primary text-center">
                                    No payments available, create one!
                                  </h5>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </AccordionDetails>
                    </Accordion>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </PortletBody>
      </Portlet>
    )
  ) : paymentsData.formData.loader ? (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <div style={{ textAlign: "center" }}>
          <Spinner animation="border" variant="primary" />
        </div>
      </Grid>
    </Grid>
  ) : (
    <Portlet>
      <PortletHeader title="Payments" />
      <PortletBody>
        <Grid container spacing={1}>
          {paymentsData.formData.msg.show && (
            <Message
              status={paymentsData.formData.msg.status}
              setMsg={(e) =>
                updateMultipleFormData([
                  { key: "msg", subKey: "show", value: false },
                  { key: "msg", subKey: "msg", value: "" },
                  { key: "msg", subKey: "status", value: 200 },
                ])
              }
              msg={paymentsData.formData.msg.msg}
            />
          )}

          {paymentsData.formData.showPaymentMethod && (
            <Modal
              show={true}
              size="xl"
              onHide={() =>
                updateMultipleFormData([
                  { key: "showPaymentMethod", value: false },
                ])
              }
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Select Payment Method</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Tabs
                  // activeKey={this.state.key}
                  // onSelect={key => this.setState({ key })}
                  fill
                >
                  {paymentsData.formData.selectedPayments.paymentMethod.ach && (
                    <Tab eventKey="controls" title="ACH (Recommended)">
                      <Ach
                        formatDollar={formatDollar}
                        props={props}
                        sumPayments={sumPayments}
                        loadPayments={loadPayments}
                        paymentsData={paymentsData}
                        updateMultipleFormData={updateMultipleFormData}
                      />
                    </Tab>
                  )}

                  {paymentsData.formData.selectedPayments.paymentMethod
                    .card && (
                    <Tab eventKey="advanced" title="Card">
                      <Elements stripe={stripePromise}>
                        <Card
                          formatDollar={formatDollar}
                          sumPayments={sumPayments}
                          props={props}
                          loadPayments={loadPayments}
                          paymentsData={paymentsData}
                          client_secret={paymentsData.formData.client_secret}
                          updateMultipleFormData={updateMultipleFormData}
                        />
                      </Elements>
                    </Tab>
                  )}
                </Tabs>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() =>
                    updateMultipleFormData([
                      { key: "showPaymentMethod", value: false },
                    ])
                  }
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}

          {
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th nowrap="true">Amount</th>
                  <th nowrap="true">Status</th>
                  <th nowrap="true">Created By</th>
                  <th nowrap="true">Updated By</th>
                  <th nowrap="true">Actions</th>
                </tr>
              </thead>
              <tbody>
                {getPaymentsOneTimeRecurring(
                  paymentsData.formData.data.payments
                ).Onetime.length ? (
                  getPaymentsOneTimeRecurring(
                    paymentsData.formData.data.payments
                  ).Onetime.map((n, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{formatDollar(sumPayments(n.payments))}</td>
                      <td>{getStatus(n)}</td>
                      <td>
                        <Link
                          to={n.created_by.url}
                          style={{ cursor: "pointer" }}
                        >
                          {n.created_by.user}
                        </Link>
                        <br />
                        <span style={{ textAlign: "right" }}>
                          At: {n.createdAt}
                        </span>
                      </td>
                      <td>
                        <Link
                          to={n.updated_by.url}
                          style={{ cursor: "pointer" }}
                        >
                          {n.updated_by.user}
                        </Link>
                        <br />
                        <span style={{ textAlign: "right" }}>
                          At: {n.updatedAt}
                        </span>
                      </td>
                      <td>{getButtonStatus(n)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10}>
                      <h5 className="text-primary text-center">
                        No payments available to show.
                      </h5>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          }
        </Grid>
      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(Payments);
