import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import { Spinner } from "react-bootstrap";

import Message from "../../utils/Message";
import Notes from "../../services/notes";
import { checkValidation } from "../../utils/FormHandling";
import { backendApiUrl, getDate } from "../../utils";

const Form = (props) => {
  const [reqFields, setReqFields] = useState([]);

  const [state, setState] = useState({
    load: true,
    data: {},
    msg: {},
    users: [],
    taskId: "",
  });
  console.log(reqFields);
  const onChangeHandler = (e, field) => {
    let st = { ...state };
    if (!!field) {
      st.data[field] = e;
    } else {
      st.data[e.target.name] = e.target.value;
    }
    setState(st);
  };

  const setMsg = (msg) => {
    let st = { ...state };
    st.msg = msg;
    setState(st);
  };

  const onSubmitHandler = async () => {
    let check = checkValidation("tmandatory");
    if (check.length > 0) {
      setReqFields(check);
      return true;
    }

    let data = { ...state.data, module: props.module, owner: props.owner };

    let users = [];
    data.users &&
      data.users.forEach((u) => {
        users = [...users, { value: u.value, role: u.role, type: u.type }];
      });
    await axios
      .post(
        backendApiUrl("tasks/create"),
        { ...data, users },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        let st = { ...state };
        st.taskId = res.data._id;
        st.msg = {
          status: 200,
          msg: "Task created successfully.",
          show: true,
        };
        setState(st);
        props.events.setLoading(true);
      })
      .catch((err) => {
        let m = "Something went wrong, unable to create task.";
        if (err.response) m = err.response.data.message;
        let msg = {
          status: 500,
          msg: m,
          show: true,
        };
        setMsg(msg);
      });
  };

  const onUpdateHandler = async () => {
    let check = checkValidation("tmandatory");
    if (check.length > 0) {
      setReqFields(check);
      return true;
    }

    let data = { ...state.data };
    let users = [];
    data.users &&
      data.users.forEach((u) => {
        users = [...users, { value: u.value, role: u.role, type: u.type }];
      });

    await axios
      .patch(
        backendApiUrl("tasks/") + state.taskId,
        { ...data, users },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setMsg({
          status: 200,
          msg: "Task Updated successfully.",
          show: true,
        });
        props.events.setLoading(true);
      })
      .catch((err) => {
        let m = "Something went wrong, unable to update task.";
        if (err.response) m = err.response.data.message;
        let msg = {
          status: 500,
          msg: m,
          show: true,
        };
        setMsg(msg);
      });
  };

  useEffect(() => {
    if (state.load) {
      axios
        .post(
          backendApiUrl("tasks/info"),
          { tid: !!state.taskId ? state.taskId : props.data._id },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let st = { ...state };
          st.load = false;
          st.users = res.data.users;
          if (!!props.data._id) {
            st.data = {
              status: props.data.status,
              priority: props.data.priority,
              users: res.data.task.users,
              title: props.data.title,
              start_date: getDate(props.data.start_date, "Y"),
              due_date: getDate(props.data.due_date, "Y"),
              description: props.data.description,
            };
            st.taskId = props.data._id;
          }
          setState(st);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [state, props.data]);

  const onCloseClickHandler = () => {
    props.events.setTask({});
  };

  return (
    <Modal
      show={true}
      size="xl"
      onHide={onCloseClickHandler}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <b>{state.taskId ? "Update " : "Create "} Task</b>
      </Modal.Header>
      <Modal.Body>
        {state.load ? (
          <div style={{ textAlign: "center" }}>
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <>
            {state.msg.show && (
              <Message
                status={state.msg.status}
                setMsg={setMsg}
                msg={state.msg.msg}
              />
            )}
            <Grid container spacing={2}>
              <Grid item xs={state.taskId ? 6 : 12}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="title">
                        Title <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="title"
                        id="title"
                        value={state.data.title ? state.data.title : ""}
                        className={
                          "form-control tmandatory" +
                          (reqFields.includes("title") ? " is-invalid" : "")
                        }
                        onChange={onChangeHandler}
                      />
                      {reqFields.includes("title") && (
                        <div className="invalid-feedback">
                          Please provide a valid title
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="status">
                        Status <span className="text-danger">*</span>
                      </label>
                      <select
                        name="status"
                        id="status"
                        value={state.data.status ? state.data.status : ""}
                        className={
                          "form-control tmandatory" +
                          (reqFields.includes("status") ? " is-invalid" : "")
                        }
                        onChange={onChangeHandler}
                      >
                        <option disabled value="">
                          Select status
                        </option>
                        {[
                          "Not Started",
                          "In Progress",
                          "Awaiting Feedback",
                          "On Hold",
                          "Completed",
                        ].map((s, i) => (
                          <option key={i} value={s}>
                            {s}
                          </option>
                        ))}
                      </select>
                      {reqFields.includes("status") && (
                        <div className="invalid-feedback">
                          Please provide a valid status
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="priority">
                        Priority <span className="text-danger">*</span>
                      </label>
                      <select
                        name="priority"
                        id="priority"
                        value={state.data.priority ? state.data.priority : ""}
                        className={
                          "form-control tmandatory" +
                          (reqFields.includes("priority") ? " is-invalid" : "")
                        }
                        onChange={onChangeHandler}
                      >
                        <option disabled value="">
                          Select priority
                        </option>
                        {["Low", "Medium", "High", "Urgent"].map((s, i) => (
                          <option key={i} value={s}>
                            {s}
                          </option>
                        ))}
                      </select>

                      {reqFields.includes("priority") && (
                        <div className="invalid-feedback">
                          Please provide a valid priority
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="start_date">
                        Start Date
                      </label>
                      <input
                        type="date"
                        name="start_date"
                        className="form-control"
                        value={
                          !!state.data.start_date ? state.data.start_date : ""
                        }
                        onChange={onChangeHandler}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="due_date">
                        Due Date
                      </label>
                      <input
                        type="date"
                        name="due_date"
                        className="form-control"
                        value={!!state.data.due_date ? state.data.due_date : ""}
                        onChange={onChangeHandler}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="users">
                        Assigned Users
                      </label>
                      <Select
                        className="kt-width-full"
                        name="users"
                        options={state.users}
                        value={state.data.users ? state.data.users : ""}
                        isMulti
                        placeholder="Please select users"
                        onChange={(e) => onChangeHandler(e, "users")}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="description">
                        Description
                      </label>
                      <textarea
                        name="description"
                        className="form-control"
                        value={
                          !!state.data.description ? state.data.description : ""
                        }
                        onChange={onChangeHandler}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              {state.taskId && (
                <Grid item xs={6}>
                  <Notes
                    module="Tasks"
                    permissions={{ notes: { add: true, update: true } }}
                    owner={state.taskId}
                  />
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-sm btn-primary"
          onClick={onCloseClickHandler}
        >
          Close
        </button>
        {!!state.taskId ? (
          <button className="btn btn-sm btn-primary" onClick={onUpdateHandler}>
            Update
          </button>
        ) : (
          <button className="btn btn-sm btn-primary" onClick={onSubmitHandler}>
            Create
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default Form;
