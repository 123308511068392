import React from "react";
import FormGroup from "./utils/FormGroup";

const TimePicker = (props) => {
  return (
    <FormGroup element={props.element} module={props.module}>
      {props.module.permissions.update ? (
        <input
          placeholder={props.element.placeholder}
          type="time"
          id={props.element.id}
          onChange={props.events.change}
          disabled={props.element.readonly ? true : false}
          value={props.element.value}
          className={"form-control " + props.element.class}
        />
      ) : (
        <h6 className="text-primary">{props.element.value}</h6>
      )}
    </FormGroup>
  );
};

export default TimePicker;
