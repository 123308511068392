import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Menu = (props) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  let data = { ...props.data };

  return (
    <Accordion
      expanded={expanded === data.title}
      onChange={handleChange(data.title)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-content"
        id="panel-header"
      >
        <Typography>
          <i
            className={
              "fa " +
              (data.title === "Builders" ? "fa-building" : "fa-wrench") +
              " fa-lg mr-2 text-primary"
            }
          />
          {data.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ width: "100%" }}>
          {data.submenu &&
            data.submenu.length > 0 &&
            data.submenu.map((d, i) => (
              <div className="col-12" key={i}>
                <Link to={"/" + d.page}>
                  <Typography>{d.title}</Typography>
                </Link>
                <hr />
              </div>
            ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default Menu;
