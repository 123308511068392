import React, { useState, useEffect } from "react";
import axios from "axios";
import { ButtonToolbar, ButtonGroup, Button } from "react-bootstrap";
import DialogBox from "./DialogBox";
import { backendApiUrl } from "../utils";

const MassActions = (props) => {
  const [state, setState] = useState({
    confirmBox: false,
    msg: {},
    from: "",
    approved: false,
  });

  const statusClickHandler = (status) => {
    let st = { ...state };
    st.approved = status;
    st.confirmBox = false;
    setState(st);
  };

  const onClickHandler = (from) => {
    let st = { ...state };
    st.from = from;
    st.confirmBox = true;
    st.timesheetInfo = props?.timesheetInfo;
    if (from === "Delete Selected") {
      st.msg = "Are you sure want to deactivate selected items?";
    } else if (from === "Delete All") {
      st.msg = "Are you sure want to deactivate all items?";
    } else {
      st.confirmBox = false;
      let info = { ...props.info };
      info.openCompose = true;
      if (props.module === "Leads") {
        info.url = backendApiUrl("leads/mass_action");
      } else if (props.module === "TimeSheet") {
        info.url = backendApiUrl("timesheet/mass_action");
      } else {
        info.url = backendApiUrl("module/mass_action");
      }
      info.from = from;
      props.actions.setActionInfo(info);
    }
    setState(st);
  };
  useEffect(() => {
    if (
      (state.approved && !!state.from) ||
      (props.module === "TimeSheet" && !!state.from)
    ) {
      let st = { ...state };

      let data = {
        from: st.from,
        module: props.module,
        Ids: props.info.Ids,
      };
      if (props.module === "TimeSheet" && props.timesheetInfo) {
        if (props.timesheetInfo.startDate)
          data.sd = props.timesheetInfo.startDate;
        if (props.timesheetInfo.endDate) data.ed = props.timesheetInfo.endDate;
      }
      if (props.filters) data.filters = props.filters;
      axios
        .post(props.url, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          let tmsg = "";
          if (st.from === "Delete Selected") {
            tmsg = "Selected items deleted successfully.";
          } else if (st.from === "Delete All") {
            tmsg = "All items deleted successfully.";
          } else if (st.from === "Send Remainder To Selected") {
            tmsg = "Sent Remainder To Selected successfully.";
          } else if (st.from === "Send Remainder To All") {
            tmsg = "Sent Remainder To All successfully.";
          }

          props.actions.setLoading(true);
          props.actions.setActionInfo({});

          st.confirmBox = false;
          st.from = "";
          setState(st);

          props.actions.setMsg({
            status: 200,
            msg: tmsg,
            show: true,
          });
        })
        .catch((err) => {
          st.confirmBox = false;
          st.from = "";
          setState(st);
          props.actions.setMsg({
            status: 500,
            msg: "Unable to process the request.",
            show: true,
          });
        });
    }
  }, [
    props.actions,
    props.filters,
    props.info.Ids,
    props.module,
    props.title,
    props.url,
    state,
    props,
  ]);

  return (
    <span>
      {state.confirmBox && (
        <DialogBox msg={state.msg} setStatus={statusClickHandler} />
      )}
      <ButtonToolbar aria-label="Toolbar with button groups">
        {props.module !== "TimeSheet" ? (
          <>
            {" "}
            <ButtonGroup className="mr-2">
              <Button
                size="sm"
                onClick={() => onClickHandler("Delete Selected")}
              >
                Delete Selected
              </Button>
            </ButtonGroup>
            <ButtonGroup className="mr-2">
              <Button size="sm" onClick={() => onClickHandler("Delete All")}>
                Delete All
              </Button>
            </ButtonGroup>
            <ButtonGroup className="mr-2">
              <Button
                size="sm"
                onClick={() => onClickHandler("Send Mail To Selected")}
              >
                Send Mail To Selected
              </Button>
            </ButtonGroup>
            <ButtonGroup>
              <Button
                size="sm"
                onClick={() => onClickHandler("Send Mail To All")}
              >
                Send Mail To All
              </Button>
            </ButtonGroup>
          </>
        ) : (
          <>
            <ButtonGroup className="mr-2">
              <Button
                size="sm"
                onClick={() => onClickHandler("Send Remainder To Selected")}
              >
                Send Remainder To Selected
              </Button>
            </ButtonGroup>
            <ButtonGroup>
              <Button
                size="sm"
                onClick={() => onClickHandler("Send Remainder To All")}
              >
                Send Remainder To All
              </Button>
            </ButtonGroup>
          </>
        )}
      </ButtonToolbar>
    </span>
  );
};

export default MassActions;
