import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { FaTrash } from "react-icons/fa";

function Notes(props) {
  const [note, setNote] = useState("");

  const saveClickHandler = () => {
    props.events.onNotesChange(note, props.week);
    setNote("");
  };

  return (
    <Modal show={true} size="lg" backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Notes</Modal.Title>
        <div>
          <button
            className="btn btn-sm btn-default"
            onClick={() => props.events.onClose("")}
          >
            Close
          </button>
        </div>
      </Modal.Header>

      <Modal.Body style={{ maxHeight: "600px", overflow: "auto" }}>
        <div className="row">
          <div className="col-md-8">
            <textarea
              name="note"
              className="form-control"
              onChange={(e) => setNote(e.target.value)}
              value={note}
            />
          </div>
          <div className="col-md-4">
            <button className="btn btn-primary" onClick={saveClickHandler}>
              Save
            </button>
          </div>
        </div>

        <table className="table table-hover">
          <thead>
            <tr>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            {props.state.data[props.week] &&
              props.state.data[props.week].notes &&
              props.state.data[props.week].notes.map((n, i) => (
                <tr key={i}>
                  <td>
                    {n}
                    <br />
                    <span>
                      <b>By: </b>
                      {props.user.fullName +
                        "(" +
                        (props.user.type === "Prospects"
                          ? "Employee"
                          : props.user.type) +
                        ")"}
                    </span>
                    <span style={{ float: "right" }}>
                      <FaTrash
                        size="25"
                        className="text-danger"
                        onClick={() => props.events.onDelete(i, props.week)}
                      />
                    </span>
                  </td>
                </tr>
              ))}
            {props.state.notes &&
              props.state.notes[props.week] &&
              props.state.notes[props.week].map((n, i) => (
                <tr key={i}>
                  <td>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: n.note ? n.note : n,
                      }}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(Notes);
