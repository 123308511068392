import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";

import { backendApiUrl } from "../../utils";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../partials/content/Portlet";
import Files from "./Files";

const MergeFiles = (props) => {
  const [state, setState] = useState({
    load: true,
    form: props.module.form._id,
    owner: props.module.MDID,
    files: [],
  });

  const [showFiles, setShowFiles] = useState(false);

  useEffect(() => {
    if (state.load) {
      axios
        .post(
          backendApiUrl("merge"),
          {
            form: state.form,
            owner: state.owner,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let st = { ...state, ...res.data };
          st.load = false;
          setState(st);
        })
        .catch((err) => {
          console.log(err);
          let st = { ...state };
          st.load = false;
          setState(st);
        });
    }
  }, [state]);

  return (
    <div className="mt-3">
      <Portlet>
        <PortletHeader
          title="Merge Documents"
          toolbar={
            <PortletHeaderToolbar>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => setShowFiles(true)}
              >
                Merge File
              </button>
            </PortletHeaderToolbar>
          }
        />
        <PortletBody>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>title</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {state.load ? (
                  <tr>
                    <td colSpan="3">
                      <div style={{ textAlign: "center" }}>
                        <Spinner animation="border" variant="primary" />
                      </div>
                    </td>
                  </tr>
                ) : (
                  state.files.map((f, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{f.title}</td>
                      <td className="fa">
                        <Link
                          to={"/merge_docs/view/" + f._id}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="fa fa-eye fa-lg mr-2"
                        />
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>
                              Uploaded by: <strong>{f.created_by.user}</strong>
                              <br />
                              Uploaded Date & Time:{" "}
                              <strong>{f.createdAt}</strong>
                              <br />
                              Last Updated by:{" "}
                              <strong>{f.updated_by.user}</strong>
                              <br />
                              Last Updated Date & Time:{" "}
                              <strong>
                                {!!f.updated_by.user ? f.updatedAt : ""}
                              </strong>
                            </Tooltip>
                          }
                        >
                          <i className="fa fa-info-circle fa-lg text-primary mr-2" />
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </PortletBody>
      </Portlet>
      {showFiles && (
        <Files
          module={props.module}
          state={state}
          setState={setState}
          onShow={setShowFiles}
        />
      )}
    </div>
  );
};

export default MergeFiles;
