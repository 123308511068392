import React from "react";
import { shallowEqual, useSelector } from "react-redux";

const Logo = () => {
  let { menuConfig } = useSelector(
    ({ builder }) => ({
      menuConfig: builder.menuConfig,
    }),
    shallowEqual
  );

  return (
    <div className="kt-header-logo">
      {menuConfig.logo ? (
        <img
          alt="Company Logo"
          width="150"
          height="40"
          src={`data:image/png;base64,${menuConfig.logo}`}
        />
      ) : (
        <h2>
          <span style={{ color: "#fff" }}>Immi</span>
          <span style={{ color: "#34ceed" }}>CRM</span>
        </h2>
      )}
    </div>
  );
};

export default Logo;
