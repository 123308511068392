import React from "react";
import FormGroup from "./utils/FormGroup";
import { Form } from "react-bootstrap";

const CheckBox = (props) => {
  return (
    <FormGroup element={props.element} module={props.module}>
      {props.module.permissions.update ? (
        <Form>
          {props.element.options ? (
            props.element.options.map((option) => {
              return (
                <Form.Check
                  custom
                  inline
                  label={option.text}
                  value={option.id}
                  key={option.id}
                  onChange={props.events.change}
                  id={option.id}
                  disabled={props.element.readonly ? true : false}
                  className={props.element.id + props.element.class}
                  checked={
                    Array.isArray(props.element.value) &&
                    props.element.value.includes(option.id)
                      ? true
                      : false
                  }
                  type="checkbox"
                  name={props.element.id}
                />
              );
            })
          ) : (
            <>
              <Form.Check
                custom
                inline
                label="Option 1"
                type="checkbox"
                id={props.element.id}
              />
            </>
          )}
        </Form>
      ) : (
        <h6 className="text-primary">{props.element.value}</h6>
      )}
    </FormGroup>
  );
};

export default CheckBox;
