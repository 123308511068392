import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

const Footer = (props) => {
  const [buttons, setButtons] = useState([]);
  const [load, setLoad] = useState(true);
  const [isWebform, setIsWebform] = useState(false);

  const onClickHandler = (e, id) => {
    e.preventDefault();
    document.getElementById(id).click();
  };

  setInterval(function() {
    if (
      document.getElementById("active_tab") &&
      document.getElementById("tab_back") &&
      document.getElementById("tab_next")
    ) {
      if (document.getElementById("tab_create")) {
        document
          .getElementById("tab_create")
          .setAttribute("disabled", "disabled");
      }
      if (document.getElementById("terms_conditions")) {
        document.getElementById("terms_conditions").style.display = "none";
      }
      if (document.getElementById("signature_section")) {
        document.getElementById("signature_section").style.display = "none";
      }
      let active_tab = document.getElementById("active_tab").value;

      if (
        active_tab === "" ||
        active_tab === document.getElementById("first_tab").value
      ) {
        document
          .getElementById("tab_back")
          .setAttribute("disabled", "disabled");
        document.getElementById("tab_next").removeAttribute("disabled");
      } else if (active_tab === document.getElementById("last_tab").value) {
        document
          .getElementById("tab_next")
          .setAttribute("disabled", "disabled");
        if (document.getElementById("tab_create")) {
          document.getElementById("tab_create").removeAttribute("disabled");
        }
        if (document.getElementById("terms_conditions")) {
          document.getElementById("terms_conditions").style.display = "block";
        }
        if (document.getElementById("signature_section")) {
          document.getElementById("signature_section").style.display = "block";
        }
        document.getElementById("tab_back").removeAttribute("disabled");
      } else {
        document.getElementById("tab_back").removeAttribute("disabled");
        document.getElementById("tab_next").removeAttribute("disabled");
      }
    }
  }, 100); // check every 100ms

  useEffect(() => {
    if (load) {
      let checkExist = setInterval(function() {
        if (document.getElementById("action_buttons")) {
          let temp = [];
          [
            "back",
            "next",
            "update",
            "force_update",
            "create",
            "force_create",
            "convert",
            "converted",
          ].forEach((b) => {
            let obj = document.getElementById(b);
            if (obj) {
              if (obj.innerText === "Submit") setIsWebform(true);
              temp = [
                ...temp,
                { id: obj.id, disabled: obj.disabled, text: obj.innerText },
              ];
            }
          });
          setButtons(temp);
          clearInterval(checkExist);

          if (
            document.getElementById("tab_create") &&
            document.getElementById("active_tab")
          ) {
            document
              .getElementById("tab_create")
              .setAttribute("disabled", "disabled");

            if (document.getElementById("terms_conditions")) {
              document.getElementById("terms_conditions").style.display =
                "none";
            }
            if (document.getElementById("signature_section")) {
              document.getElementById("signature_section").style.display =
                "none";
            }
          }
        }
      }, 100); // check every 100ms
      setLoad(false);
    }
  }, [load, props.user]);

  return (
    <div
      className="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop kt-footer--fixed"
      id="kt_footer"
    >
      <div className="col-md-12">
        <div className="kt-footer__copyright"></div>
        <div className="kt-footer__menu mb-1">
          {window.location.hostname === "quote.mirsgroupllc.com" && isWebform && (
            <a
              className="btn btn-primary mr-2"
              href="https://quote.mirsgroupllc.com"
            >
              <i className="fa fa-home fa-lg" />
              Home
            </a>
          )}
          {buttons.map((b, i) => (
            <button
              type="button"
              key={i}
              id={"tab_" + b.id}
              className="btn btn-primary mr-3"
              onClick={(e) => onClickHandler(e, b.id)}
            >
              {b.id === "back" && (
                <i className="fa fa-angle-double-left mr-2" />
              )}
              {b.text}
              {b.id === "next" && (
                <i className="fa fa-angle-double-right ml-2" />
              )}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(Footer);
