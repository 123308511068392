import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";

import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../partials/content/Portlet";
import { backendApiUrl } from "../../utils";
import Message from "../../utils/Message";

const Notes = (props) => {
  const [load, setLoad] = useState(true);

  const [msg, setMsg] = useState({ show: false });

  const [notes, setNotes] = useState([]);

  const [note, setNote] = useState({
    module: props.module,
    owner: props.owner,
  });

  const openEditNote = (data) => {
    let n = { ...data };
    n.module = props.module;
    n.owner = props.owner;
    n.load = true;
    setNote(n);
  };

  const onChangeHandler = (event) => {
    let n = { ...note };
    n[event.target.id] = event.target.value;
    setNote(n);
  };

  const saveOnClickHandler = () => {
    let data = { ...note };
    let shared = {};
    note.users &&
      note.users.forEach((u) => {
        if (shared[u.type]) {
          shared[u.type] = [...shared[u.type], u._id];
        } else {
          shared[u.type] = [u._id];
        }
      });
    data.users = shared;
    data.MI = props.MI;
    axios
      .post(backendApiUrl("notes"), data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setNote({
          module: props.module,
          owner: props.owner,
        });
        setMsg({
          status: 200,
          msg: "Notes created successfully.",
          show: true,
        });
        setLoad(true);
      })
      .catch((err) => {
        setMsg({
          status: 500,
          msg: "Unable to create note. please try again!",
          show: true,
        });
      });
  };

  const editOnClickHandler = (id) => {
    let data = { ...note };
    let shared = {};
    note.users.forEach((u) => {
      if (shared[u.type]) {
        shared[u.type] = [...shared[u.type], u._id];
      } else {
        shared[u.type] = [u._id];
      }
    });
    data.users = shared;
    data.MI = props.MI;
    axios
      .patch(backendApiUrl("notes/" + note._id), data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setNote({
          module: props.module,
          owner: props.owner,
        });
        setMsg({
          status: 200,
          msg: "Notes updated successfully.",
          show: true,
        });
        setLoad(true);
      })
      .catch((err) => {
        setMsg({
          status: 500,
          msg: "Unable to create note. please try again!",
          show: true,
        });
      });
  };

  useEffect(() => {
    if (load) {
      axios
        .post(
          backendApiUrl("notes/list"),
          {
            module: props.module,
            owner: props.owner,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setNotes(res.data.notes);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
          setLoad(false);
        });
    }
  }, [load, props.module, props.owner]);

  let permissions = {};
  if ((props.user && props.user.role.admin) || props.module.MDID === "") {
    permissions = { add: true, update: true, delete: true };
  } else if (props.permissions && props.permissions.notes) {
    permissions = props.permissions.notes;
  }
  let allowToEdit = false;
  if (permissions.add || permissions.update) allowToEdit = true;

  return (
    <div className="mt-3">
      <Portlet>
        <PortletHeader title="Notes" />
        <PortletBody>
          {load ? (
            <div style={{ textAlign: "center" }}>
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <Grid container spacing={2}>
              {msg.show && (
                <Message status={msg.status} setMsg={setMsg} msg={msg.msg} />
              )}

              <Grid container spacing={2}>
                <Grid item md={10}>
                  <div className="form-group">
                    <div className="col-md-12">
                      <label className="form-label" htmlFor="content">
                        Notes
                      </label>
                    </div>
                    {allowToEdit ? (
                      <textarea
                        name="content"
                        id="content"
                        onChange={onChangeHandler}
                        value={note.content ? note.content : ""}
                        className="form-control"
                      />
                    ) : (
                      <h6 className="text-primary">{note.content}</h6>
                    )}
                  </div>
                </Grid>

                <Grid item md={2}>
                  {note._id ? (
                    <button
                      className="btn btn-success btn-sm"
                      style={{ marginTop: "25px" }}
                      onClick={editOnClickHandler}
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      className="btn btn-success btn-sm"
                      style={{ marginTop: "25px" }}
                      onClick={saveOnClickHandler}
                    >
                      Save
                    </button>
                  )}
                </Grid>
              </Grid>

              <table className="table table-hover">
                <thead>
                  <tr>
                    <th nowrap="true" width="60%">
                      Notes
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {notes.length > 0 ? (
                    notes.map((n, i) => (
                      <tr key={i}>
                        <td>
                          <p>
                            {n.content}&nbsp;&nbsp;&nbsp;&nbsp;
                            <i
                              className="fa fa-edit fa-lg text-primary"
                              title="Edit the note."
                              onClick={(e) => openEditNote(n)}
                            />
                            <br />
                            <span style={{ fontSize: "12px" }}>
                              <i>
                                <b>Created By:</b> {n.created_by.user} -{" "}
                                {n.createdAt}
                                {!!n.updated_by.user && (
                                  <>
                                    <br />
                                    <b>Updated By:</b> {n.updated_by.user} -{" "}
                                    {n.updatedAt}
                                  </>
                                )}
                              </i>
                            </span>
                          </p>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={2}>
                        <h5 className="text-primary text-center">
                          No notes available, create one!
                        </h5>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Grid>
          )}
        </PortletBody>
      </Portlet>
    </div>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(Notes);
