import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { backendApiUrl } from "../utils";

import Admin from "./Admin";

const Create = (props) => {
  let MDID = useParams().MDID;
  if (!!props.MDID) MDID = props.MDID;

  const [moduleInfo, setModuleInfo] = useState({
    msg: { show: false },
    load: true,
    admin: {},
    opportunity_id: "",
    from: "opportunities",
    MDID: "",
    prospect: MDID,
    permissions: { add: true, update: true, delete: true },
  });

  const onSubmitHandler = async () => {
    let data = {};
    let MI = { ...moduleInfo };

    //if (MI.admin.product) data.product = MI.admin.product._id;
    if (MI.admin.program) {
      let obj = MI.admin.program.value.split("---");
      data.product = obj[0];
      data.program = obj[1];
    }

    let workforces = [];
    MI.admin.workforces &&
      MI.admin.workforces.forEach((workforce) => {
        workforces = [...workforces, workforce.value];
      });

    let contacts = [];
    MI.admin.contacts &&
      MI.admin.contacts.forEach((contact) => {
        contacts = [...contacts, contact.value];
      });

    let status = [];
    MI.admin.status &&
      MI.admin.status.forEach((s) => {
        status = [...status, s._id];
      });

    let source = [];
    MI.admin.source &&
      MI.admin.source.forEach((s) => {
        source = [...source, s._id];
      });

    let groups = [];
    MI.admin.groups &&
      MI.admin.groups.forEach((s) => {
        groups = [...groups, s._id];
      });

    data.status = status;
    data.source = source;
    data.workforces = workforces;
    data.contacts = contacts;
    data.prospect = MI.prospect;
    data.groups = MI.groups;

    await axios
      .post(backendApiUrl("opportunities/add"), data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        MI.opportunity_id = res.data._id;
        setModuleInfo(MI);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (!!moduleInfo.opportunity_id) {
    return <Redirect to={"/petitions/edit/" + moduleInfo.opportunity_id} />;
  }

  return (
    <div className="col-md-12">
      <Admin module={moduleInfo} actions={{ setModuleInfo }} />
      <hr />
      <button
        className="btn btn-sm btn-primary"
        onClick={() => props.setCrud("list")}
      >
        Back
      </button>
      &nbsp;&nbsp;
      <button className="btn btn-sm btn-primary" onClick={onSubmitHandler}>
        Create Petition & Load Form
      </button>
    </div>
  );
};

export default Create;
