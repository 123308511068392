import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import Create from "./Create";
import List from "./List";

const Opportunities = (props) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const action = params.get("a");

  const [crudType, setCrudType] = useState("list");

  const { menuConfig } = useSelector(
    ({ builder: { menuConfig } }) => ({
      menuConfig,
    }),
    shallowEqual
  );

  let MDID = "";
  if (menuConfig.loggedInUserType === "prospect") {
    MDID = menuConfig.loggedInUserId;
  }

  useEffect(() => {
    if (!!action) setCrudType(action);
  }, [action]);

  return (
    <div>
      {crudType === "list" && <List setCrud={setCrudType} MDID={MDID} />}
      {crudType === "create" && <Create setCrud={setCrudType} MDID={MDID} />}
    </div>
  );
};

export default Opportunities;
