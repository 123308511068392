import React from "react";
import { connect } from "react-redux";
import Container from "./GridContainer";
import Grid from "./GridItem";
import Section from "./Section";
import Tabs from "./Tabs";
import TextBox from "./TextBox";
import TextArea from "./TextArea";
import Password from "./Password";
import Amount from "./Amount";
import Percentage from "./Percentage";
import Number from "./Number";
import Separator from "./Separator";
import TimePicker from "./TimePicker";
import DatePicker from "./DatePicker";
import DateTimePicker from "./DateTimePicker";
import Select from "./Select";
import MultiSelect from "./MultiSelect";
import Checkbox from "./Checkbox";
import RadioButton from "./RadioButton";
import Paragraph from "./Paragraph";
import HeaderText from "./HeaderText";
import Button from "./Button";
import Email from "./Email";
import Phone from "./Phone";
import Cell from "./Cell";
import SSN from "./SocialSecurityNumber";
import WebSite from "./WebSite";
import FilePicker from "./FilePicker";
import Zip from "./Zip";
import UsStates from "./UsStates";
import CloneableSection from "./CloneableSection";
import Signature from "./Signature";
import Status from "./admin/Status";
import AutoIncrement from "./admin/AutoIncrement";

const Elements = ({ element, module, events, user }) => {
  element.class = "";
  //Validation Check
  if (element.mandatory) element.class = " LS_mandatory";
  if (
    module.requiredFields.includes(element.id) &&
    (element.value === "" || element.value === null || element.value === null)
  ) {
    element.class += " is-invalid";
    element.isInValid = true;
  } else {
    element.class += " ";
    element.isInValid = false;
  }
  
  // Highlight loan guideline
  if (module.form.guidelines && module.form.guidelines[element.id]) {
    element.class += " is-invalid";
  }

  var inputProps = { element, module, events };

  // Section hide based on roles
  if (
    element.hide &&
    element.hided_roles &&
    element.hided_roles.length > 0 &&
    ((user && element.hided_roles.includes(user.role._id)) || !user)
  ) {
    element.type = "";
  }

  // Readonly based on roles
  if (
    element.readonly &&
    element.readonly_roles &&
    ((user && element.readonly_roles.includes(user.role._id)) ||
      element.readonly_roles.includes("all"))
  ) {
    element.readonly = true;
  } else {
    element.readonly = false;
  }

  switch (element.type) {
    case "container":
      return <Container {...inputProps} />;
    case "grid":
      return <Grid {...inputProps} />;
    case "section":
      if (element.cloneable) {
        return <CloneableSection {...inputProps} />;
      } else {
        return <Section {...inputProps} />;
      }
    case "status":
      return <Status {...inputProps} />;
    case "tab":
      return <Tabs {...inputProps} />;
    case "text":
      return <TextBox {...inputProps} />;
    case "auto_increment":
      return <AutoIncrement {...inputProps} />;
    case "textarea":
      return <TextArea {...inputProps} />;
    case "texteditor":
      return <TextArea {...inputProps} />;
    case "date":
      return <DatePicker {...inputProps} />;
    case "time":
      return <TimePicker {...inputProps} />;
    case "datetime":
      return <DateTimePicker {...inputProps} />;
    case "select":
      return <Select {...inputProps} />;
    case "multiselect":
      return <MultiSelect {...inputProps} />;
    case "radio":
      return <RadioButton {...inputProps} />;
    case "checkbox":
      return <Checkbox {...inputProps} />;
    case "number":
      return <Number {...inputProps} />;
    case "password":
      return <Password {...inputProps} />;
    case "email":
      return <Email {...inputProps} />;
    case "phone":
      return <Phone {...inputProps} />;
    case "cell":
      return <Cell {...inputProps} />;
    case "ssn":
      return <SSN {...inputProps} />;
    case "amount":
      return <Amount {...inputProps} />;
    case "percentage":
      return <Percentage {...inputProps} />;
    case "state":
      return <Select {...inputProps} />;
    case "website":
      return <WebSite {...inputProps} />;
    case "file":
      return <FilePicker {...inputProps} />;
    case "image":
      return <FilePicker {...inputProps} />;
    case "signature":
      return <Signature {...inputProps} />;
    case "dropzone":
      return <FilePicker {...inputProps} />;
    case "button":
      return <Button {...inputProps} />;
    case "headertext":
      return <HeaderText {...inputProps} />;
    case "separator":
      return <Separator {...inputProps} />;
    case "paragraph":
      return <Paragraph {...inputProps} />;
    case "hiddenfield":
      return <TextBox {...inputProps} />;
    case "zip":
      return <Zip {...inputProps} />;
    case "us_states":
      return <UsStates {...inputProps} />;
    default:
      return "";
  }
};

const FromElements = (props) => {
  if (props.element.show === false) {
    return "";
  } else {
    return (
      <Elements
        element={props.element}
        module={props.module}
        events={props.events}
        user={props.user}
      />
    );
  }
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(FromElements);
