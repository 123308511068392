import React, { useState } from "react";
import axios from "axios";

import { backendApiUrl } from "../utils";

const StatusUpdate = (props) => {
    const [status, setStatus] = useState("");
    const statusOption = [
        { label: "Awaiting For Review", value: "Awaiting For Review" },
        { label: "Resubmit", value: "Resubmit" },
        { label: "Not Submitted", value: "Not Submitted" },
    ];

    const onChangeHandler = (e) => {
        if (!!e.target.value) {
            setStatus(e.target.value);
            onSaveHandler(e.target.value);
        }
    };

    const onSaveHandler = (status) => {
        axios
            .post(
                backendApiUrl("timesheet/status"),
                {
                    status,
                    _id: props.id,
                    change_type: "manual",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then(async (res) => {
                props.setMsg({
                    status: 200,
                    msg: "Status updated successfully.",
                    show: true,
                });
                setTimeout(() => {
                    props.setLoad(true);
                }, 2000);
            })
            .catch((e) => {
                props.setMsg({
                    status: 200,
                    msg: "Problem while update status.",
                    show: true,
                });
            });
    };

    return (
        <select
            className="form-control mt-2"
            onChange={onChangeHandler}
            value={status}
        >
            <option value="">Select Status</option>
            {statusOption.map((op, i) => (
                <option value={op.value} key={i}>
                    {op.label}
                </option>
            ))}
        </select>
    );
};

export default StatusUpdate;
