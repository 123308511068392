import React from "react";
import { useDropzone } from "react-dropzone";

import { formatBytes } from "../../utils";
import "../../docs/dropzone.css";
import { MdDelete } from "react-icons/md";

const Attachment = (props) => {
  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    onDrop: (acceptedFiles) =>
      acceptedFiles.map((file) => props.onAttachmentHandler(file, props.week)),
  });
  
  const deleteFile = (index, week) => {
    if (props.file) {
      // Filter out the file with the specified index
      const newFiles = props.file.filter((file, i) => i !== index);
      // Update the state with the new files
      props.onAttachmentDeleteHandler(newFiles, week);
    }
  };

  return (
    <section className="container">
      <div
        {...getRootProps({ className: "dropzone" })}
        style={{ width: "200px", height: "50px", alignContent: "center" }}
      >
        <input {...getInputProps()} name="test" />
        <p>
          <b>Drag & Drop files here</b>
        </p>
      </div>

      {Array.isArray(props.file) && (
        <aside className="mt-2" style={{ width: "200px" }}>
          <h6>Files</h6>
          {props.file.map((f, i) => (
            <>
              <span key={i}>
                {f.path} - {formatBytes(f.size, 2)}
                <MdDelete
                  size="25"
                  color="#fd397a"
                  onClick={() => deleteFile(i, props.week)}
                />
              </span>
              <br />
            </>
          ))}
        </aside>
      )}
    </section>
  );
};

export default Attachment;
