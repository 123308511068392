import React, { useState } from "react";
import { connect } from "react-redux";
import { Grid, Switch } from "@material-ui/core";
import { MdOutlineNoteAlt } from "react-icons/md";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { DateRange } from "react-date-range";
import { IoDocumentAttach } from "react-icons/io5";
import {
  Badge,
  OverlayTrigger,
  Popover,
  Tooltip,
  Modal,
  Button,
} from "react-bootstrap";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import StatusUpdate from "./StatusUpdate";
import { FaRegEye } from "react-icons/fa";

import {
  Portlet,
  PortletHeader,
  PortletHeaderToolbar,
  PortletBody,
} from "../partials/content/Portlet";
import Attachment from "./utils/Attachment";
import { getWeek, getData } from "./utils/DateFun";

const moment = require("moment-timezone");

const Weekly = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [attachment, setAttachment] = useState([]);
  let totalHours = 0;
  const status = !!getData(
    props.state.data,
    moment(props.state.startDate).format("MM/DD/YYYY") +
      "to" +
      moment(props.state.endDate).format("MM/DD/YYYY"),
    "status"
  )
    ? getData(
        props.state.data,
        moment(props.state.startDate).format("MM/DD/YYYY") +
          "to" +
          moment(props.state.endDate).format("MM/DD/YYYY"),
        "status"
      )
    : "Not Submitted";

  let allowToSubmit = true;
  if (
    moment(props.state.startDate).format("MM/DD/YYYY") ===
    moment()
      .startOf("isoWeek")
      .format("MM/DD/YYYY")
  ) {
    if (
      moment().format("MM/DD/YYYY") >= props.state.weeks[0].days[4] ||
      moment()
        .endOf("month")
        .format("MM/DD/YYYY") === moment().format("MM/DD/YYYY")
    ) {
      allowToSubmit = true;
    } else {
      allowToSubmit = false;
    }
  }

  const onNextAndPreviousWeekHandler = (action) => {
    let st = { ...props.state };

    if (action === "Previous") {
      st.startDate = moment(st.startDate)
        .subtract(1, "days")
        .startOf("isoWeek");
      st.endDate = moment(st.startDate).endOf("isoWeek");
    } else {
      st.endDate = moment(st.endDate)
        .add(1, "days")
        .endOf("isoWeek");
      st.startDate = moment(st.endDate).startOf("isoWeek");
    }
    st.weeks = getWeek(st.startDate, st.endDate);
    st.load = true;
    props.events.setState(st);
  };

  const onCalendarChangeHandler = (e) => {};
  const showAttachment = (files, week) => {
    setOpenModal(true);
    setAttachment(files);
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12} align="center">
        <Portlet>
          <PortletHeader
            title="Weekly Timesheet"
            toolbar={
              <PortletHeaderToolbar>
                <button
                  className="btn btn-sm btn-default"
                  onClick={() => props.events.onClose({})}
                >
                  {"<< Back"}
                </button>
                &nbsp;
                <button
                  className={
                    props.state.type === "Weekly"
                      ? "btn btn-sm btn-primary"
                      : "btn btn-sm btn-default"
                  }
                  onClick={() => props.events.setType("Weekly")}
                >
                  Weekly
                </button>
                &nbsp;
                <button
                  className={
                    props.state.type === "Monthly"
                      ? "btn btn-sm btn-primary"
                      : "btn btn-sm btn-default"
                  }
                  onClick={() => props.events.setType("Monthly")}
                >
                  Monthly
                </button>
              </PortletHeaderToolbar>
            }
          />
          <PortletBody>
            <table className="table table-dark">
              {props.state.weeks &&
                props.state.weeks.map((we, b) => (
                  <thead key={b}>
                    <tr className="table-active">
                      <th colSpan={10} style={{ textAlign: "center" }}>
                        <div className="date-input">
                          <h5>
                            {new Date(
                              moment(props.state.client.project_start_date)
                            ) <=
                              new Date(moment(we.startDate, "MM/DD/YYYY")) && (
                              <FiChevronLeft
                                className="left-right-margin npBtn"
                                size={25}
                                onClick={() =>
                                  onNextAndPreviousWeekHandler("Previous")
                                }
                              />
                            )}
                            {we.startDate}
                            <span className="left-right-margin">to</span>
                            {we.endDate}
                            {moment(we.endDate, "MM/DD/YYYY")
                              .format("MM/DD/YYYY")
                              .toString() !==
                              moment()
                                .endOf("isoWeek")
                                .format("MM/DD/YYYY")
                                .toString() && (
                              <FiChevronRight
                                className="left-right-margin npBtn"
                                size={25}
                                onClick={() =>
                                  onNextAndPreviousWeekHandler("Next")
                                }
                              />
                            )}
                          </h5>
                        </div>
                        <div style={{ float: "right" }}>
                          Have you worked weekends?
                          <Switch
                            name="allowWeekend"
                            checked={
                              getData(
                                props.state.data,
                                we.startDate + "to" + we.endDate,
                                "allowWeekend"
                              )
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              props.events.onHourChange(
                                e,
                                we.startDate + "to" + we.endDate
                              )
                            }
                          />
                        </div>
                      </th>
                    </tr>
                    <tr>
                      {we.days.map(
                        (w, i) =>
                          ((["Saturday", "Sunday"].includes(
                            moment(w).format("dddd")
                          ) &&
                            getData(
                              props.state.data,
                              we.startDate + "to" + we.endDate,
                              "allowWeekend"
                            )) ||
                            !["Saturday", "Sunday"].includes(
                              moment(w).format("dddd")
                            )) && (
                            <th className="alignCenter" key={i}>
                              {moment(w).format("MM/DD")}
                              <br />
                              {moment(w).format("dddd")}
                            </th>
                          )
                      )}
                      <th className="table-active">Attachment</th>
                      <th className="table-active">Notes</th>
                      <th className="table-active">Status</th>
                    </tr>
                    <tr>
                      {we.days.map(
                        (w, i) =>
                          ((["Saturday", "Sunday"].includes(
                            moment(w).format("dddd")
                          ) &&
                            getData(
                              props.state.data,
                              we.startDate + "to" + we.endDate,
                              "allowWeekend"
                            )) ||
                            !["Saturday", "Sunday"].includes(
                              moment(w).format("dddd")
                            )) && (
                            <th className="alignCenter" key={i}>
                              <span style={{ display: "none" }}>
                                {parseFloat(
                                  getData(
                                    props.state.data[
                                      we.startDate + "to" + we.endDate
                                    ],
                                    "dailyHours",
                                    moment(w)
                                      .format("MM/DD/YYYY")
                                      .toString()
                                  )
                                ) > 0
                                  ? (totalHours += parseFloat(
                                      getData(
                                        props.state.data[
                                          we.startDate + "to" + we.endDate
                                        ],
                                        "dailyHours",
                                        moment(w)
                                          .format("MM/DD/YYYY")
                                          .toString()
                                      )
                                    ))
                                  : ""}
                              </span>
                              <input
                                type="number"
                                className="form-control"
                                max="24"
                                value={getData(
                                  props.state.data[
                                    we.startDate + "to" + we.endDate
                                  ],
                                  "dailyHours",
                                  moment(w)
                                    .format("MM/DD/YYYY")
                                    .toString()
                                )}
                                name={moment(w)
                                  .format("MM/DD/YYYY")
                                  .toString()}
                                onChange={(e) =>
                                  e.target.value === "" || e.target.value <= 24
                                    ? props.events.onHourChange(
                                        e,
                                        we.startDate + "to" + we.endDate
                                      )
                                    : true
                                }
                                disabled={props.events.inputDisabled(
                                  w,
                                  status,
                                  props.state.data[
                                    we.startDate + "to" + we.endDate
                                  ]
                                    ? props.state.data[
                                        we.startDate + "to" + we.endDate
                                      ].dailyHours
                                    : []
                                )}
                              />
                            </th>
                          )
                      )}
                      <th className="table-active">
                        <OverlayTrigger
                          placement="top"
                          trigger="click"
                          overlay={
                            <Popover
                              style={{ width: "300px", maxWidth: "none" }}
                            >
                              <Attachment
                                onAttachmentHandler={
                                  props.events.onAttachmentHandler
                                }
                                onAttachmentDeleteHandler={
                                  props.events.onAttachmentDeleteHandler
                                }
                                week={we.startDate + "to" + we.endDate}
                                file={
                                  props.state.data[
                                    we.startDate + "to" + we.endDate
                                  ]
                                    ? props.state.data[
                                        we.startDate + "to" + we.endDate
                                      ].file
                                    : null
                                }
                              />
                            </Popover>
                          }
                          rootClose
                        >
                          <IoDocumentAttach
                            size="25"
                            color={
                              props.state.data[
                                we.startDate + "to" + we.endDate
                              ] &&
                              props.state.data[we.startDate + "to" + we.endDate]
                                .file &&
                              props.state.data[we.startDate + "to" + we.endDate]
                                .file.length > 0
                                ? "#e83e8c"
                                : "#fff"
                            }
                          />
                        </OverlayTrigger>
                        <br />
                        {/*(props.state.data[we.startDate + "to" + we.endDate] &&
                          !!props.state.data[we.startDate + "to" + we.endDate]
                            .file &&
                          !props.state.data[we.startDate + "to" + we.endDate]
                            .file.path) ||
                          (!!props.state.data[we.startDate + "to" + we.endDate]
                            .files && (
                            <a
                              href={
                                process.env.REACT_APP_BACKEND_API_URL +
                                "auth/timesheet/doc/" +
                                props.state.companyId +
                                "/" +
                                props.state.data[
                                  we.startDate + "to" + we.endDate
                                ].file
                              }
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              view
                            </a>
                            ))*/}

                        {props.state.data[we.startDate + "to" + we.endDate] &&
                          props.state.data[we.startDate + "to" + we.endDate]
                            .files &&
                          props.state.data[we.startDate + "to" + we.endDate]
                            .files.length > 0 && (
                            <span style={{ margin: "5px" }}>
                              <OverlayTrigger
                                placement="top"
                                trigger="click"
                                overlay={<Tooltip>Show the Attachment</Tooltip>}
                                rootClose
                              >
                                <FaRegEye
                                  size="25"
                                  color={
                                    props.state.data[
                                      we.startDate + "to" + we.endDate
                                    ] &&
                                    props.state.data[
                                      we.startDate + "to" + we.endDate
                                    ].files &&
                                    props.state.data[
                                      we.startDate + "to" + we.endDate
                                    ].files.length > 0
                                      ? "#e83e8c"
                                      : "#fff"
                                  }
                                  onClick={() =>
                                    showAttachment(
                                      props.state.data[
                                        we.startDate + "to" + we.endDate
                                      ].files,
                                      we.startDate + "to" + we.endDate
                                    )
                                  }
                                />
                              </OverlayTrigger>
                            </span>
                          )}
                      </th>
                      <th className="table-active">
                        {(props.state.data[we.startDate + "to" + we.endDate] &&
                          props.state.data[we.startDate + "to" + we.endDate]
                            .notes) ||
                        (props.state.notes &&
                          props.state.notes[
                            we.startDate + "to" + we.endDate
                          ]) ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                <table className="table table-hover">
                                  <thead>
                                    <tr>
                                      <th>Notes</th>
                                    </tr>
                                    {props.state.data[
                                      we.startDate + "to" + we.endDate
                                    ] &&
                                      props.state.data[
                                        we.startDate + "to" + we.endDate
                                      ].notes &&
                                      props.state.data[
                                        we.startDate + "to" + we.endDate
                                      ].notes.map((n, g) => (
                                        <tr key={g}>
                                          <th>
                                            {n}
                                            <br />
                                            <span>
                                              <b>By: </b>
                                              {props.user.fullName +
                                                "(" +
                                                props.user.type +
                                                ")"}
                                            </span>
                                          </th>
                                        </tr>
                                      ))}
                                    {props.state.notes &&
                                      props.state.notes[
                                        we.startDate + "to" + we.endDate
                                      ] &&
                                      props.state.notes[
                                        we.startDate + "to" + we.endDate
                                      ].map((n, g) => (
                                        <tr key={g}>
                                          <th>
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: n,
                                              }}
                                            />
                                          </th>
                                        </tr>
                                      ))}
                                  </thead>
                                </table>
                              </Tooltip>
                            }
                          >
                            <MdOutlineNoteAlt
                              size="25"
                              color="#fff"
                              onClick={() =>
                                props.events.showNotes(
                                  we.startDate + "to" + we.endDate
                                )
                              }
                            />
                          </OverlayTrigger>
                        ) : (
                          <MdOutlineNoteAlt
                            size="25"
                            color="#fff"
                            onClick={() =>
                              props.events.showNotes(
                                we.startDate + "to" + we.endDate
                              )
                            }
                          />
                        )}
                      </th>
                      <th className="table-active">
                        <OverlayTrigger
                          placement="top"
                          trigger="click"
                          overlay={
                            <Tooltip>
                              {!!status &&
                              status === "Approved" &&
                              props.user.role.isTimesheetApprover ? (
                                <StatusUpdate
                                  id={
                                    props.state.data[
                                      we.startDate + "to" + we.endDate
                                    ]._id
                                  }
                                  status={
                                    props.state.data[
                                      we.startDate + "to" + we.endDate
                                    ].status
                                  }
                                  setMsg={props.events.setMsg}
                                  setLoad={props.events.setLoad}
                                />
                              ) : (
                                "Time Sheet Status"
                              )}
                            </Tooltip>
                          }
                          rootClose
                        >
                          {!!status ? (
                            <Badge
                              variant={
                                status === "Awaiting For Review"
                                  ? "warning"
                                  : status === "Approved"
                                  ? "success"
                                  : status === "Partially Approve"
                                  ? "success"
                                  : status === "Resubmit"
                                  ? "danger"
                                  : "info"
                              }
                              className="rounded-corners"
                            >
                              {status}
                            </Badge>
                          ) : (
                            <Badge variant="info" className="rounded-corners">
                              Not Submitted
                            </Badge>
                          )}
                        </OverlayTrigger>
                      </th>
                    </tr>
                  </thead>
                ))}
            </table>
            <div className="col-md-12" align="center">
              <h4>
                Total Hours:{" "}
                <span className="text-success">{totalHours}.00 hours</span>
              </h4>
              <br />
              {status === "Awaiting For Review" ? (
                <>
                  {props.user.type === "Workforces" &&
                  (props.user._id ===
                    getData(
                      props.state.data,
                      moment(props.state.startDate).format("MM/DD/YYYY") +
                        "to" +
                        moment(props.state.endDate).format("MM/DD/YYYY"),
                      "approver"
                    ) ||
                    props.user.role.isTimesheetApprover) ? (
                    <>
                      <button
                        className="btn btn-sm btn-success mr-2"
                        onClick={() =>
                          props.events.onApprove({
                            status: "Approve",
                            _id: getData(
                              props.state.data,
                              moment(props.state.startDate).format(
                                "MM/DD/YYYY"
                              ) +
                                "to" +
                                moment(props.state.endDate).format(
                                  "MM/DD/YYYY"
                                ),
                              "_id"
                            ),
                          })
                        }
                      >
                        <h5>Approve</h5>
                      </button>
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() =>
                          props.events.onApprove({
                            status: "Resubmit",
                            _id: getData(
                              props.state.data,
                              moment(props.state.startDate).format(
                                "MM/DD/YYYY"
                              ) +
                                "to" +
                                moment(props.state.endDate).format(
                                  "MM/DD/YYYY"
                                ),
                              "_id"
                            ),
                          })
                        }
                      >
                        <h5>Request to Resubmit</h5>
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() => {
                          props.events.EmployeeResubmit(
                            getData(
                              props.state.data,
                              moment(props.state.startDate).format(
                                "MM/DD/YYYY"
                              ) +
                                "to" +
                                moment(props.state.endDate).format(
                                  "MM/DD/YYYY"
                                ),
                              "_id"
                            )
                          );
                        }}
                      >
                        <h5>Resubmit</h5>
                      </button>
                      {/*<Badge variant="warning">{status}</Badge> */}
                    </>
                  )}
                </>
              ) : status === "Approved" ? (
                <Badge variant="success">{status}</Badge>
              ) : (
                <>
                  <button
                    className="btn btn-sm btn-success mr-2"
                    onClick={() => props.events.onSubmit("Saved")}
                  >
                    <h5>SAVE</h5>
                  </button>
                  {allowToSubmit && (
                    <button
                      className="btn btn-sm btn-success"
                      onClick={() => props.events.onSubmit("Submitted")}
                    >
                      <h5>SUBMIT</h5>
                    </button>
                  )}
                </>
              )}
            </div>
            {openModal && (
              <Modal
                show={true}
                size="md"
                onHide={() => setOpenModal(false)}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title> Attachments</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Grid container spacing={2}></Grid>
                </Modal.Body>
                {attachment &&
                  attachment.map((a, i) => (
                    <span style={{ margin: "15px" }} key={i}>
                      <a
                        href={
                          process.env.REACT_APP_BACKEND_API_URL +
                          "auth/timesheet/doc/" +
                          props.state.companyId +
                          "/" +
                          a
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Attached File {i + 1}
                      </a>
                    </span>
                  ))}
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setOpenModal(false)}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
          </PortletBody>
        </Portlet>
      </Grid>
      <Grid item md={4} align="center"></Grid>
      <Grid item md={4} align="center">
        <table className="table table-dark">
          <thead>
            <tr className="table-active">
              <th style={{ textAlign: "center" }}>
                <h4>Calendar View</h4>
                <div>
                  <Badge variant="info" className="rounded-corners mr-2">
                    Not Submitted
                  </Badge>
                  <Badge variant="warning" className="rounded-corners mr-2">
                    Awaiting For Review
                  </Badge>
                  <Badge variant="success" className="rounded-corners mr-2">
                    Approved
                  </Badge>
                  <Badge variant="danger" className="rounded-corners mr-2">
                    Resubmit
                  </Badge>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <DateRange
                  editableDateInputs={false}
                  moveRangeOnFirstSelection={false}
                  onChange={(e) => onCalendarChangeHandler(e)}
                  ranges={[
                    {
                      startDate: new Date(props.state.startDate.toString()),
                      endDate: new Date(props.state.endDate.toString()),
                      key: "week1",
                      color:
                        status === "Awaiting For Review"
                          ? "rgb(254, 209, 76)"
                          : status === "Approved"
                          ? "#0abb87"
                          : status === "Resubmit"
                          ? "#fd397a"
                          : "rgb(61, 145, 255)",
                    },
                  ]}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(Weekly);
