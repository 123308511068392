import React, { useState } from "react";
import { Badge } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

import { populateFieldData } from "../../utils";

const VendorDetails = (props) => {
  const [vendor, setVendor] = useState({});

  const onChangeHandler = (e) => {
    let d = { ...vendor };
    d[e.target.name] = e.target.value;
    setVendor(d);
  };

  const onSaveHandler = () => {
    let client = { ...props.client };
    client.vendors = [...client.vendors, vendor];
    props.setClient(client);
    setVendor({});
  };

  const onUpdateHandler = () => {
    let client = { ...props.client };
    client.vendors = [];
    props.client.vendors &&
      props.client.vendors.forEach((ven) => {
        if (vendor._id === ven._id) {
          client.vendors = [...client.vendors, vendor];
        } else {
          client.vendors = [...client.vendors, ven];
        }
      });

    props.setClient(client);
    setVendor({});
  };

  const onDeleteClickHandler = (_id) => {
    let client = { ...props.client };
    client.vendors = [];
    props.client.vendors &&
      props.client.vendors.forEach((ven) => {
        if (ven._id !== _id) {
          client.vendors = [...client.vendors, ven];
        }
      });

    props.setClient(client);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <h5 className="text-info">Vendor Details</h5>
        </div>
        <div className="col-md-6" align="right">
          <Badge variant="success" onClick={() => setVendor({ _id: uuidv4() })}>
            Add Vendor
          </Badge>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th nowrap="true">Vendor Name</th>
              <th nowrap="true">Vendor Location</th>
              <th nowrap="true">Account Manager</th>
              <th nowrap="true">Account Manager Email</th>
              <th nowrap="true">Account Manager Phone</th>
              <th nowrap="true">Actions</th>
            </tr>
          </thead>
          <tbody>
            {props.client.vendors ? (
              props.client.vendors.map((ven, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{ven.name}</td>
                  <td>{ven.location}</td>
                  <td>{ven.manager}</td>
                  <td>{ven.email}</td>
                  <td>{ven.phone}</td>
                  <td>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Click here to edit.</Tooltip>}
                    >
                      <i
                        className="fa fa-edit fa-lg text-info mr-2"
                        onClick={() => setVendor({ ...ven, edit: true })}
                      />
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Click here to deactivate.</Tooltip>}
                    >
                      <i
                        className="fa fa-trash fa-lg text-danger"
                        onClick={() => onDeleteClickHandler(ven._id)}
                      />
                    </OverlayTrigger>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7}>
                  <h5 className="text-primary text-center">
                    No vendors available, Create one!
                  </h5>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {(!!vendor._id || vendor._id > 0) && (
        <Modal show={true} size="lg" backdrop="static" keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>Vendor Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <div className="form-group">
                  <label className="form-label" htmlFor="name">
                    Vendor name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    onChange={onChangeHandler}
                    value={populateFieldData(vendor, "name")}
                    className="form-control input-sm"
                  />
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="form-group">
                  <label className="form-label" htmlFor="location">
                    Vendor Location
                  </label>
                  <input
                    type="text"
                    name="location"
                    onChange={onChangeHandler}
                    value={populateFieldData(vendor, "location")}
                    className="form-control input-sm"
                  />
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="form-group">
                  <label className="form-label" htmlFor="manager">
                    Account Manager <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="manager"
                    onChange={onChangeHandler}
                    value={populateFieldData(vendor, "manager")}
                    className="form-control input-sm"
                  />
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="form-group">
                  <label className="form-label" htmlFor="email">
                    Account Manager Email <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="email"
                    onChange={onChangeHandler}
                    value={populateFieldData(vendor, "email")}
                    className="form-control input-sm"
                  />
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="form-group">
                  <label className="form-label" htmlFor="phone">
                    Account Manager Phone <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="phone"
                    onChange={onChangeHandler}
                    value={populateFieldData(vendor, "phone")}
                    className="form-control input-sm"
                  />
                </div>
              </Grid>
            </Grid>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-sm btn-default"
              onClick={() => setVendor({})}
            >
              Close
            </button>
            {vendor.edit ? (
              <button
                className="btn btn-sm btn-primary"
                onClick={onUpdateHandler}
              >
                Update
              </button>
            ) : (
              <button
                className="btn btn-sm btn-primary"
                onClick={onSaveHandler}
              >
                Create
              </button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default VendorDetails;
