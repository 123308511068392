import React from "react";
import FormGroup from "./utils/FormGroup";

const TextArea = (props) => {
  return (
    <FormGroup element={props.element} module={props.module}>
      {props.module.permissions.update ? (
        <textarea
          placeholder={props.element.placeholder}
          className={"form-control " + props.element.class}
          id={props.element.id}
          disabled={props.element.readonly ? true : false}
          onChange={props.events.change}
          value={props.element.value}
        />
      ) : (
        <h6 className="text-primary">{props.element.value}</h6>
      )}
    </FormGroup>
  );
};

export default TextArea;
