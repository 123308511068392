import React, { useState } from "react";
import axios from "axios";
import { Grid } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";

import { backendApiUrl, formatBytes } from "../../utils";

const Files = (props) => {
  const [title, setTitle] = useState("");
  const [files, setFiles] = useState([]);

  let dualOptions = [];
  props.module.moduleDocs &&
    props.module.moduleDocs.forEach((md) => {
      md.fields &&
        md.fields.forEach((fld) => {
          let temp = [];
          fld.doc &&
            fld.doc.files.forEach((file, z) => {
              temp = [
                ...temp,
                {
                  value: file._id,
                  label:
                    fld.title +
                    "_" +
                    (z + 1) +
                    " - " +
                    formatBytes(file.size, 2),
                },
              ];
            });

          dualOptions = [
            ...dualOptions,
            {
              label: fld.title,
              options: temp,
            },
          ];
        });
    });

  const mergeClickHandler = () => {
    axios
      .post(
        backendApiUrl("merge/submit"),
        {
          title,
          form: props.module.form._id,
          owner: props.module.MDID,
          files,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        let st = { ...props.state };
        st.load = true;
        props.onShow(false);
        props.setState(st);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      show={true}
      size="lg"
      backdrop="static"
      onHide={() => props.onShow(false)}
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Files</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="form-group">
              <label htmlFor="title">
                Title <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="title">Uploaded Documents</label>
            <DualListBox
              options={dualOptions}
              selected={files}
              onChange={setFiles}
              preserveSelectOrder
              showOrderButtons
            />
          </Grid>
        </Grid>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" onClick={() => props.onShow(false)}>
          Close
        </button>
        <button className="btn btn-primary" onClick={mergeClickHandler}>
          Merge
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Files;
