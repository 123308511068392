import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Spinner, Badge } from "react-bootstrap";
import { TablePagination } from "@material-ui/core";

import { backendApiUrl, getDate } from "../../utils";
import Message from "../../utils/Message";
import { Activate, Deactivate } from "../../utils/ActivateAndDeactivate";
import MassActions from "../../utils/MassActions";
import Sort from "../../utils/Sort";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../partials/content/Portlet";
import TablePaginationActions from "../../shared/TablePaginationActions";

import { SelectAllRow, SelectOneRow } from "../../utils/ListSettings";
import Filter from "../../utils/Filter";
import TaskForm from "./Form";

const Tasks = (props) => {
  const [loadUser, setLoadUser] = useState(true);

  const [users, setUsers] = useState([]);

  const search_fields = [
    {
      id: "title",
      title: "Title",
      type: "text",
      outside_field: true,
    },
    {
      id: "status",
      title: "Status",
      outside_field: true,
      options: [
        { label: "Not Started", value: "Not Started" },
        { label: "In Progress", value: "In Progress" },
        { label: "Awaiting Feedback", value: "Awaiting Feedback" },
        { label: "On Hold", value: "On Hold" },
        { label: "Completed", value: "Completed" },
      ],
    },
    {
      id: "priority",
      title: "Priority",
      outside_field: true,
      options: [
        { label: "Low", value: "Low" },
        { label: "Medium", value: "Medium" },
        { label: "High", value: "High" },
        { label: "Urgent", value: "Urgent" },
      ],
    },
    {
      id: "type",
      title: "Task Type",
      outside_field: true,
      options: [
        { label: "Created", value: "Created" },
        { label: "Assigned", value: "Assigned" },
      ],
    },
    {
      id: "users.value",
      title: "Assigned users",
      type: "array",
      outside_field: true,
      options: users,
    },
    {
      id: "start_date",
      title: "Start Date",
      type: "date",
      outside_field: true,
    },
    {
      id: "due_date",
      title: "Due Date",
      type: "date",
      outside_field: true,
    },
  ];

  const [msg, setMsg] = useState({ show: false });

  const [sort, setSort] = useState({});

  const [totalDocumnetCount, setTotalDocumnetCount] = useState(0);

  const [currentRowStart, setCurrentRowStart] = useState(1);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [tasks, setTasks] = useState({});

  const [isLoading, setLoading] = useState(true);

  const [actionInfo, setActionInfo] = useState({});

  const [openFilter, setOpenFilter] = useState(false);

  const [filters, setFilters] = useState([]);

  const [task, setTask] = useState({});

  const handleChangePage = (event, newPage) => {
    if (
      Math.max(0, Math.ceil(totalDocumnetCount / rowsPerPage) - 1) === newPage
    ) {
      setCurrentRowStart(totalDocumnetCount - rowsPerPage + 1);
    } else if (page < newPage) {
      setCurrentRowStart(currentRowStart + rowsPerPage);
    } else if (newPage === 0) {
      setCurrentRowStart(1);
    } else {
      setCurrentRowStart(currentRowStart - rowsPerPage);
    }
    setPage(newPage);
    setLoading(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setLoading(true);
  };

  const searchClickHandler = (data) => {
    setFilters(data);
    setLoading(true);
  };

  useEffect(() => {
    if (isLoading) {
      let data = {
        skip: currentRowStart - 1,
        limit: rowsPerPage,
        list: true,
      };

      if (filters.length > 0 && openFilter) data.filters = filters;
      if (sort.field) data.sort = sort;
      data.module = props.module;
      data.owner = props.owner;

      axios
        .post(backendApiUrl("tasks"), data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setTasks(res.data.tasks);
          setTotalDocumnetCount(res.data.count);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }

    if (loadUser) {
      axios
        .post(backendApiUrl("users"), [], {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          let users = [];
          res.data.workforces.forEach((w) => {
            w.value = w._id;
            w.label = w.info.first_name + " " + w.info.last_name;
            users = [...users, w];
          });
          setUsers(users);
          setLoadUser(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [
    isLoading,
    currentRowStart,
    rowsPerPage,
    filters,
    openFilter,
    sort,
    props.module,
    props.owner,
    loadUser,
  ]);

  let permissions = {};
  if (props.user.role.admin || props.module.MDID === "") {
    permissions = { add: true, update: true, delete: true };
  } else if (props.permissions && props.permissions.tasks) {
    permissions = props.permissions.tasks;
  }

  return (
    <div className="mt-4">
      <Portlet>
        <PortletHeader
          title={
            <i
              className="fa fa-search fa-lg text-primary"
              onClick={() => setOpenFilter(openFilter ? false : true)}
              aria-hidden="true"
            />
          }
          toolbar={
            <PortletHeaderToolbar>
              {permissions.add && (
                <button
                  className="btn btn-sm btn-primary mr-2"
                  onClick={() => setTask({ open: true })}
                >
                  Create Task
                </button>
              )}
            </PortletHeaderToolbar>
          }
        />
        <PortletBody>
          {msg.show && (
            <Message status={msg.status} setMsg={setMsg} msg={msg.msg} />
          )}

          {(task.open || task._id) && (
            <TaskForm
              data={task}
              module={props.module}
              owner={props.owner}
              permissions={permissions}
              events={{ setTask, setLoading }}
            />
          )}

          {isLoading ? (
            <div style={{ textAlign: "center" }}>
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <>
              {openFilter && (
                <span>
                  <Filter
                    fields={search_fields}
                    submit={searchClickHandler}
                    filters={filters}
                  />
                  <div className="kt-separator kt-separator--space-sm kt-separator--border" />
                </span>
              )}

              {actionInfo.Ids && actionInfo.Ids.length > 0 && (
                <span>
                  <MassActions
                    url={backendApiUrl("tasks/mass_action")}
                    module="Tasks"
                    filters={filters}
                    info={actionInfo}
                    actions={{ setMsg, setLoading, setActionInfo }}
                  />
                  <div className="kt-separator kt-separator--space-sm kt-separator--border" />
                </span>
              )}

              <div className="row">
                <div className="col-md-6">
                  Status color code :{" "}
                  <Badge variant="danger">Not Started</Badge>
                  &nbsp;
                  <Badge variant="primary">In Progress</Badge>&nbsp;
                  <Badge variant="warning">Awaiting Feedback</Badge>&nbsp;
                  <Badge variant="info">On Hold</Badge>&nbsp;
                  <Badge variant="success">Completed</Badge>
                </div>
                <div className="col-md-6">
                  Priority color code : <Badge variant="secondary">Low</Badge>
                  &nbsp;
                  <Badge variant="warning">Medium</Badge>&nbsp;
                  <Badge variant="primary">High</Badge>&nbsp;
                  <Badge variant="danger">Urgent</Badge>&nbsp;
                </div>
              </div>
              <div className="kt-separator kt-separator--space-sm kt-separator--border" />

              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th nowrap="true">
                        <input
                          type="checkbox"
                          name="select"
                          onClick={(e) =>
                            SelectAllRow(e, "task_list", setActionInfo)
                          }
                          className="sel_all"
                        />
                      </th>
                      <th nowrap="true">
                        Title
                        <Sort
                          data={sort}
                          actions={{ sort: setSort, load: setLoading }}
                          field="title"
                        />
                      </th>
                      <th nowrap="true">Status</th>
                      <th nowrap="true">Start Date</th>
                      <th nowrap="true">Due Date</th>
                      <th nowrap="true">Priority</th>
                      <th nowrap="true">
                        Created Date
                        <Sort
                          data={sort}
                          actions={{ sort: setSort, load: setLoading }}
                          field="createdAt"
                        />
                      </th>
                      <th nowrap="true">Created By</th>
                      <th nowrap="true">
                        Updated Date
                        <Sort
                          data={sort}
                          actions={{ sort: setSort, load: setLoading }}
                          field="updatedAt"
                        />
                      </th>
                      <th nowrap="true">Updated By</th>
                      <th width="10%">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tasks.length ? (
                      tasks.map((t, i) => (
                        <tr key={i}>
                          <td>{currentRowStart + i}</td>
                          <td>
                            <input
                              type="checkbox"
                              className="task_list"
                              onClick={(e) =>
                                SelectOneRow(e, setActionInfo, actionInfo)
                              }
                              value={t._id}
                            />
                          </td>
                          <td>{t.title}</td>
                          <td>
                            {t.status === "Not Started" && (
                              <Badge variant="danger">{t.status}</Badge>
                            )}
                            {t.status === "In Progress" && (
                              <Badge variant="primary">{t.status}</Badge>
                            )}
                            {t.status === "Awaiting Feedback" && (
                              <Badge variant="warning">{t.status}</Badge>
                            )}
                            {t.status === "On Hold" && (
                              <Badge variant="info">{t.status}</Badge>
                            )}
                            {t.status === "Completed" && (
                              <Badge variant="success">{t.status}</Badge>
                            )}
                          </td>
                          <td>{getDate(t.start_date)}</td>
                          <td>{getDate(t.due_date)}</td>
                          <td>
                            {t.priority === "Low" && (
                              <Badge variant="secondary">{t.priority}</Badge>
                            )}
                            {t.priority === "Medium" && (
                              <Badge variant="warning">{t.priority}</Badge>
                            )}
                            {t.priority === "High" && (
                              <Badge variant="primary">{t.priority}</Badge>
                            )}
                            {t.priority === "Urgent" && (
                              <Badge variant="danger">{t.priority}</Badge>
                            )}
                          </td>
                          <td>{t.createdAt}</td>
                          <td>{t.created_by.user}</td>
                          <td>{t.updatedAt}</td>
                          <td>{t.updated_by.user}</td>
                          <td>
                            <i
                              onClick={() => setTask(t)}
                              className="fa fa-pencil-alt fa-lg text-primary mr-2"
                            />
                            {permissions.delete && (
                              <span>
                                {t.active ? (
                                  <Deactivate
                                    url={backendApiUrl("tasks/" + t._id)}
                                    id={t._id}
                                    actions={{
                                      msg: setMsg,
                                      load: setLoading,
                                    }}
                                    title={t.title}
                                  />
                                ) : (
                                  <Activate
                                    url={backendApiUrl("tasks/activate")}
                                    id={t._id}
                                    actions={{
                                      msg: setMsg,
                                      load: setLoading,
                                    }}
                                    title={t.title}
                                  />
                                )}
                              </span>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={12}>
                          <h5 className="text-primary text-center">
                            No tasks available, Create one!
                          </h5>
                        </td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        colSpan={13}
                        count={totalDocumnetCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { "aria-label": "Rows per page" },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </tr>
                  </tfoot>
                </table>
              </div>
            </>
          )}
        </PortletBody>
      </Portlet>
    </div>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(Tasks);
