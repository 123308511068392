import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";

import { backendApiUrl } from "../utils";
import Message from "../utils/Message";
import Notes from "../services/notes";
import MergeFiles from "./merge/index";
import PublicFiles from "./public_docs/index";
import Assets from "./assets/index";
import StandardDocs from "./standard_docs/index";
import Contracts from "./contracts/index";

const DocumentManager = (props) => {
  let hideFields = [];
  if (
    props.module.form.hideSectionsAndFields &&
    props.module.form.hideSectionsAndFields.length > 0
  ) {
    hideFields = props.module.form.hideSectionsAndFields;
  }

  let form_id = props.module.form._id;

  let owner_id = props.module.MDID;

  const [state, setState] = useState({ load: true });

  const [msg, setMsg] = useState({});

  const noteOnClickHandler = (doc_id) => {
    let st = { ...state };
    st.doc_id = doc_id;
    setState(st);
  };

  useEffect(() => {
    if (state.load) {
      axios
        .post(
          backendApiUrl("public/docs"),
          { form_id, owner_id, hideFields },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let tempModule = { ...props.module };
          tempModule["moduleDocs"] = res.data.modules;
          let st = { ...state };
          st.modules = res.data.modules;
          st.templates = res.data.templates;
          st.company = res.data.company_name;
          if (res.data.logo) st.logo = res.data.logo;
          st.load = false;
          props.events.setModuleInfo(tempModule);
          setState(st);
        })
        .catch((err) => {
          let st = { ...state };
          st.load = false;
          setState(st);
        });
    }
  }, [form_id, owner_id, state, hideFields, props.events, props.module]);

  return (
    <div className="row">
      {msg.show && (
        <Message status={msg.status} setMsg={setMsg} msg={msg.msg} />
      )}

      {props.module.form.activeTab === "docs" &&
        props.module.from === "opportunities" &&
        props.module.permissions &&
        props.module.permissions.payments &&
        props.module.permissions.payments.add && (
          <Assets
            setMsg={setMsg}
            opportunity={props.module.opportunity}
            owner={owner_id}
            user={props.user}
          />
        )}

      {props.module.form.activeTab === "docswizard" &&
        props.user &&
        props.user.type === "Workforces" &&
        props.module.from !== "leads" &&
        props.module.form.title === "Opportunities" && (
          <>
            <div className="col-md-6">
              <StandardDocs MI={props.module} events={props.events} />
            </div>
          </>
        )}

      {props.module.form.activeTab === "docswizard" &&
        props.user &&
        props.user.type === "Workforces" && (
          <div className="col-md-6">
            <MergeFiles module={props.module} />
          </div>
        )}

      {props.module.form.activeTab === "docswizard" &&
        props.user &&
        props.user.type === "Workforces" && (
          <div className="col-md-12">
            <Contracts templates={state.templates} MI={props.module} />
          </div>
        )}

      {props.module.form.activeTab === "docswizard" &&
        props.module.from === "opportunities" && (
          <div className="col-md-6">
            <PublicFiles module={props.module} events={props.events} />
          </div>
        )}

      {!!state.doc_id && (
        <Modal show={true} size="lg" backdrop="static" keyboard={false}>
          <Modal.Body>
            <div style={{ maxHeight: "500px", overflowY: "auto" }}>
              <Notes
                module="docs"
                permissions={{ notes: { add: true, update: true } }}
                owner={state.doc_id}
              />
            </div>
            <button
              style={{ float: "right" }}
              className="btn btn-primary"
              onClick={() => noteOnClickHandler("")}
            >
              Close
            </button>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(DocumentManager);
