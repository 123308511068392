import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import "../../_metronic/_assets/sass/app_tab.scss";
import Opportunities from "../opportunities";
import Admin from "../opportunities/Admin"; // AdminSection for opportunity.
import FromElements from "./";
import Auth from "../module/Auth"; // Credentials section for workforce, contact, prospect.
import Docs from "../docs";
import Emails from "../campaigns/Emails";
import Notes from "../services/notes";
import Payments from "../payments";
import Tasks from "../services/tasks";
import History from "../services/history";
import Employment from "../timesheets/employment";

const LsTabs = (props) => {
  // console.log(props.user.role.admin);
  let MI = { ...props.module };

  const getFields = (config) => {
    let fields = [];
    Array.isArray(config) &&
      config.forEach((c) => {
        if (
          c.type !== "container" &&
          c.type !== "grid" &&
          c.type !== "item" &&
          c.type !== "tab" &&
          c.type !== "section" &&
          c.type !== "file"
        ) {
          if (MI.requiredFields.includes(c.id)) {
            fields = [...fields, c];
          }
        }
        if (c.childs) fields = [...fields, ...getFields(c.childs)];
      });
    return fields;
  };

  const getTabs = (config) => {
    let tabs = [];
    Array.isArray(config) &&
      config.forEach((c) => {
        if (c.type === "item") {
          let fields = [];
          fields = getFields(c.childs);
          if (fields.length > 0) {
            tabs = [...tabs, c.id];
          }
        }
        if (c.childs) tabs = [...tabs, ...getTabs(c.childs)];
      });
    return tabs;
  };

  // tabs controlled by roles
  let tab_list = [];
  let tab_ids = [];
  props.element.childs.forEach((t) => {
    let allow = true;

    if (t.hided_roles && t.hided_roles.length > 0) {
      let troles = [];
      t.hided_roles.forEach((r) => {
        troles = [...troles, r._id];
      });
      if ((props.user && troles.includes(props.user.role._id)) || !props.user) {
        allow = false;
      }
    }

    if (allow) {
      tab_ids = [...tab_ids, t.id];
      tab_list = [...tab_list, t];
    }
  });

  tab_ids = [...tab_ids, ...MI.allowed_tabs];

  let required_tabs = getTabs(MI.form.content);
  let tabStyle = "";
  required_tabs.forEach((rt) => {
    tabStyle += `
      .tabs nav a[data-rb-event-key="${rt}"] {
        border: 2px solid red;
      }
      .tabs-style-bar nav a[data-rb-event-key="${rt}"]:hover {
        border: 2px solid red;
      } 
    `;
  });

  const onTabChangeHandler = (key, e) => {
    e.preventDefault();
    var myDiv = document.getElementById("kt_content");
    myDiv.scrollTop = 0;
    let from = "next";

    // Webform tab validation except back tabs
    if (!props.user) {
      let current_tab = 0;
      let next_tab = key === "docs" ? tab_list.length + 1 : 0;
      tab_list.forEach((t, i) => {
        if (MI.form.activeTab === t.id) current_tab = i;
        if (key === t.id) next_tab = i;
      });
      if (next_tab < current_tab) from = "back";
    }
    props.events.tabChange(key, from);
  };

  return (
    <>
      <style type="text/css">{tabStyle}</style>
      <input type="hidden" id="first_tab" value={tab_ids[0]} />
      <input type="hidden" id="active_tab" value={MI.form.activeTab} />
      <input type="hidden" id="last_tab" value={tab_ids[tab_ids.length - 1]} />
      <div className="tabs tabs-style-bar col-md-12 mb-3">
        <Tabs
          activeKey={MI.form.activeTab}
          unmountOnExit={false}
          onSelect={onTabChangeHandler}
          id="content-tab"
        >
          {tab_list.map((child, i) => {
            return (
              <Tab
                eventKey={child.id}
                title={
                  child.title.toLowerCase() === child.id
                    ? "Tab - " + (i + 1)
                    : child.title
                }
                key={i}
              >
                {child.childs.length &&
                  child.childs.map((content, j) => {
                    return (
                      <div className="col-md-12" key={j}>
                        <FromElements
                          element={content}
                          module={MI}
                          events={props.events}
                        />
                        <br />
                      </div>
                    );
                  })}
              </Tab>
            );
          })}
          
          {tab_ids.includes("opportunities") && (
            <Tab eventKey="opportunities" title="Petitions">
              {MI.form.activeTab === "opportunities" && <Opportunities />}
            </Tab>
          )}

          {tab_ids.includes("docs") && (
            <Tab eventKey="docswizard" title="Docs Wizard">
              {MI.form.activeTab === "docswizard" && (
                <Docs
                  module={MI}
                  events={props.events}
                  activeTab={"docswizard"}
                />
              )}
            </Tab>
          )}

          {tab_ids.includes("timesheets") && (
            <Tab eventKey="timesheets" title="Time sheets">
              <Employment module={MI} events={props.events} />
            </Tab>
          )}
                  
          {tab_ids.includes("productivity") && (
            <Tab eventKey="productivity" title="Productivity">
              {MI.form.activeTab === "productivity" && (
                <>
                  {tab_ids.includes("emails") && (
                    <Emails
                      module={MI.form._id}
                      owner={MI.MDID}
                      email={MI.dynamicFieldData.email}
                      MI={MI}
                      opp={MI.opportunity ? MI.opportunity : {}}
                    />
                  )}
                  {tab_ids.includes("tasks") && (
                    <Tasks
                      info={MI}
                      permissions={MI.permissions}
                      module={MI.form._id}
                      owner={MI.MDID}
                    />
                  )}
                  {tab_ids.includes("notes") && (
                    <Notes
                      module={
                        MI.from === "opportunities"
                          ? "opportunities"
                          : MI.from === "leads"
                          ? "leads"
                          : MI.form._id
                      }
                      permissions={MI.permissions}
                      owner={MI.MDID}
                      from="tabs"
                      MI={MI}
                    />
                  )}
                  {tab_ids.includes("history") && (
                    <History module={MI} events={props.events} />
                  )}
                </>
              )}
            </Tab>
          )}
          {tab_ids.includes("payments") && (
            <Tab eventKey="payments" title="Payments">
              {MI.form.activeTab === "payments" && (
                <Payments
                  module={
                    MI.from === "opportunities"
                      ? "opportunities"
                      : MI.from === "leads"
                      ? "leads"
                      : MI.form._id
                  }
                  permissions={MI.permissions}
                  owner={MI.MDID}
                  from="tabs"
                  opportunity={MI.opportunity}
                  company={MI.form.company}
                />
              )}
            </Tab>
          )}
          {tab_ids.includes("admin") && (
            <Tab eventKey="admin" title="Admin Info">
              <Admin module={MI} actions={props.events} />
            </Tab>
          )}          
          {tab_ids.includes("auth") && (
            <Tab eventKey="auth" title="Auth Information">
              <Auth module={MI} events={props.events} />
            </Tab>
          )}
        </Tabs>
      </div>
    </>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(LsTabs);
