import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import Notes from "./utils/Notes";
import Weekly from "./Weekly";
import Monthly from "./Monthly";
import Message from "../utils/Message";
import ConfirmBox from "./utils/ConfirmBox";

import { backendApiUrl } from "../utils";
import { getWeek, getWeeksInMonth } from "./utils/DateFun";

const moment = require("moment-timezone");

function Sheet(props) {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const swd = params.get("sd");
  const ewd = params.get("ed");

  const [weekNotes, setWeekNotes] = useState("");

  const [statusInfo, setStatusInfo] = useState({});

  const [msg, setMsg] = useState({});

  const [state, setState] = useState({
    prospect: useParams().MDID,
    client: props.client,
    load: true,
    type: "Weekly",
    users: [],
    data: {},
    notes: {},
    approver: "",
  });

  const setLoad = (sta) => {
    let st = { ...state };
    st.load = sta;
    setState(st);
  };

  const setType = (type) => {
    let st = { ...state };
    delete st._id;
    delete st.status;
    delete st.startDate;
    delete st.endDate;
    delete st.weeks;
    delete st.data;
    delete st.notes;
    st.type = type;
    st.approver = "";
    st.load = true;
    setState(st);
  };

  const onHourChangeHandler = (e, week) => {
    let st = { ...state };
    if (e.target.type === "checkbox") {
      if (st.data[week]) {
        st.data[week][e.target.name] = e.target.checked;
      } else {
        st.data[week] = { [e.target.name]: e.target.checked };
      }
    } else {
      if (st.data[week]) {
        if (st.data[week].dailyHours) {
          if (st.data[week].dailyHours[e.target.name]) {
            st.data[week].dailyHours[e.target.name] = e.target.value;
          } else {
            st.data[week].dailyHours = {
              ...st.data[week].dailyHours,
              [e.target.name]: e.target.value,
            };
          }
        } else {
          st.data[week].dailyHours = { [e.target.name]: e.target.value };
        }
      } else {
        st.data = {
          ...st.data,
          [week]: { dailyHours: { [e.target.name]: e.target.value } },
        };
      }
    }
    setState(st);
  };

  const onNotesChangeHandler = (val, week) => {
    let st = { ...state };
    if (st.data[week]) {
      if (st.data[week].notes) {
        st.data[week].notes = [...st.data[week].notes, val];
      } else {
        st.data[week].notes = [val];
      }
    } else {
      st.data = {
        ...st.data,
        [week]: { notes: [val] },
      };
    }
    setState(st);
  };

  const onNotesDeleteHandler = (index, week) => {
    let st = { ...state };
    let notes = [];
    st.data[week].notes.forEach((note, i) => {
      if (index !== i) notes = [...notes, note];
    });
    st.data[week].notes = notes;
    setState(st);
  };

  const onAttachmentHandler = (files, week) => {
    let st = { ...state };
    if (st.data[week]) {
      if (
        st.data[week].hasOwnProperty("file") &&
        Array.isArray(st.data[week].file)
      ) {
        st.data[week].file.push(files);
      } else if (!Array.isArray(st.data[week].file)) {
        st.data[week].file = [];
        st.data[week].file.push(files);
      }
    } else {
      st.data = { ...st.data, [week]: { file: [files] } };
    }
    setState(st);
  };

  const onAttachmentDeleteHandler = (files, week) => {
    let st = { ...state };
    st.data[week].file = files;
    setState(st);
  };
  const onApproverChangeHandler = (e) => {
    let st = { ...state };
    st[e.target.name] = e.target.value;
    setState(st);
  };

  const onSubmitHandler = async (type) => {
    let hasUpload = 0;
    let data = new FormData();
    Object.keys(state.data).forEach(function(key) {
      if (
        Array.isArray(state.data[key].file) &&
        state.data[key].file.length > 0
      ) {
        state.data[key].file.forEach((f) => {
          let code = "";
          for (let i = 0; i < 6; i++) {
            code += Math.floor(Math.random() * 10); // Generate random digit (0-9)
          }
          data.append(
            "file",
            f,
            btoa(props.client._id + "_" + key + "_" + code) +
              "." +
              f.name.split(".").pop()
          );
        });
        hasUpload++;
      }
    });

    if (hasUpload > 0) {
      await axios
        .post(backendApiUrl("timesheet/upload"), data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((f) => {
          return saveFunction(type);
        })
        .catch((err) => {
          setMsg({
            status: 500,
            msg: "Problem while Upload Timesheet.",
            show: true,
          });
        });
    } else {
      return saveFunction(type);
    }
  };

  const saveFunction = (type) => {
    axios
      .post(
        backendApiUrl("timesheet/submit"),
        {
          prospect: state.prospect,
          client: props.client._id,
          data: state.data,
          type,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setMsg({
          status: 200,
          msg: "Timesheet " + type + " Successfully.",
          show: true,
        });

        setTimeout(() => {
          let st = { ...state };
          st.load = true;
          setState(st);
        }, 2000);
      })
      .catch((err) => {
        setMsg({
          status: 500,
          msg: "Problem While Timesheet Submission.",
          show: true,
        });
      });
  };

  const isInputDisabled = (w, status, data) => {
    const onBoardingDate = moment(props.onBoardingDate).format("MM/DD/YYYY");
    const currentDate = moment().format("MM/DD/YYYY");
    const inputDate = moment(w).format("MM/DD/YYYY");
    const projectStartDate = moment(state.client.project_start_date).format(
      "MM/DD/YYYY"
    );
    const isStatusDisabled = ["Awaiting For Review", "Approved"].includes(
      status
    );
    const isFutureDate = new Date(inputDate) > new Date(currentDate);
    let isBeforeProjectStartDate = false;
    let isPartiallyUpload = false;
    // Check if project start date is after onboarding date
    if (new Date(projectStartDate) >= new Date(onBoardingDate)) {
      isBeforeProjectStartDate = !(
        new Date(inputDate) >= new Date(projectStartDate)
      );
    }
    // Check if project start date is before onboarding date
    if (new Date(projectStartDate) < new Date(onBoardingDate)) {
      isBeforeProjectStartDate = new Date(inputDate) < new Date(onBoardingDate);
    }

    if (status === "Partially Approve") {
      if (data[inputDate] === undefined) {
        isPartiallyUpload = false;
      } else {
        isPartiallyUpload = true;
      }
    }
    return (
      isStatusDisabled ||
      isFutureDate ||
      isBeforeProjectStartDate ||
      isPartiallyUpload
    );
  };

  const EmployeeResubmit = (id) => {
    axios
      .post(
        backendApiUrl("timesheet/status"),
        {
          _id: id,
          status: "Resubmit",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setLoad(true);
      })
      .catch((err) => {
        setMsg({
          status: 500,
          msg: "Problem While Resubmit Timesheet.",
          show: true,
        });
      });
  };

  useEffect(() => {
    if (state.load) {
      let startDate = "";
      let endDate = "";
      let weeks = [];
      if (state.startDate) {
        startDate = state.startDate;
        endDate = state.endDate;
        weeks = state.weeks;
      } else {
        if (state.type === "Monthly") {
          startDate = moment().startOf("month");
          endDate = moment().endOf("month");
          /**
           * Prospect Login >> By default in timesheet display only the currect date & Month
           * Admin Login >> By default display only the previous week.
           */
          if (props.user.type === "Workforces") {
            startDate = moment(startDate)
              .subtract(1, "months")
              .startOf("month");
            endDate = moment(startDate).endOf("month");
          }
          weeks = getWeeksInMonth(startDate, endDate);
        } else {
          startDate = moment().startOf("isoWeek");
          endDate = moment().endOf("isoWeek");
          /**
           * Prospect Login >> By default in timesheet display only the currect date & Month
           * Admin Login >> By default display only the previous week.
           */
          if (props.user.type === "Workforces") {
            startDate = moment(startDate)
              .subtract(1, "days")
              .startOf("isoWeek");
            endDate = moment(startDate).endOf("isoWeek");
          }
          if (!!swd && !!ewd) {
            startDate = moment(swd);
            endDate = moment(ewd);
          }
          weeks = getWeek(startDate, endDate);
        }
      }
      /*
			Date : 17/10/2023
			Purpose : For fetching last week data ,if month start date is in previouse week.
			Auther : Karthikeyan p.
			*/

      let sDate = startDate;
      if (state.type === "Monthly") {
        sDate = moment(sDate).startOf("isoWeek");
      }

      axios
        .post(
          backendApiUrl("timesheet/weeks"),
          {
            prospect: state.prospect,
            client: props.client._id,
            startDate: sDate.format("MM/DD/YYYY").toString(),
            endDate: endDate.format("MM/DD/YYYY").toString(),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let sta = { ...state };
          sta.startDate = startDate;
          sta.endDate = endDate;
          sta.weeks = weeks;
          sta.companyId = res.data.companyId;

          if (res.data.weeks) {
            sta.data = res.data.weeks;
            sta.notes = res.data.notes;
          }
          sta.users = res.data.users;
          // If approver is empty, select first user as default
          if (!state.approver && res.data.users.length > 0) {
            sta.approver = res.data.users[0]._id;
          }
          sta.load = false;
          setState(sta);
        })
        .catch((err) => {
          let st = { ...state };
          st.load = false;
          setState(st);
        });
    }
  }, [state, props.client._id, props.user, swd, ewd]);

  return state.load ? (
    <div style={{ textAlign: "center" }}>
      <Spinner animation="border" variant="primary" />
    </div>
  ) : (
    <>
      {msg.show && (
        <Message status={msg.status} setMsg={setMsg} msg={msg.msg} />
      )}
      {state.type === "Weekly" && (
        <Weekly
          state={state}
          events={{
            setState,
            setType,
            onClose: props.onClose,
            onHourChange: onHourChangeHandler,
            onApproverChange: onApproverChangeHandler,
            inputDisabled: isInputDisabled,
            onSubmit: onSubmitHandler,
            showNotes: setWeekNotes,
            onApprove: setStatusInfo,
            onAttachmentHandler,
            EmployeeResubmit: EmployeeResubmit,
            onAttachmentDeleteHandler,
            setLoad,
            setMsg,
          }}
        />
      )}
      {state.type === "Monthly" && (
        <Monthly
          state={state}
          events={{
            setState,
            setType,
            onClose: props.onClose,
            onHourChange: onHourChangeHandler,
            onApproverChange: onApproverChangeHandler,
            inputDisabled: isInputDisabled,
            onSubmit: onSubmitHandler,
            showNotes: setWeekNotes,
            onApprove: setStatusInfo,
            onAttachmentHandler,
            onAttachmentDeleteHandler,
            setLoad,
            setMsg,
          }}
        />
      )}

      {!!weekNotes && (
        <Notes
          state={state}
          week={weekNotes}
          events={{
            onClose: setWeekNotes,
            onNotesChange: onNotesChangeHandler,
            onDelete: onNotesDeleteHandler,
          }}
        />
      )}

      {!!statusInfo._id && (
        <ConfirmBox
          info={statusInfo}
          events={{ onClose: setStatusInfo, setMsg, setLoad }}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(Sheet);
