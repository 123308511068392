import React from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

const DialogBox = (props) => {
  return (
    <Dialog
      open={true}
      onClose={() => props.setStatus(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Delete Confirmation"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.msg}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setStatus(false)} color="primary">
          Disagree
        </Button>
        <Button onClick={() => props.setStatus(true)} color="primary" autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBox;
