import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { Switch } from "@material-ui/core";
import axios from "axios";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import { Form } from "react-bootstrap";

import { populateFieldData, backendApiUrl } from "../../utils";
import Vendors from "./Vendors";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../partials/content/Portlet";
import Message from "../../utils/Message";

const Clients = (props) => {
  const prospect = useParams().MDID;

  const [requiredFields, setRequiredFields] = useState([]);

  const [msg, setMsg] = useState({});

  const [client, setClient] = useState(
    props.client && !!props.client._id
      ? props.client
      : {
          name: props.companyName,
          location: "",
          teamName: "",
          manager: "",
          email: "",
          phone: "",
          isActiveClient: false,
          project_start_date: "",
          needVendor: false,
          externalProject: "No",
          vendors: [],
          workArrangement: "",
          h1bPetitionFiled: false,
        }
  );

  const onChangeHandler = (e, field) => {
    let d = { ...client };
    if (e.target.type === "checkbox") {
      d[e.target.name] = e.target.checked;
    } else {
      d[e.target.name] = e.target.value;
      if (e.target.name === "externalProject" && e.target.value === "No") {
        d["old_name"] = d.name !== "" ? d.name : "";
        d["name"] = props.companyName;
      } else if (
        e.target.name === "externalProject" &&
        e.target.value === "Yes"
      ) {
        d["name"] = !!d.old_name ? d.old_name : d.name;
      }
    }
    setClient(d);
  };

  const onDateChangeHandler = (e, field) => {
    let d = { ...client };
    d[field] = e;
    setClient(d);
  };

  const checkValidation = () => {
    let rFields = [];
    let validateField = ["name", "manager", "email"];
    //phone number validation removed asked by rajesh
    /*if (client.externalProject && client.externalProject === "Yes") {
      validateField = [...validateField, "teamName"];
    }*/
    validateField.forEach((f) => {
      if (client[f] === "" || client[f] === undefined || client[f] === null) {
        rFields = [...rFields, f];
      }
    });
    if (
      client.isActiveClient &&
      (client.project_start_date === "" ||
        client.project_start_date === undefined ||
        client.project_start_date === null)
    ) {
      rFields = [...rFields, "project_start_date"];
    }
    return rFields;
  };

  const onSaveHandler = async () => {
    let check = checkValidation();

    if (check.length > 0) {
      setRequiredFields(check);
      return true;
    }

    await axios
      .post(
        backendApiUrl("timesheet/create_client"),
        { ...client, prospect },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        props.setMsg({
          status: 200,
          msg: "Client and vendor created successfully.",
          show: true,
        });
        props.onClose({});
        props.setLoad(true);
      })
      .catch((err) => {
        props.setMsg({
          status: 500,
          msg: "Problem while create client.",
          show: true,
        });
        props.onClose({});
        props.setLoad(false);
      });
  };

  const onUpdateHandler = async () => {
    let check = checkValidation("mandatory");

    if (check.length > 0) {
      setRequiredFields(check);
      return true;
    }

    await axios
      .patch(backendApiUrl("timesheet/") + client._id, client, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        props.setMsg({
          status: 200,
          msg: "Client and vendor updated successfully.",
          show: true,
        });
        props.onClose({});
        props.setLoad(true);
      })
      .catch((err) => {
        props.setMsg({
          status: 500,
          msg: "Problem while update.",
          show: true,
        });
        props.onClose({});
        props.setLoad(false);
      });
  };

  const handleH1BPetitionFiled = async (e) => {
    if (e.target.checked) {
      try {
        const response = await axios.post(
          backendApiUrl("timesheet/petitionfiled"),
          { prospect },
          { headers: { "Content-Type": "application/json" } }
        );

        if (response.data.clientIds.length > 0) {
          setMsg({
            status: 500,
            msg:
              "You have already filed an H1B petition for another client or project.",
            show: true,
          });
        } else {
          setClient({ ...client, h1bPetitionFiled: true });
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      setClient({ ...client, h1bPetitionFiled: false });
    }
  };

  let workArrangementOption = [
    { label: "Onsite", value: "Onsite" },
    { label: "Remote", value: "Remote" },
    { label: "Hybrid", value: "Hybrid" },
  ];

  return (
    <Portlet>
      <PortletHeader title="Project Details" />
      <PortletBody>
        {msg.show && (
          <Message status={msg.status} setMsg={setMsg} msg={msg.msg} />
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h5>
              Are you currently working on a Project with external
              Client?&nbsp;&nbsp;&nbsp;&nbsp;
              <Form.Check
                custom
                inline
                type="radio"
                label="Yes"
                id="externalProject_yes"
                name="externalProject"
                value="Yes"
                onChange={onChangeHandler}
                checked={client.externalProject === "Yes" ? true : false}
              />
              <Form.Check
                custom
                inline
                type="radio"
                id="externalProject_no"
                label="No"
                value="No"
                name="externalProject"
                onChange={onChangeHandler}
                checked={client.externalProject === "No" ? true : false}
              />
            </h5>
            <br></br>
          </Grid>
        </Grid>
        {client.externalProject === "Yes" ? (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div className="form-group">
                <label className="form-label" htmlFor="name">
                  Client Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  onChange={onChangeHandler}
                  value={populateFieldData(client, "name")}
                  className={
                    "form-control mandatory" +
                    (requiredFields.includes("name") ? " is-invalid" : "")
                  }
                />
                {requiredFields.includes("name") && (
                  <div className="invalid-feedback">
                    Please Provide a Valid Client Name
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label className="form-label" htmlFor="location">
                  Client Location
                </label>
                <input
                  type="text"
                  name="location"
                  onChange={onChangeHandler}
                  value={populateFieldData(client, "location")}
                  className="form-control"
                />
              </div>
            </Grid>
            {/*<Grid item xs={4}>
              <div className="form-group">
                <label className="form-label" htmlFor="teamName">
                  Team Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="teamName"
                  onChange={onChangeHandler}
                  value={populateFieldData(client, "teamName")}
                  className={
                    "form-control mandatory" +
                    (requiredFields.includes("teamName") ? " is-invalid" : "")
                  }
                />
                {requiredFields.includes("teamName") && (
                  <div className="invalid-feedback">
                    Please Provide a valid Team Name
                  </div>
                )}
              </div>
                </Grid>
            */}
            <Grid item xs={4}>
              <div className="form-group">
                <label className="form-label" htmlFor="manager">
                  Reporting Manager <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="manager"
                  onChange={onChangeHandler}
                  value={populateFieldData(client, "manager")}
                  className={
                    "form-control mandatory" +
                    (requiredFields.includes("manager") ? " is-invalid" : "")
                  }
                />
                {requiredFields.includes("manager") && (
                  <div className="invalid-feedback">
                    Please Provide a Valid Manager
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label className="form-label" htmlFor="email">
                  Reporting Manager Email <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="email"
                  onChange={onChangeHandler}
                  value={populateFieldData(client, "email")}
                  className={
                    "form-control mandatory" +
                    (requiredFields.includes("email") ? " is-invalid" : "")
                  }
                />
                {requiredFields.includes("email") && (
                  <div className="invalid-feedback">
                    Please Provide a Valid Email
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label className="form-label" htmlFor="phone">
                  Reporting Manager Phone
                </label>
                <input
                  type="text"
                  name="phone"
                  onChange={onChangeHandler}
                  value={populateFieldData(client, "phone")}
                  className={
                    "form-control" +
                    (requiredFields.includes("phone") ? " is-invalid" : "")
                  }
                />
                {requiredFields.includes("phone") && (
                  <div className="invalid-feedback">
                    Please Provide a Valid Phone
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={4}>
              <br />
              Is your active project?
              <Switch
                name="isActiveClient"
                checked={client.isActiveClient ? true : false}
                onChange={onChangeHandler}
              />
            </Grid>
            {client.isActiveClient && (
              <Grid item xs={4}>
                <div className="form-group">
                  <label className="form-label" htmlFor="project_start_date">
                    Project Start Date <span className="text-danger">*</span>
                  </label>
                  <DatePicker
                    dateFormat="MM-dd-yyyy"
                    selected={
                      !!populateFieldData(client, "project_start_date")
                        ? new Date(
                            populateFieldData(client, "project_start_date")
                          )
                        : ""
                    }
                    className={
                      "form-control" +
                      (requiredFields.includes("project_start_date")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(d) =>
                      onDateChangeHandler(d, "project_start_date")
                    }
                    placeholderText="MM-DD-YYYY"
                    customInput={<InputMask mask="99-99-9999" type="text" />}
                  />
                  {requiredFields.includes("project_start_date") && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Please Provide a Valid Start Date
                    </div>
                  )}
                </div>
              </Grid>
            )}
            <Grid item xs={4}>
              <div className="form-group">
                <label className="form-label" htmlFor="workArrangement">
                  Select your Work Arrangement ?
                </label>
                <select
                  onChange={onChangeHandler}
                  value={populateFieldData(client, "workArrangement")}
                  name="workArrangement"
                  className={
                    "form-control" +
                    (requiredFields.includes("workArrangement")
                      ? " is-invalid"
                      : "")
                  }
                >
                  <option value="">Select Work Arrangement</option>
                  {workArrangementOption &&
                    workArrangementOption.map((op, i) => (
                      <option value={op.value} key={i}>
                        {op.label}
                      </option>
                    ))}
                </select>
                {requiredFields.includes("workArrangement") && (
                  <div className="invalid-feedback">
                    Please Select Valid Work Arrangement.
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              Is there a Prime Vendor between Sira and the Client?
              <Switch
                name="needVendor"
                checked={client.needVendor ? true : false}
                onChange={onChangeHandler}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div className="form-group">
                <label className="form-label" htmlFor="name">
                  Company Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  onChange={onChangeHandler}
                  value={populateFieldData(client, "name")}
                  className={
                    "form-control mandatory" +
                    (requiredFields.includes("name") ? " is-invalid" : "")
                  }
                />
                {requiredFields.includes("name") && (
                  <div className="invalid-feedback">
                    Please Provide a Valid Client Name
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label className="form-label" htmlFor="manager">
                  Reporting Manager <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="manager"
                  onChange={onChangeHandler}
                  value={populateFieldData(client, "manager")}
                  className={
                    "form-control mandatory" +
                    (requiredFields.includes("manager") ? " is-invalid" : "")
                  }
                />
                {requiredFields.includes("manager") && (
                  <div className="invalid-feedback">
                    Please Provide a Valid Manager
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label className="form-label" htmlFor="email">
                  Reporting Manager Email <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="email"
                  onChange={onChangeHandler}
                  value={populateFieldData(client, "email")}
                  className={
                    "form-control mandatory" +
                    (requiredFields.includes("email") ? " is-invalid" : "")
                  }
                />
                {requiredFields.includes("email") && (
                  <div className="invalid-feedback">
                    Please Provide a Valid Email
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="form-group">
                <label className="form-label" htmlFor="phone">
                  Reporting Manager Phone
                </label>
                <input
                  type="text"
                  name="phone"
                  onChange={onChangeHandler}
                  value={populateFieldData(client, "phone")}
                  className={
                    "form-control " +
                    (requiredFields.includes("phone") ? " is-invalid" : "")
                  }
                />
                {requiredFields.includes("phone") && (
                  <div className="invalid-feedback">
                    Please Provide a Valid Phone
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={4}>
              <br />
              Is your active project?
              <Switch
                name="isActiveClient"
                checked={client.isActiveClient ? true : false}
                onChange={onChangeHandler}
              />
            </Grid>
            {client.isActiveClient && (
              <Grid item xs={4}>
                <div className="form-group">
                  <label className="form-label" htmlFor="project_start_date">
                    Project Start Date <span className="text-danger">*</span>
                  </label>
                  <DatePicker
                    dateFormat="MM-dd-yyyy"
                    selected={
                      !!populateFieldData(client, "project_start_date")
                        ? new Date(
                            populateFieldData(client, "project_start_date")
                          )
                        : ""
                    }
                    className={
                      "form-control" +
                      (requiredFields.includes("project_start_date")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(d) =>
                      onDateChangeHandler(d, "project_start_date")
                    }
                    placeholderText="MM-DD-YYYY"
                    customInput={<InputMask mask="99-99-9999" type="text" />}
                  />
                  {requiredFields.includes("project_start_date") && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Please Provide a Valid Start Date
                    </div>
                  )}
                </div>
              </Grid>
            )}
          </Grid>
        )}
        <Grid item xs={12}>
          <br />
          Has an H1B Petition been Filed for this Current Project?
          <Switch
            name="h1bPetitionFiled"
            checked={client.h1bPetitionFiled ? true : false}
            onChange={handleH1BPetitionFiled}
          />
        </Grid>
        {client.needVendor && <Vendors client={client} setClient={setClient} />}
        <div className="col-md-12" align="right">
          <button
            className="btn btn-sm btn-default"
            onClick={() => props.onClose({})}
          >
            {"<< Back"}
          </button>
          &nbsp;&nbsp;
          {!!client._id ? (
            <button
              className="btn btn-sm btn-primary"
              onClick={onUpdateHandler}
            >
              Update
            </button>
          ) : (
            <button className="btn btn-sm btn-primary" onClick={onSaveHandler}>
              Create
            </button>
          )}
        </div>
      </PortletBody>
    </Portlet>
  );
};

export default Clients;
