import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Grid } from "@material-ui/core";

const Filter = (props) => {
  const fields = [
    ...props.fields,
    {
      id: "active",
      title: "Active Status",
      type: "select",
      options: [
        { label: "Active", value: "true" },
        { label: "Deactive", value: "false" },
      ],
      outside_field: true,
    },
    // {
    //   id: "createdAt",
    //   title: "Created Date",
    //   type: "date",
    //   outside_field: true,
    // },
    // {
    //   id: "updatedAt",
    //   title: "Updated Date",
    //   type: "date",
    //   outside_field: true,
    // },
  ];

  const [filters, setFilters] = useState(
    props.filters && props.filters.length > 0
      ? props.filters
      : [
          {
            _id: uuidv4(),
            field: "",
            match: "",
            value: "",
            operator: "",
          },
        ]
  );

  const addNewRow = () => {
    let f = [
      ...filters,
      {
        _id: uuidv4(),
        field: "",
        match: "",
        value: "",
        operator: "",
      },
    ];
    setFilters(f);
  };

  const removeRow = (id) => {
    let f = [];
    filters.forEach((fi) => {
      if (id !== fi._id) {
        f = [...f, fi];
      }
    });
    if (f.length === 0) {
      f = [
        {
          _id: uuidv4(),
          field: "",
          match: "",
          value: "",
          data: {},
          operator: "",
        },
      ];
    }
    setFilters(f);
  };

  const fieldOnChangeHandler = (e, _id) => {
    let temp = [];
    filters.forEach((f) => {
      if (f._id === _id) {
        f.field = e.target.value;
        fields.forEach((d) => {
          if (e.target.value === d.id) {
            f.data = d;
          }
        });
      }
      temp = [...temp, f];
    });
    setFilters(temp);
  };

  const valueOnChangeHandler = (e, _id) => {
    let temp = [];
    filters.forEach((f) => {
      if (f._id === _id) {
        f[e.target.name] = e.target ? e.target.value : e;
      }
      temp = [...temp, f];
    });
    setFilters(temp);
  };

  return (
    <div>
      {filters.map((f, i) => (
        <Grid container spacing={2} key={i}>
          <Grid item xs={3}>
            <div className="form-group">
              <select
                className="form-control"
                onChange={(e) => fieldOnChangeHandler(e, f._id)}
                value={f.field}
              >
                <option disabled value="">
                  Please select field
                </option>
                {fields.map((o, i) => (
                  <option value={o.id} key={i}>
                    {o.title}
                  </option>
                ))}
              </select>
            </div>
          </Grid>
          {f.data && (
            <>
              <Grid item xs={3}>
                <div className="form-group">
                  <select
                    className="form-control"
                    name="operator"
                    value={!!f.operator ? f.operator : ""}
                    onChange={(e) => valueOnChangeHandler(e, f._id)}
                  >
                    <option disabled value="">
                      Please select action
                    </option>
                    {["number", "amount", "percentage"].includes(f.data.type)
                      ? [">", "<", ">=", "<="].map((s, i) => (
                          <option value={s} key={i}>
                            {s}
                          </option>
                        ))
                      : ["="].map((s, i) => (
                          <option value={s} key={i}>
                            {s}
                          </option>
                        ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="form-group">
                  {f.data.options ? (
                    <select
                      className="form-control"
                      name="value"
                      onChange={(e) => valueOnChangeHandler(e, f._id)}
                      value={!!f.value ? f.value : ""}
                    >
                      <option disabled value="">
                        Please select
                      </option>
                      {f.data.options.map((o, i) => (
                        <option
                          value={o.value}
                          key={i}
                          disabled={o.disabled ? true : false}
                        >
                          {o.label}
                        </option>
                      ))}
                    </select>
                  ) : f.data.type === "date" ? (
                    <input
                      type="date"
                      name="value"
                      id="values"
                      onChange={(e) => valueOnChangeHandler(e, f._id)}
                      value={f.value}
                      className="form-control"
                      autoComplete="false"
                    />
                  ) : (
                    <input
                      type="text"
                      name="value"
                      id="values"
                      onChange={(e) => valueOnChangeHandler(e, f._id)}
                      value={f.value}
                      className="form-control"
                      autoComplete="false"
                    />
                  )}
                </div>
              </Grid>
            </>
          )}
          <Grid item xs={2}>
            <div className="form-group mt-2">
              {filters.length - 1 === i && (
                <i
                  className="fa fa-plus fa-lg text-success mr-4"
                  onClick={addNewRow}
                />
              )}
              <i
                className="fa fa-times fa-lg text-danger mr-4"
                onClick={() => removeRow(f._id)}
              />

              {filters.length - 1 === i && (
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => props.submit(filters)}
                >
                  Search
                </button>
              )}
            </div>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

export default Filter;
